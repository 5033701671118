import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PhoneIcon from '@mui/icons-material/Phone';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  hasAddressVerificationPermission,
  hasAdminEditCampaignsVersionsPermissions,
  hasAdminEditCustomersLobsPermissions,
  hasAdminInHomeWindowsPermissions,
  hasAdminLogosPermissions,
  hasAdminManageUsersDataAccessPermissions,
  hasAdminPermissions,
  hasAdminRolesPermissionsPermissions,
  hasCreateCampaignPermission,
  hasFileProcessingPermission,
  hasGhsAdminBannerPermissions,
  hasGhsAdminDashboardThumbnailsPermissions,
  hasGhsAdminLogoPermissions,
  hasGhsAdminPermissions, hasMailTrakAutoRatingPermission,
  hasMailTrakClientLobPermission,
  hasSinglePieceLookupPermission
} from '../services/UserService/UserService';

/**
 * @typedef {{
 *  name: string;
 *  path: string;
 *  icon: Element;
 *  exclude?: boolean | ((permissions: string[], dashboardsInfo?: SisenseInfo | null) => boolean);
 *  showBanners?: boolean;
 * }} SubPage
 */

/**
 * @typedef {{
 *  name: string;
 *  path: string;
 *  icon: Element;
 *  exclude?: boolean | ((permissions: string[], dashboardsInfo?: SisenseInfo | null) => boolean);
 *  showBanners?: boolean;
 *  subPages?: SubPage[];
 * }} Page
 */

/**
 * List of pages in the application.
 *
 * @type {Page[]}
 */
const pages = [
  { name: 'Home', path: '/', icon: <HomeIcon />, showBanners: true },
  {
    name: 'Dashboards',
    path: '/dashboards',
    icon: <AnalyticsIcon />,
    exclude: (_permissions, dashboardsInfo) => !((dashboardsInfo?.authenticated && dashboardsInfo?.dashboardsNo > 0) ?? false),
    showBanners: true
  },
  { name: 'Single Piece Lookup', path: '/lookup', icon: <SearchIcon />, showBanners: true, exclude: permissions => !hasSinglePieceLookupPermission(permissions) },
  { name: 'Address Verification', path: '/addressVerification', icon: <ContactMailIcon />, showBanners: true, exclude: permissions => !hasAddressVerificationPermission(permissions) },
  {
    name: 'Create Campaigns',
    path: import.meta.env.VITE_GH_SELECT_URL,
    icon: <NoteAddIcon />,
    exclude: permissions => !hasCreateCampaignPermission(permissions),
    showBanners: false,
    isExternalLink: true
  },
  { name: 'SPL Results', path: '/splResults', exclude: true, showBanners: true },
  { name: 'Contact', path: '/contact', icon: <PhoneIcon />, showBanners: true },
  { name: 'FAQ', path: '/faq', icon: <HelpIcon />, showBanners: true },
  {
    name: 'GHS Admin',
    icon: <AdminPanelSettingsIcon />,
    exclude: permissions => !hasGhsAdminPermissions(permissions),
    subPages: [
      { name: 'Logos', path: '/ghsAdmin/logos', exclude: permissions => !hasGhsAdminLogoPermissions(permissions), showBanners: true },
      { name: 'Dashboard thumbnails', path: '/ghsAdmin/dashboards', exclude: permissions => !hasGhsAdminDashboardThumbnailsPermissions(permissions), showBanners: true },
      { name: 'Banners', path: '/ghsAdmin/banners', exclude: permissions => !hasGhsAdminBannerPermissions(permissions), showBanners: true },
      { name: 'File Processing', path: '/ghsAdmin/fileProcessing', showBanners: true, exclude: permissions => !hasFileProcessingPermission(permissions) },
      { name: 'MailTrak Client LOB', path: '/ghsAdmin/mailTrakClientLOB', showBanners: true, exclude: permissions => !hasMailTrakClientLobPermission(permissions) },
      { name: 'MailTrak Auto Rating', path: '/ghsAdmin/mailTrakAutoRating', showBanners: true , exclude: permissions => !hasMailTrakAutoRatingPermission(permissions) }
    ]
  },
  {
    name: 'Admin',
    icon: <SettingsIcon />,
    exclude: permissions => !hasAdminPermissions(permissions),
    subPages: [
      { name: 'Manage Users/Data Access', path: '/admin/data-access', exclude: permissions => !hasAdminManageUsersDataAccessPermissions(permissions), showBanners: true },
      { name: 'Roles/Permissions', path: '/admin/roles', exclude: permissions => !hasAdminRolesPermissionsPermissions(permissions), showBanners: true },
      { name: 'Edit Campaigns/Versions', path: '/admin/campaigns', exclude: permissions => !hasAdminEditCampaignsVersionsPermissions(permissions), showBanners: true },
      { name: 'In-Home Windows', path: '/admin/inHomeWindows', exclude: permissions => !hasAdminInHomeWindowsPermissions(permissions), showBanners: true },
      { name: 'Edit Customer/LOB', path: '/admin/customers', exclude: permissions => !hasAdminEditCustomersLobsPermissions(permissions), showBanners: true },
      { name: 'Logos', path: '/admin/logos', exclude: permissions => !hasAdminLogosPermissions(permissions), showBanners: true }
    ]
  },
  { name: 'Logout', path: '/logout', exclude: true },
  { name: 'Callback', path: '/callback', exclude: true },
  { name: 'Dashboard', path: '/dashboard', exclude: true }
];

/**
 * Get pages user has access to.
 *
 * @param {string[]} permissions user permissions to validate against page access.
 * @param {SisenseInfo} sisenseInfo dashboards info object
 * @returns {Page[]} list of pages.
 */
function getPages(permissions, sisenseInfo) {
  return pages.filter(page => (page.exclude instanceof Function ? !page.exclude(permissions, sisenseInfo) : !page.exclude));
}

/**
 * Get all pages flattened, that is, containing the subpages as pages.
 *
 * @returns {Array} list of flattened pages.
 */
function getAllPagesFlattened() {
  return pages.reduce((result, page) => {
    if (!page.subPages) {
      result.push({ ...page, fullName: `${page.name}` });
    } else {
      // Add the subpages and don't add the parent page as it's not an actual page (just a menu item to
      // expand/collapse the subpages. For each subpage, add a new property "parentPageName"
      page.subPages.forEach(subPage => {
        result.push({ ...subPage, fullName: `${page.name} - ${subPage.name}` });
      });
    }
    return result;
  }, []);
}

export { getAllPagesFlattened, getPages, pages };
