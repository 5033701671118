import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { Tooltip as MuiTooltip } from '@mui/material';

export const tooltipPlacements = {
  NONE: null,
  TOP: 'top',
  LEFT: 'left',
  RIGHT: 'right',
  BOTTOM: 'bottom'
};

const Tooltip = ({ children, title, placement, ...overrides }) => (
  <MuiTooltip
    title={title}
    arrow={!isNil(placement)}
    {...(placement && { placement })}
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: '#0288D180',
          color: 'text.primary',
          '& .MuiTooltip-arrow': {
            color: '#0288D180'
          }
        }
      }
    }}
    {...overrides}
  >
    {children}
  </MuiTooltip>
);

Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(Object.values(tooltipPlacements))
};

export default Tooltip;
