import { ExpandMore } from '@mui/icons-material';
import { Pagination as MuiPagination, TextField, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import {
  DataGridProProps,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridEditInputCell,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridValidRowModel,
  DataGridPro as MuiDataGrid,
  gridDetailPanelExpandedRowsContentCacheSelector,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import { isValidElement, useState } from 'react';
import IconButton from '~/components/IconButton';

export const DETAIL_PANEL_VARIANTS = {
  STANDARD: 'standard',
  ACTIONS: 'actions'
} as const;

export function DataGridCellErrorTooltip(props: TooltipProps) {
  const { className, ...rest } = props;

  return (
    <Tooltip
      placement="bottom"
      arrow
      {...rest}
      classes={{ popper: className }}
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme => theme.palette.error.main,
          color: theme => theme.palette.error.contrastText
        },
        [`& .${tooltipClasses.arrow}`]: {
          color: theme => theme.palette.error.main
        }
      }}
    />
  );
}

type DataGridEditInputCellProps = {
  error?: string;
};

export function DataGridEditInputCell(props: DataGridEditInputCellProps) {
  const { error } = props;

  return (
    <DataGridCellErrorTooltip open={Boolean(error)} title={error}>
      <GridEditInputCell {...props} />
    </DataGridCellErrorTooltip>
  );
}

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const [goTo, setGoTo] = useState('');

  const handlePageChange = event => {
    if (event.target.value.trim().length === 0) {
      setGoTo('');
      return;
    }

    if (/^\d+$/.test(event.target.value)) {
      const pageNumber = parseInt(event.target.value, 10);

      setGoTo(event.target.value);
      onPageChange(event, pageNumber - 1);
    }
  };

  return (
    <>
      {pageCount > 5 && (
        <>
          <Typography variant="body2" sx={{ ml: 2, mr: 1 }} style={{ whiteSpace: 'nowrap' }}>
            Go to page
          </Typography>
          <TextField
            size="small"
            value={goTo}
            onChange={handlePageChange}
            inputProps={{ maxLength: 3 }}
            sx={{
              minWidth: '3em',
              maxWidth: '3em',
              '& .MuiInputBase-input': {
                px: 1,
                py: 0.5,
                fontSize: '1.3986rem'
              }
            }}
          />
        </>
      )}
      <MuiPagination
        color="primary"
        className={className}
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    </>
  );
}

Pagination.propTypes = {
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  className: PropTypes.string
};

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

// See https://mui.com/x/react-data-grid/master-detail/#customizing-the-detail-panel-toggle
const CustomDetailPanelToggle = ({ id, value: isExpanded }) => {
  const apiRef = useGridApiContext();

  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton size="small" tabIndex={-1} disabled={!hasDetail} aria-label={isExpanded ? 'Close' : 'Open'}>
      <ExpandMore
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: theme =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest
            })
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
};

CustomDetailPanelToggle.propTypes = {
  id: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export function DefaultToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

type DataGridProps<R extends GridValidRowModel> = DataGridProProps<R> &
  React.RefAttributes<HTMLDivElement> & {
    height?: number;
    defaultPageSize?: number;
    getDetailPanelVariant?: (typeof DETAIL_PANEL_VARIANTS)[keyof typeof DETAIL_PANEL_VARIANTS];
  };

export default function DataGrid<R extends GridValidRowModel>(props: DataGridProps<R>) {
  const {
    columns,
    rows,
    initialState,
    height,
    pagination,
    pageSizeOptions = [10, 25, 50, 100],
    defaultPageSize = 10,
    getDetailPanelContent,
    getDetailPanelHeight,
    getDetailPanelVariant,
    apiRef,
    slots,
    slotProps,
    sx,
    ...overrides
  } = props;

  return (
    <div style={{ width: '100%', height }}>
      <MuiDataGrid
        columns={
          getDetailPanelContent && getDetailPanelVariant === DETAIL_PANEL_VARIANTS.ACTIONS
            ? [
                ...columns,
                {
                  ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                  renderHeader: always('Actions'),
                  width: 90,
                  align: 'center',
                  renderCell: ({ id, value }) => <CustomDetailPanelToggle id={id} value={value} />
                }
              ]
            : columns
        }
        rows={rows}
        initialState={{
          ...(pagination && {
            pagination: {
              paginationModel: { pageSize: defaultPageSize }
            }
          }),
          ...initialState
        }}
        pagination={pagination}
        pageSizeOptions={pageSizeOptions}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        apiRef={apiRef}
        slots={{
          ...(pagination && { pagination: CustomPagination }),
          ...slots
        }}
        slotProps={slotProps}
        sx={{
          '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox), & .MuiDataGrid-cell': {
            px: 2
          },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: theme => theme?.palette?.misc?.dataGridHeader
          },
          ...sx
        }}
        {...overrides}
      />
    </div>
  );
}
