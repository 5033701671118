import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment as MuiInputAdornment, TextField as MuiTextField } from '@mui/material';
import { isEmpty, isNil } from 'ramda';
import { useRef } from 'react';

export const textFieldSizes = {
  MEDIUM: 'medium',
  SMALL: 'small'
} as const;

export const textFieldVariants = {
  STANDARD: 'standard',
  FILLED: 'filled',
  OUTLINED: 'outlined'
} as const;

type TextFieldProps = Parameters<typeof MuiTextField>[0] & {
  suffix?: React.ReactNode;
};

export default function TextField(props: TextFieldProps) {
  const { type, disabled, helperText, prefix, suffix, size = 'medium', error, onChange, value, InputProps, ...overrides } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const hasPrefix = !isEmpty(prefix) && !isNil(prefix);
  const hasSuffix = !isEmpty(suffix) && !isNil(suffix);

  const isSearchType = type === 'search';

  const clearInput = () => {
    if (isSearchType) {
      const input = inputRef.current?.querySelector('input');

      if (input) {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;

        if (nativeInputValueSetter) {
          nativeInputValueSetter.call(input, '');
          const event = new Event('input', { bubbles: true });

          input.dispatchEvent(event);
        }
      }
    }
  };

  return (
    <MuiTextField
      type={isSearchType ? 'text' : type}
      value={value}
      size={size}
      error={error}
      color={error ? null : 'primary'}
      disabled={disabled}
      onChange={onChange}
      helperText={helperText}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        ref: inputRef,
        ...((hasPrefix || isSearchType) && {
          startAdornment: <MuiInputAdornment position="start">{hasPrefix ? prefix : <SearchIcon />}</MuiInputAdornment>
        }),
        ...((hasSuffix || isSearchType) && {
          endAdornment: hasSuffix ? (
            <MuiInputAdornment position="end">{hasSuffix ? suffix : <ClearIcon fontSize="small" />}</MuiInputAdornment>
          ) : (
            <IconButton onClick={clearInput}>
              <ClearIcon fontSize="small" />
            </IconButton>
          )
        }),
        sx: {
          'input:-webkit-autofill': {
            '-webkit-box-shadow': theme => `0 0 0 30px ${theme.palette.background.paper} inset !important`
          },
          ...(InputProps?.sx || {})
        },
        ...InputProps
      }}
      {...overrides}
    />
  );
}
