import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from 'react';
import BgTitleStyle from "./BgTitleStyle";

/**
 * Dialog for confirmation of banner deletion.
 *
 * @param {object} props properties
 * @param {Function} props.onClose callback function to handle output.
 * @param {Function} props.bannerName banner name that is going to be deleted *
 * @param {boolean} props.open banner name that is going to be deleted *
 * @returns {React.ReactNode} Return Dialog element
 * @class
 */
const DeleteConfirmationDialog = ({ onClose, bannerName, open }) => {    

    const handleCancel = (e) => {
        onClose(e);
    };

    const handleOk = (e) => {
        onClose(e, "ok");
    };

    return (
        <Dialog fullWidth maxWidth="md" sx={{ m: 0, p: 3 }} open={open}>
            <DialogTitle>Delete Banner Confirmation</DialogTitle>
            <DialogContent>
                <Typography variant="body1" display="inline">
                    <b>Are you sure you wish to delete the</b>
                </Typography>
                <Typography color="secondary" display="inline"> <BgTitleStyle>{bannerName}</BgTitleStyle></Typography>
                <Typography variant="body1" display="inline"> <b>banner?</b></Typography>
                <Typography sx={{ whiteSpace: "pre-line" }} >{"\n"}</Typography>
                <Typography variant="body1">If you wish to reuse this banner in the future, click Cancel and you can just mark the banner as inactive.</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" autoFocus onClick={handleCancel} variant="contained">
                    Cancel
                </Button>
                <Button color="error" onClick={handleOk} variant="contained">Delete</Button>
            </DialogActions>
        </Dialog>
    )
}

DeleteConfirmationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    bannerName: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired
};

export default DeleteConfirmationDialog;
