/**
 * @typedef {{childId: number, lobName: string, ytdJobQuantity: number, ytdRecordCount: number}} LineOfBusiness
 * @typedef {{clientId: string; clientName: string; linesOfBusiness: Array<LineOfBusiness>}} Client
 * @typedef {{clientId: number; lobValue: string; stidValue: string}} ExistingRatesRequest
 * @typedef {{
 *   serviceTypeName: number,
 *   extraServiceName: string,
 *   unit: string,
 *   rate: number,
 *   minRatePerVersion: number,
 *   minRatePerJob: number,
 *   breakPoint: number,
 *   creationDate: string,
 *   lastUpdate: string,
 *   active: number,
 *   lastUpdateStr: string,
 *   creationDateStr: string
 * }} CurrentRatesRow
 * @typedef {{
 *   modeValue: string;
 *   clientId: string;
 *   rateDecorator: string;
 *   rateValue: string;
 *   minRatePerVersionValue: string;
 *   minRatePerJobValue: string;
 *   breakPointValue: string;
 *   lobValue: Array<string>;
 *   stidValue: Array<string>
 * }} SaveDeleteRatesRequest
 * @typedef {{
 *   rateValue: string | null;
 *   rateDecoratorValue: string;
 *   minRatePerVersionValue: string;
 *   minRatePerJobValue: string;
 *   breakPointValue: string;
 *   modeValue: string;
 * }} EditRatesFormData
 * @typedef {{
 *   rateValueError: string;
 *   rateDecoratorValueError: string;
 *   minRatePerVersionValueError: string;
 *   minRatePerJobValueError: string;
 *   breakPointValueError: string;
 *   modeValueError: string;
 * }} EditRatesFormDataErrors
 */
import { combine, createEffect, createEvent, createStore, sample } from 'effector';
import { ghApi } from '../../services/DataService';
import { spread } from 'patronum';
import { $$snackbar } from '../../components/SnackbarRoot/model';
import isEmpty from 'lodash/isEmpty';

const VALID_FLOAT_REGEX = /^(?:0|[1-9]\d*)(?:\.\d+)?$/;

//-------------------------------- Stores --------------------------------
const $clientList = createStore(/** @type {Array<Client> | null} */ (null));
const $lobList = createStore(/** @type {Array<LineOfBusiness> | null} */ (null));
const $stidList = createStore(/** @type {Array<number> | null} */ (null));
const $clientValue = createStore(/** @type {Client | null} */ (null));
const $lobValue = createStore(/** @type {Array<LineOfBusiness> | Array<>} */ ([]));
const $stidValue = createStore(/** @type {Array<number> | Array<>} */ ([]));

/** @type {import('effector').Store<boolean>} */
const $getExistingRatesDisabled = combine([$clientValue, $lobValue, $stidValue], ([clientValue, lobValue, stidValue]) => {
  return clientValue === null || isEmpty(lobValue) || isEmpty(stidValue);
});

const $editRatesFormData = createStore(
  /** @type {EditRatesFormData} */ ({
    rateValue: '',
    rateDecoratorValue: '/M',
    minRatePerVersionValue: '',
    minRatePerJobValue: '',
    breakPointValue: '',
    modeValue: 'C'
  })
);

const $editRatesFormDataErrors = createStore(
  /** @type {EditRatesFormDataErrors} */ ({
    rateValueError: '',
    rateDecoratorValueError: '',
    minRatePerVersionValueError: '',
    minRatePerJobValueError: '',
    breakPointValueError: '',
    modeValueError: ''
  })
);

/** @type {import('effector').Store<boolean>} */
const $disableSaveRate = combine([$editRatesFormDataErrors, $clientValue, $lobValue, $stidValue], ([editRatesFormDataErrors, clientValue, lobValue, stidValue]) => {
  return !Object.values(editRatesFormDataErrors).every(value => value === '' || value == null) || clientValue === null || isEmpty(lobValue) || isEmpty(stidValue);
});

/** @type {import('effector').Store<boolean>} */
const $disableDeleteRate = combine([$editRatesFormData, $clientValue, $lobValue, $stidValue], ([editRatesFormData, clientValue, lobValue, stidValue]) => {
  return clientValue === null || isEmpty(lobValue) || isEmpty(stidValue) || editRatesFormData.modeValue !== 'U' || editRatesFormData.modeValue == null;
});

const $currentRates = createStore(/** @type {Array<CurrentRatesRow>} */ ([]));
const $currentRatesExpanded = createStore(/** @type {boolean} */ (false));

//-------------------------------- Events --------------------------------
/** @type {import('effector').EventCallable<boolean>} */
const pageMounted = createEvent();
/** @type {import('effector').EventCallable<Client>} */
const clientSelected = createEvent();
/** @type {import('effector').EventCallable<LineOfBusiness>} */
const lobSelected = createEvent();
/** @type {import('effector').EventCallable<number>} */
const stidSelected = createEvent();
/** @type {import('effector').EventCallable<void>} */
const noClientSelected = createEvent();
/** @type {import('effector').EventCallable<void>} */
const noLOBSelected = createEvent();
/** @type {import('effector').EventCallable<void>} */
const noSTIDSelected = createEvent();
/** @type {import('effector').EventCallable<void>} */
const getExistingRatesClicked = createEvent();
/** @type {import('effector').EventCallable<void>} */
const clearClicked = createEvent();

/** @type {import('effector').EventCallable<{ name: keyof FormData; value: string }>} */
const editRatesFormValueChanged = createEvent();
/** @type {import('effector').EventCallable<void>} */
const saveRateClicked = createEvent();
/** @type {import('effector').EventCallable<void>} */
const saveRateClickedValidation = createEvent();
/** @type {import('effector').EventCallable<void>} */
const deleteRateClicked = createEvent();

/** @type {import('effector').EventCallable<boolean>} */
const currentRatesClicked = createEvent();
/** @type {import('effector').EventCallable<void>} */
const resetRateDataAndInput = createEvent();
const resetEditRate = createEvent();

//-------------------------------- Effects -------------------------------

/** @type {import('effector').Effect<void>} */
const getClientListFx = createEffect(async () => {
  return ghApi.get('/MailTrakAutoRating/clients/list').then(response => response.data);
});

/** @type {import('effector').Effect<number>} */
const getSTIDListFx = createEffect(async clientId => {
  return ghApi.get(`/MailTrakAutoRating/clients/${clientId}`).then(response => response.data);
});

/** @type {import('effector').Effect<ExistingRatesRequest>} */
const getExistingRatesFx = createEffect(async ({ clientId, lobValue, stidValue }) => {
  return ghApi
    .get(`/MailTrakAutoRating/clients/${clientId}/rates`, {
      params: {
        lobs: lobValue,
        stids: stidValue
      }
    })
    .then(response => response.data);
});

/** @type {import('effector').Effect<SaveDeleteRatesRequest>} */
const saveRateFx = createEffect(async ({ modeValue, clientId, rateDecorator, rateValue, minRatePerVersionValue, minRatePerJobValue, breakPointValue, lobValue, stidValue }) => {
  return ghApi
    .put(`/MailTrakAutoRating/clients/${clientId}/rates`, {
      mode: modeValue,
      rateDesc: rateDecorator,
      rate: rateValue,
      breakPoint: breakPointValue,
      minRatePerVersion: minRatePerVersionValue,
      minRatePerJob: minRatePerJobValue,
      lobs: lobValue,
      stids: stidValue
    })
    .then(response => response.data);
});

/** @type {import('effector').Effect<SaveDeleteRatesRequest>} */
const deleteRateFx = createEffect(async ({ modeValue, clientId, rateDecorator, rateValue, minRatePerVersionValue, minRatePerJobValue, breakPointValue, lobValue, stidValue }) => {
  return ghApi
    .delete(`/MailTrakAutoRating/clients/${clientId}/rates`, {
      params: {
        mode: modeValue,
        rateDesc: rateDecorator,
        rate: rateValue,
        breakPoint: breakPointValue,
        minRatePerVersion: minRatePerVersionValue,
        minRatePerJob: minRatePerJobValue,
        lobs: lobValue,
        stids: stidValue
      }
    })
    .then(response => response.data);
});

const getRateReportFx = createEffect(async ({ clientId, clientName, lobValue, stidValue }) => {
  return ghApi
    .get(`/MailTrakAutoRating/clients/${clientId}/billingReport`, {
      params: {
        client: clientName,
        lobs: lobValue,
        stids: stidValue
      }
    })
    .then(response => response.data);
});

//-------------------------------- Samples -------------------------------

//-- Selection Field Section --

//When page mounts we want to reinit all list and value stores and get the list of available clients.
sample({
  clock: pageMounted.filter({ fn: Boolean }).map(() => ({})),
  target: [$clientList.reinit, $clientValue.reinit, $lobList.reinit, $lobValue.reinit, $stidList.reinit, $stidValue.reinit, resetRateDataAndInput, getClientListFx]
});

//When we clear the client field we want to ensure that the LOB and STID list and values get reset as they are dependent on the client.
sample({
  clock: noClientSelected,
  target: [$clientValue.reinit, $lobList.reinit, $lobValue.reinit, $stidList.reinit, $stidValue.reinit, resetRateDataAndInput]
});

//When we clear the LOB field we want to ensure that just the LOB and STID values are reinit as the list's of each field are dependent on the client selected.
sample({
  clock: noLOBSelected,
  target: [$lobValue.reinit, $stidValue.reinit, resetRateDataAndInput]
});

//When the STID field is clear we only want to reinit the STID value as no other fields are dependent on this value.
sample({
  clock: noSTIDSelected,
  target: [$stidValue.reinit, resetRateDataAndInput]
});

//-----------------------------

//-- Data Retrieval Section --

//When we getClientListFx is done fill the clientList value with that data.
sample({
  clock: getClientListFx.doneData,
  target: $clientList
});

// When getSTIDListFx is done map servicedCodesUsed for that client returned and fill the stidList value with that data
sample({
  clock: getSTIDListFx.doneData,
  fn: client => {
    return client.serviceCodesUsed.map(serviceCode => serviceCode.serviceCodeNbr);
  },
  target: $stidList
});

//When getExistingRatesFx is done check if numberOfRatesFound is 1 and if so map the values of the rate found to the editRatesFormData.
sample({
  clock: getExistingRatesFx.doneData,
  filter: existingRates => Number(existingRates.numberOfRatesFound) === 1,
  fn: existingRates => {
    return {
      rateValue: existingRates.rate.toString(),
      rateDecoratorValue: existingRates.rateDesc.toString(),
      minRatePerVersionValue: existingRates.minRatePerVersion?.toString(),
      minRatePerJobValue: existingRates.minRatePerJob?.toString(),
      breakPointValue: existingRates.breakPoint?.toString(),
      modeValue: existingRates.mode.toString()
    };
  },
  target: $editRatesFormData
});

//When getExistingRatesFx is done check if numberOfRatesFound other than 1 and if so trigger snackbar with corresponding error.
sample({
  clock: getExistingRatesFx.doneData,
  filter: existingRates => Number(existingRates.numberOfRatesFound) !== 1,
  fn: existingRates => {
    let message =
      Number(existingRates.numberOfRatesFound) === 0 ? 'No existing rates found' : `${existingRates.numberOfRatesFound} different rates found. Please change your search criteria and search again.`;
    return {
      message: message,
      severity: /** @type {const} */ ('error'),
      autoHideDuration: 6000
    };
  },
  target: $$snackbar.open
});

//When getRateReportFx is done map all billingReportDetails for every LOB in the client, and the lobName and a unique ID for DataGrid display.
sample({
  clock: getRateReportFx.doneData,
  fn: rateReport => {
    return rateReport.client.linesOfBusiness.flatMap(lob =>
      lob.billingReportDetail.map(billingReportLine => ({
        ...billingReportLine,
        lobName: lob.lobName,
        uid: `${rateReport.client.clientName}-${lob.lobName}-${billingReportLine.serviceTypeName}`.replace(/ /g, '_')
      }))
    );
  },
  target: $currentRates
});

//When getRateReportFx, getExistingRatesFx, getSTIDListFx or getClientListFx fail for some reason we display an error popup.
sample({
  clock: [getRateReportFx.failData, getExistingRatesFx.failData, getSTIDListFx.failData, getClientListFx.failData],
  fn: err => {
    return {
      message: err?.response?.data?.message ?? "There's been an error retrieving client data",
      severity: /** @type {const} */ ('error'),
      autoHideDuration: 6000
    };
  },
  target: $$snackbar.open
});

//When saveRateFX is done we want to open the snackbar and display success and reset the Edit Rate form.
sample({
  clock: saveRateFx.doneData,
  fn: () => {
    return {
      snackbarData: {
        message: 'Success saving rate',
        severity: /** @type {const} */ ('success'),
        autoHideDuration: 6000
      },
      resetRateDataAndInputTrigger: null,
      currentRatesClickedTrigger: true
    };
  },
  target: spread({
    snackbarData: $$snackbar.open,
    resetRateDataAndInputTrigger: resetRateDataAndInput,
    currentRatesClickedTrigger: currentRatesClicked
  })
});

//When deleteRateFX is done we want to open the snackbar and display success and reset the Edit Rate form.
sample({
  clock: deleteRateFx.doneData,
  fn: () => {
    return {
      snackbarData: {
        message: 'Success deleting rate',
        severity: /** @type {const} */ ('success'),
        autoHideDuration: 6000
      },
      resetRateDataAndInputTrigger: null,
      currentRatesClickedTrigger: true
    };
  },
  target: spread({
    snackbarData: $$snackbar.open,
    resetRateDataAndInputTrigger: resetRateDataAndInput,
    currentRatesClickedTrigger: currentRatesClicked
  })
});

//When saveRateFX is fails we want to open the snackbar and display error message.
sample({
  clock: saveRateFx.failData,
  fn: err => {
    return {
      message: err?.response?.data?.message ?? "There's been an error saving rate",
      severity: /** @type {const} */ ('error'),
      autoHideDuration: 6000
    };
  },
  target: $$snackbar.open
});

//When deleteRateFx is fails we want to open the snackbar and display error message.
sample({
  clock: deleteRateFx.failData,
  fn: err => {
    return {
      message: err?.response?.data?.message ?? "There's been an error deleting rate",
      severity: /** @type {const} */ ('error'),
      autoHideDuration: 6000
    };
  },
  target: $$snackbar.open
});

//-----------------------------

//-- Event Trigger Section --

//On the resetEditRate event we want to reset all editRates form data and error store.
sample({
  clock: resetEditRate,
  target: [$editRatesFormData.reinit, $editRatesFormDataErrors.reinit]
});

//On the resetRateDataAndInput event we want to reset all editRates form data and Current Rates store and expanded state store.
sample({
  clock: resetRateDataAndInput,
  target: [$editRatesFormData.reinit, $editRatesFormDataErrors.reinit, $currentRates.reinit, $currentRatesExpanded.reinit]
});

//When a client is selected we want to fill the $lobList with the corresponding LOB's for that client.
sample({
  clock: clientSelected,
  fn: client => client.linesOfBusiness,
  target: $lobList
});

//When a client is selected we want to set the value as the $clientValue and reset Current Rates related stores along with LOB and STID value stores as they vary per client.
sample({
  clock: clientSelected,
  target: [$clientValue, $lobValue.reinit, $stidValue.reinit, resetRateDataAndInput]
});

//When a client is selected we want to send the clientId to the getSTIDListFx effect so that we can retrieve that clients available STIDS.
sample({
  clock: clientSelected,
  fn: client => client.clientId,
  target: getSTIDListFx
});

//When a LOB is selected we want to set the value as the $lobValue and reset Current Rates related stores as they vary per LOB.
sample({
  clock: lobSelected,
  target: [$lobValue, $currentRates.reinit, $currentRatesExpanded.reinit]
});

//When a STID is selected we want to set the value as the $stidValue and reset Current Rates related stores as they vary per STID.
sample({
  clock: stidSelected,
  target: [$stidValue, $currentRates.reinit, $currentRatesExpanded.reinit]
});

//When clearClicked we want to reset $clientValue, $lobValue, $stidValue and call resetRateDataAndInput to reset all further aspects of the page.
sample({
  clock: clearClicked,
  target: [$clientValue.reinit, $lobValue.reinit, $stidValue.reinit, resetRateDataAndInput]
});

//When getExistingRatesClicked we want retrieve the values from $clientValue, $lobValue and $stidValue, then extract the necessary fields from those values to pass to getExistingRatesFx.
sample({
  source: [$clientValue, $lobValue, $stidValue],
  clock: getExistingRatesClicked,
  fn: ([clientValue, lobValue, stidValue]) => {
    return {
      clientId: clientValue.clientId,
      lobValue: lobValue.map(lob => lob.childId),
      stidValue: stidValue
    };
  },
  target: getExistingRatesFx
});

// When editRatesFormValueChanged we want to get the name and value passed into the event in order to overwrite that field in the existing editRatesFormData and set the $editRatesFormData to the newly created editRatesFormData.
sample({
  source: $editRatesFormData,
  clock: editRatesFormValueChanged,
  fn: (editRatesFormData, { name, value }) => {
    let newEditRatesFormData;

    if (name === 'minRatePerVersionValue') {
      newEditRatesFormData = { ...editRatesFormData, minRatePerJobValue: '', [name]: value.startsWith('.') ? '0' + value : value };
    } else if (name === 'minRatePerJobValue') {
      newEditRatesFormData = { ...editRatesFormData, minRatePerVersionValue: '', [name]: value.startsWith('.') ? '0' + value : value };
    } else {
      newEditRatesFormData = { ...editRatesFormData, [name]: value.startsWith('.') ? '0' + value : value };
    }

    return newEditRatesFormData;
  },
  target: $editRatesFormData
});

// When editRatesFormValueChanged we want to check what field was changed so that we can calculate the breakpoint if necessary.
sample({
  source: [$editRatesFormData, $editRatesFormDataErrors],
  clock: editRatesFormValueChanged,
  filter: ([_editRatesFormData, _editRatesFormDataErrors], { name }) => name === 'minRatePerJobValue' || name === 'minRatePerVersionValue' || name === 'rateValue',
  fn: ([editRatesFormData, editRatesFormDataErrors]) => {
    let newEditRatesFormData = { ...editRatesFormData, breakPointValue: '' };

    if (editRatesFormData.rateDecoratorValue === '/M' && editRatesFormData.rateValue && Object.values(editRatesFormDataErrors).every(value => value === '' || value === null)) {
      if (editRatesFormData.minRatePerVersionValue) {
        newEditRatesFormData.breakPointValue = String(Math.trunc((parseFloat(editRatesFormData.minRatePerVersionValue) / parseFloat(editRatesFormData.rateValue)) * 1000));
      }
      if (editRatesFormData.minRatePerJobValue) {
        newEditRatesFormData.breakPointValue = String(Math.trunc((parseFloat(editRatesFormData.minRatePerJobValue) / parseFloat(editRatesFormData.rateValue)) * 1000));
      }
    }
    return newEditRatesFormData;
  },
  target: $editRatesFormData
});

// When editRatesFormValueChanged we want to check what field was changed from the name and if its rateValue we want to apply the validation below and send any errors to the $editRatesFormDataErrors.
sample({
  source: $editRatesFormDataErrors,
  clock: editRatesFormValueChanged,
  filter: (_, { name }) => name === 'rateValue',
  fn: (editRatesFormDataErrors, { value }) => ({
    ...editRatesFormDataErrors,
    rateValueError: value && !VALID_FLOAT_REGEX.test(value) ? 'Please enter valid Rate' : ''
  }),
  target: $editRatesFormDataErrors
});

// When editRatesFormValueChanged we want to check what field was changed from the name and if its minRatePerVersionValue we want to apply the validation below and send any errors to the $editRatesFormDataErrors.
sample({
  source: [$editRatesFormData, $editRatesFormDataErrors],
  clock: editRatesFormValueChanged,
  filter: ([_editRatesFormData, _editRatesFormDataErrors], { name }) => name === 'minRatePerVersionValue',
  fn: ([editRatesFormData, editRatesFormDataErrors], { value }) => {
    let newEditRatesFormDataErrors = { ...editRatesFormDataErrors, minRatePerVersionValueError: '' };

    if (value && editRatesFormData.rateDecoratorValue === '/M' && newEditRatesFormDataErrors.minRatePerJobValueError.includes('/M')) {
      newEditRatesFormDataErrors.minRatePerJobValueError = '';
    }

    if (value && !VALID_FLOAT_REGEX.test(value)) {
      newEditRatesFormDataErrors.minRatePerVersionValueError = 'Please enter valid Rate Per Version';
    }

    return newEditRatesFormDataErrors;
  },
  target: $editRatesFormDataErrors
});

// When editRatesFormValueChanged we want to check what field was changed from the name and if its minRatePerJobValue we want to apply the validation below and send any errors to the $editRatesFormDataErrors.
sample({
  source: [$editRatesFormData, $editRatesFormDataErrors],
  clock: editRatesFormValueChanged,
  filter: ([_editRatesFormData, _editRatesFormDataErrors], { name }) => name === 'minRatePerJobValue',
  fn: ([editRatesFormData, editRatesFormDataErrors], { value }) => {
    let newEditRatesFormDataErrors = { ...editRatesFormDataErrors, minRatePerJobValueError: '' };

    if (value && editRatesFormData.rateDecoratorValue === '/M' && newEditRatesFormDataErrors.minRatePerVersionValueError.includes('/M')) {
      newEditRatesFormDataErrors.minRatePerVersionValueError = '';
    }

    if (value && !VALID_FLOAT_REGEX.test(value)) {
      newEditRatesFormDataErrors.minRatePerJobValueError = 'Please enter valid Rate Per Job';
    }

    return newEditRatesFormDataErrors;
  },
  target: $editRatesFormDataErrors
});

//// When saveRateClickedValidation we want to check additional validation logic only possible before sending a save request to the API, as doing this validation realtime would be not pleasing for the user.
sample({
  source: [$editRatesFormData, $editRatesFormDataErrors],
  clock: saveRateClickedValidation,
  fn: ([editRatesFormData, editRatesFormDataErrors]) => {
    let newEditRatesFormDataErrors = { ...editRatesFormDataErrors };

    if (!editRatesFormData.rateValue) {
      newEditRatesFormDataErrors.rateValueError = 'Rate must not be empty';
    }

    if (editRatesFormData.rateDecoratorValue === '/M' && !editRatesFormData.minRatePerVersionValue && !editRatesFormData.minRatePerJobValue) {
      newEditRatesFormDataErrors.minRatePerVersionValueError = 'For rate type /M Minimum Rate Per Package OR Minimum Rate Per Job is mandatory';
      newEditRatesFormDataErrors.minRatePerJobValueError = 'For rate type /M Minimum Rate Per Package OR Minimum Rate Per Job is mandatory';
    } else if (editRatesFormData.rateDecoratorValue === 'Flat' && !editRatesFormData.minRatePerJobValue) {
      newEditRatesFormDataErrors.minRatePerJobValueError = 'For rate type Flat Minimum Rate Per Job is mandatory';
    }

    if (!Object.values(newEditRatesFormDataErrors).every(value => value === '' || value === null)) {
      return { editRatesFormDataErrors: newEditRatesFormDataErrors };
    } else {
      return { saveRate: null };
    }
  },
  target: spread({
    saveRate: saveRateClicked,
    editRatesFormDataErrors: $editRatesFormDataErrors
  })
});

// When saveRateClicked we use the 'source(s)' as out data pool and pass the necessary data to the saveRateFx in the structure it expects.
sample({
  source: [$editRatesFormData, $clientValue, $lobValue, $stidValue],
  clock: saveRateClicked,
  fn: ([editRatesFormData, clientValue, lobValue, stidValue]) => ({
    modeValue: editRatesFormData.modeValue,
    clientId: clientValue.clientId,
    rateDecorator: editRatesFormData.rateDecoratorValue,
    rateValue: editRatesFormData.rateValue,
    breakPointValue: editRatesFormData.breakPointValue,
    minRatePerVersionValue: editRatesFormData.minRatePerVersionValue,
    minRatePerJobValue: editRatesFormData.minRatePerJobValue,
    lobValue: lobValue.map(lob => lob.childId),
    stidValue: stidValue
  }),
  target: saveRateFx
});

// When deleteRateClicked we use the 'source(s)' as out data pool and pass the necessary data to the deleteRateFx in the structure it expects.
sample({
  source: [$editRatesFormData, $clientValue, $lobValue, $stidValue],
  clock: deleteRateClicked,
  fn: ([editRatesFormData, clientValue, lobValue, stidValue]) => ({
    modeValue: editRatesFormData.modeValue,
    clientId: clientValue.clientId,
    rateDecorator: editRatesFormData.rateDecoratorValue,
    rateValue: editRatesFormData.rateValue,
    breakPointValue: editRatesFormData.breakPointValue,
    minRatePerVersionValue: editRatesFormData.minRatePerVersionValue,
    minRatePerJobValue: editRatesFormData.minRatePerJobValue,
    lobValue: lobValue.map(lob => lob.childId),
    stidValue: stidValue
  }),
  target: deleteRateFx
});

// We simply pass the currentRatesClicked directly to the $currentRatesExpanded to keep track of the accordion's state.
sample({
  clock: currentRatesClicked,
  target: $currentRatesExpanded
});

// When currentRatesClicked we use the 'source(s)' as out data pool and pass the necessary data to the getRateReportFx in the structure it expects only if expanded is true (meaning the accordion must be closed first to make the request.).
sample({
  source: [$clientValue, $lobValue, $stidValue],
  clock: currentRatesClicked,
  filter: (_, isExpanded) => isExpanded,
  fn: ([clientValue, lobValue, stidValue]) => {
    return {
      clientId: clientValue.clientId,
      clientName: clientValue.clientName,
      lobValue: lobValue.map(lob => lob.childId),
      stidValue: stidValue
    };
  },
  target: getRateReportFx
});

//-----------------------------

export const $$mailTrakAutoRatingPage = {
  // Stores
  $clientList,
  $lobList,
  $stidList,
  $clientValue,
  $lobValue,
  $stidValue,
  $getExistingRatesDisabled,
  $currentRates,
  $editRatesFormData,
  $editRatesFormDataErrors,
  $disableSaveRate,
  $disableDeleteRate,
  $currentRatesExpanded,

  // While getClientListFx in progress the $clientListLoading is set to true and is updated to false once effect is completed successfully or not.
  $clientListLoading: getClientListFx.pending,
  $rateReportLoading: getRateReportFx.pending,
  $stidListLoading: getSTIDListFx.pending,

  // Events
  pageMounted,
  clientSelected,
  lobSelected,
  stidSelected,
  noClientSelected,
  noLOBSelected,
  noSTIDSelected,
  getExistingRatesClicked,
  clearClicked,
  editRatesFormValueChanged,
  saveRateClicked,
  saveRateClickedValidation,
  deleteRateClicked,
  currentRatesClicked,
  resetEditRate,

  //Effects
  getClientListFx,
  getExistingRatesFx,
  getRateReportFx,
  getSTIDListFx,
  saveRateFx,
  deleteRateFx
};
