/* eslint-disable no-nested-ternary */

import { Tabs as MuiTabs, Tab } from '@mui/material';
import { tabsClasses, TabsProps } from '@mui/material/Tabs';

export const tabVariants = {
  SCROLLABLE: 'scrollable',
  FULL_WIDTH: 'fullWidth'
} as const;

export const tabOrientations = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
} as const;

/** @task Update props type definition to include the `value` prop for each child component and use union of all possible values as second argument type for onChange function */
export default function Tabs(props: TabsProps) {
  const { value = 0, variant = tabVariants.SCROLLABLE, orientation = tabOrientations.HORIZONTAL, scrollButtons = false, ...overrides } = props;

  return (
    <MuiTabs
      value={value}
      variant={variant}
      orientation={orientation}
      scrollButtons={scrollButtons}
      textColor="primary"
      indicatorColor="primary"
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': { opacity: 0.3 }
        }
      }}
      {...overrides}
    />
  );
}

export { Tab };
