import { AlertColor } from '@mui/material/Alert';
import { createEffect } from 'effector';
import { ghApi } from './DataService';

export type Banner = {
  id: string;
  name: string;
  content: string;
  pages: string[];
  severity: AlertColor;
  active: boolean;
  updatedAt: string;
};

/**
 * Update or creates a banner
 *
 * @param {object} banner banner to update
 * @returns {Promise<any|undefined>} upload result
 */
export const saveBanner = async (banner: Banner) => {
  let saveUrl = '/banners';

  if (banner.id) {
    saveUrl += '/' + banner.id;
  }

  const response = await ghApi.post(saveUrl, banner).then(res => res.data);
  return response;
};

export const deleteBanner = async (banner: Banner, accessToken: string) => {
  const saveUrl = `${import.meta.env.VITE_API_SERVER_URL}/banners/${banner.id}`;
  const response = await fetch(saveUrl, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: ''
  });

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  return true;
};

const getBannersForPageFx = createEffect<string, Banner[]>(async pageName => {
  return ghApi.get('/banners', { params: { pageName } }).then(res => res.data);
});

const getAllBannersFx = createEffect<void, Banner[]>(async () => {
  return ghApi.get('/banners/all').then(res => res.data);
});

export const updateResellerBannerOptions = createEffect<{ resellerId: unknown } & object, unknown>(async params => {
  const { resellerId, ...requestBody } = params;
  return ghApi.post(`/banners/reseller/${resellerId}/options`, requestBody).then(response => response.data);
});

export const getResellerBannerOptions = createEffect<{ resellerId: unknown }, unknown>(async params => {
  const { resellerId } = params;
  return ghApi.get(`/banners/reseller/${resellerId}/options`).then(response => response.data);
});

export const $$bannerService = {
  updateResellerBannerOptions,
  getResellerBannerOptions,

  getBannersForPageFx,
  getAllBannersFx
};
