import { createEffect, createStore, sample } from 'effector';
import { ghApi } from '../DataService';

const $activePersona = createStore(/** @type {Persona | null} */ (null));
const $availablePersonas = createStore(/** @type {Persona[]} */ ([]));
const $isMapActivePersona = $activePersona.map(persona => persona?.ghsSystem === 'MAP' || false);
const $isStActivePersona = $activePersona.map(persona => persona?.ghsSystem === 'ST' || false);

/** @type {import('effector').Effect<void, Persona>} */
const getActivePersonaFx = createEffect(async () => {
  return ghApi.get('/persona').then(response => response.data);
});

/** @type {import('effector').Effect<void, Persona[]>} */
const getAvailablePersonasFx = createEffect(async () => {
  return ghApi.get('/personas').then(response => response.data);
});

/**
 * Set the active persona for the current user.
 *
 * @param {Persona} persona - The persona to set as active.
 * @type {import('effector').Effect<Persona, unknown>} - The effect to set the active persona.
 */
const setActivePersonaFx = createEffect(async persona => {
  return ghApi.put('/persona', persona).then(response => response.data);
});

sample({
  clock: getActivePersonaFx.doneData,
  target: $activePersona
});

sample({
  clock: getAvailablePersonasFx.doneData,
  target: $availablePersonas
});

export const $$personaService = {
  $activePersona,
  $availablePersonas,
  $isMapActivePersona,
  $isStActivePersona,

  getActivePersonaFx,
  getAvailablePersonasFx,
  setActivePersonaFx
};
