import { DataGridPro } from '@mui/x-data-grid-pro';
import { DataGridStylesWithoutSelectionOverride } from '../../util/DataGridStyles';
import { $$myRolesTable } from './model';

/**
 * MyRolesTable component
 *
 * @type {React.FC<{}>}
 * @returns {React.ReactNode} MyRolesTable component
 */
export default function MyRolesTable() {
  const dataGridProps = $$myRolesTable.$$rolesDataGrid.useDataGrid();

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Roles.Role, any, any>[]} */
  const customerTableColumns = [
    { field: 'name', headerName: 'Assignment', minWidth: 160, flex: 4 },
    { field: 'description', headerName: 'Description', minWidth: 160, flex: 6 }
  ];

  return (
    <DataGridPro
      {...dataGridProps}
      autoHeight
      columns={customerTableColumns}
      disableRowSelectionOnClick
      editMode="row"
      getRowId={row => row.id}
      initialState={{
        columns: { columnVisibilityModel: { id: false } },
        pagination: { paginationModel: { pageSize: 10 } }
      }}
      pagination={false}
      hideFooter={true}
      autosizeOnMount={false} // vitest is failing beause of this prop
      autosizeOptions={{
        includeOutliers: true,
        includeHeaders: true
      }}
      sx={DataGridStylesWithoutSelectionOverride}
    />
  );
}
