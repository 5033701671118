import { AxiosError } from 'axios';
import { attach, createEvent, createStore, sample } from 'effector';
import { isEmpty, pick, sortBy } from 'lodash';
import { debounce, spread, readonly } from 'patronum';
import qs from 'qs';
import { $$auth } from '../../services/AuthService/model';
import { $$contactService } from '../../services/ContactService/model';
import { $$splService } from '../../services/SplService/model';
import { ErrorMessageInfo, generateErrorAlertWithContact, generateTimeoutErrorAlert, isTimeoutError } from '../../util/ErrorUtils';
import { treeShakeObject, VOID } from '../../util/JsonUtils';
import { $$router } from '../../util/RouterUtils/RouterUtils';
import { FormErrors, SearchResultItem, SplFormData, SplTabSlug, splTabSlugs } from './types';
import { defaultDateRange, validateForm } from './utils';
import { $$reseller } from '../../services/ResellerService/model';
import dayjs from 'dayjs';

//-------------------------------- Events --------------------------------
const pageMounted = createEvent<boolean>();

const pageUnmounted = pageMounted.filter({ fn: value => value === false });

const reinitAll = createEvent<void>();

const setFormData = createEvent<Partial<SplFormData>>();

const setErrors = createEvent<FormErrors>();

const setSelectedTab = createEvent<SplTabSlug>();

const setSearchResults = createEvent<{ isLoaded: boolean; rows: SearchResultItem[] }>();

const setAlertData = createEvent<ErrorMessageInfo | null>();

const clearButtonClicked = createEvent<void>();

const submitButtonClicked = createEvent<void>();

const pageInitialized = createEvent<void>();

const getCustomersFx = attach({ effect: $$reseller.getCustomerListFx });

const getLineOfBusinessFx = attach({ effect: $$reseller.getLineOfBusinessListFx });

const getCampaignsFx = attach({ effect: $$reseller.getCampaignListFx });

//-------------------------------- Stores --------------------------------
const $pageInitialized = createStore<boolean>(false).reset(pageUnmounted);

const $formData = createStore<Partial<SplFormData>>({});

const $customers = createStore<Reseller.Customer[]>([]);

const $selectedCustomer = sample({
  source: {
    customerId: $formData.map(formData => formData.customerId),
    customers: $customers
  },
  fn: ({ customerId, customers }) => (customerId ? customers.find(customer => customer.id === customerId) || null : null)
});

const $linesOfBusiness = createStore<Reseller.LineOfBusiness[]>([]).reset(reinitAll);

const $selectedLob = sample({
  source: {
    lineOfBusinessId: $formData.map(formData => formData.lineOfBusinessId),
    linesOfBusiness: $linesOfBusiness
  },
  fn: ({ lineOfBusinessId, linesOfBusiness }) => (lineOfBusinessId ? linesOfBusiness.find(lob => lob.id === lineOfBusinessId) || null : null)
});

const $campaigns = createStore<Reseller.Campaign[]>([]).reset(reinitAll);

const $selectedCampaign = sample({
  source: {
    campaignId: $formData.map(formData => formData.campaignId),
    campaigns: $campaigns
  },
  fn: ({ campaignId, campaigns }) => (campaignId ? campaigns.find(campaign => campaign.id === campaignId) || null : null)
});

const $errors = createStore<FormErrors>({}).reset(reinitAll, pageUnmounted);

const $selectedTab = $$router.$search.map(search => (search['lookup-type'] || splTabSlugs.byPieceNumber) as SplTabSlug);

const $searchResults = createStore<{ isLoaded: boolean; rows: SearchResultItem[] }>({ isLoaded: false, rows: [] }).reset(reinitAll, pageUnmounted, clearButtonClicked, submitButtonClicked);

const $alertData = createStore<ErrorMessageInfo | null>(null).reset(reinitAll, pageUnmounted);

const $searchInProgress = $$splService.getSplResultsFx.pending;

//-------------------------------- Events mapped from stores --------------------------------
const startDateSet = debounce({ source: $formData.map(f => f?.startDate).updates.filter({ fn: date => date !== undefined }), timeout: 500 });
const endDateSet = debounce({ source: $formData.map(f => f?.endDate).updates.filter({ fn: date => date !== undefined }), timeout: 500 });

const customerIdSet = $formData.map(f => f?.customerId).updates.filter({ fn: customerId => customerId !== undefined });
const customerIdReset = $formData.map(f => f.customerId === null).updates.filter({ fn: Boolean });

const lobIdSet = $formData.map(f => f?.lineOfBusinessId).updates.filter({ fn: lineOfBusinessId => lineOfBusinessId !== undefined });
const lobIdReset = $formData.map(f => f.lineOfBusinessId === null).updates.filter({ fn: Boolean });

sample({
  clock: pageInitialized,
  target: $pageInitialized
});

// Customer dropdown
sample({
  source: [$pageInitialized, $formData],
  clock: [$pageInitialized, startDateSet, endDateSet],
  filter: ([initialized, formData]) => Boolean(initialized && dayjs(formData.startDate).isValid() && dayjs(formData.endDate).isValid()),
  fn: ([_, formData]) => ({
    startDate: dayjs(formData.startDate).format('MM-DD-YYYY'),
    endDate: dayjs(formData.endDate).format('MM-DD-YYYY')
  }),
  target: getCustomersFx
});

sample({
  clock: getCustomersFx.doneData,
  fn: customers => sortBy(customers, [customer => customer.name.toLowerCase()]),
  target: $customers
});

// If there's only one customer, that customer is auto-populated
sample({
  clock: [
    $customers.updates,
    // Trigger when tab is switched
    setSelectedTab.map(() => VOID)
  ],
  source: $customers,
  filter: customers => customers.length === 1,
  fn: customers => ({ customerId: customers[0].id }),
  target: setFormData
});

// LOB dropdown
sample({
  source: $formData,
  clock: [customerIdSet, startDateSet, endDateSet],
  filter: formData => Boolean(formData.customerId && dayjs(formData.startDate).isValid() && dayjs(formData.endDate).isValid()),
  fn: formData => ({
    customerIds: formData.customerId,
    startDate: dayjs(formData.startDate).format('MM-DD-YYYY'),
    endDate: dayjs(formData.endDate).format('MM-DD-YYYY')
  }),
  target: getLineOfBusinessFx
});

sample({
  clock: getLineOfBusinessFx.doneData,
  fn: lobs => sortBy(lobs, [lob => lob.name.toLowerCase()]),
  target: $linesOfBusiness
});

// If there's only one LOB, that LOB is auto-populated
sample({
  clock: [
    $linesOfBusiness.updates,
    // Trigger when tab is switched
    setSelectedTab.map(() => VOID)
  ],
  source: $linesOfBusiness,
  filter: linesOfBusiness => linesOfBusiness.length === 1,
  fn: linesOfBusiness => ({ lineOfBusinessId: linesOfBusiness[0].id }),
  target: setFormData
});

sample({
  clock: [customerIdReset],
  target: $linesOfBusiness.reinit
});

sample({
  clock: $linesOfBusiness.reinit,
  fn: () => ({ lineOfBusinessId: null }),
  target: setFormData
});

// Campaigns dropdown
sample({
  source: $formData,
  clock: [lobIdSet, startDateSet, endDateSet],
  filter: formData => Boolean(formData.customerId && formData.lineOfBusinessId && dayjs(formData.startDate).isValid() && dayjs(formData.endDate).isValid()),
  fn: formData => ({
    customerIds: formData.customerId,
    lineOfBusinessIds: formData.lineOfBusinessId,
    mailDateStart: dayjs(formData.startDate).format('MM-DD-YYYY'),
    mailDateEnd: dayjs(formData.endDate).format('MM-DD-YYYY')
  }),
  target: getCampaignsFx
});

sample({
  clock: getCampaignsFx.doneData,
  fn: campaigns => sortBy(campaigns, [campaign => campaign.name.toLowerCase(), campaign => campaign.number.toLowerCase()]),
  target: $campaigns
});

sample({
  clock: [lobIdReset],
  target: $campaigns.reinit
});

sample({
  clock: $campaigns.reinit,
  fn: () => ({ campaignId: null }),
  target: setFormData
});

// Errors
sample({
  clock: setErrors,
  target: $errors
});

sample({
  clock: setSelectedTab,
  fn: value => ({ key: 'lookup-type', value }),
  target: $$router.setSearchByKey
});

sample({
  clock: setAlertData,
  target: $alertData
});

sample({
  clock: setSearchResults,
  target: $searchResults
});

sample({
  source: $formData,
  clock: [pageUnmounted, clearButtonClicked, setSelectedTab.map(() => VOID)],
  fn: formData => {
    const newFormData = 'pieceNumberType' in formData ? pick(formData, 'pieceNumberType') : {};

    // Need to manually reset $formData with default values here and not with 'reset(reinitAll)' to avoid triggering
    // dropdown filter searches (customers, LOBs, campaigns) with the default dates when the page is being loaded from
    // the URL params (Same reason why $pageInitialized was introduced)
    return {
      ...newFormData,
      customerId: null, // Force null value so derived store $selectedCustomer is refreshed
      startDate: defaultDateRange.startDate.format('MM/DD/YYYY'),
      endDate: defaultDateRange.endDate.format('MM/DD/YYYY')
    };
  },
  target: [$formData, $errors.reinit, reinitAll]
});

sample({
  clock: $$splService.getSplResultsFx.done,
  fn: ({ params, result: rows }) => ({
    searchResults: { isLoaded: true, rows },
    setSearchByKey: { key: 'form-data', value: params }
  }),
  target: spread({
    searchResults: $searchResults,
    setSearchByKey: $$router.setSearchByKey
  })
});

sample({
  source: {
    user: $$auth.$user,
    contactInfo: $$contactService.$contactInfo
  },
  clock: $$splService.getSplResultsFx.failData,
  fn: ({ user, contactInfo }, error) => {
    if (isTimeoutError(error as AxiosError)) {
      return generateTimeoutErrorAlert({ email: user?.email || '', url: window.location.href });
    }
    return generateErrorAlertWithContact({
      contactInfo: contactInfo,
      userEmail: user?.email || '',
      requestId: (error as AxiosError)?.response?.headers?.['Request-Id'],
      errorMessage: 'There was an error loading your search results. Please retry your search'
    });
  },
  target: setAlertData
});

// When submit button is clicked, check if form is valid and call API accordingly (by piece number or by name)
sample({
  clock: submitButtonClicked,
  source: { selectedTab: $selectedTab, formData: $formData, isSplPending: $searchInProgress },
  filter: ({ isSplPending }) => !isSplPending,
  fn: ({ selectedTab, formData }) => {
    const errors = validateForm({ formData, selectedTab });
    if (!isEmpty(errors)) {
      return { setErrors: errors };
    }
    return { triggerSplSearch: treeShakeObject(formData) as SplFormData, setErrors: {} };
  },
  target: spread({
    setErrors: setErrors,
    triggerSplSearch: $$splService.getSplResultsFx
  })
});

sample({
  clock: setFormData,
  source: $formData,
  fn: (oldValue, newValue) => ({ ...oldValue, ...newValue }),
  target: $formData
});

sample({
  source: {
    formData: $formData,
    routerParams: $$router.$search.map(search => {
      const parsedData = (qs.parse(search['form-data']) as Partial<SplFormData>) || null;
      // Convert specific fields to numbers if they are not null (If not, dropdowns are not preloaded with items from URL)
      if (parsedData) {
        parsedData.customerId &&= Number(parsedData.customerId);
        parsedData.lineOfBusinessId &&= Number(parsedData.lineOfBusinessId);
        parsedData.campaignId &&= Number(parsedData.campaignId);
      }
      return parsedData;
    })
  },
  clock: pageMounted.filter({ fn: Boolean }),
  fn: ({ formData, routerParams }) => {
    const newFormData = isEmpty(routerParams) ? formData : routerParams;
    return {
      formData: {
        ...newFormData,
        startDate: newFormData.startDate || defaultDateRange.startDate.format('MM/DD/YYYY'),
        endDate: newFormData.endDate || defaultDateRange.endDate.format('MM/DD/YYYY')
      },
      pageInitialized: true
    };
  },
  target: spread({
    formData: $formData,
    pageInitialized: pageInitialized
  })
});

sample({
  source: { selectedTab: $selectedTab, formData: $formData },
  clock: pageInitialized,
  filter: ({ selectedTab, formData }) => isEmpty(validateForm({ formData, selectedTab })),
  target: submitButtonClicked
});

export const $$splPage = {
  $formData,
  $selectedTab,
  $searchInProgress,
  $searchResults,
  $alertData,
  $errors,
  $customers: readonly($customers),
  $selectedCustomer,
  $linesOfBusiness: readonly($linesOfBusiness),
  $selectedLob,
  $campaigns: readonly($campaigns),
  $selectedCampaign,

  setFormData,
  setSelectedTab,
  setSearchResults,
  setAlertData,

  clearButtonClicked,
  submitButtonClicked,
  pageMounted,
  pageInitialized,

  __: {
    reinitAll
  }
};
