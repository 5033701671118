import { IconButton as MuiIconButton } from '@mui/material';
import { omit } from 'ramda';

import { colors as themeColors } from '../../theme';

export const iconButtonSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small'
};

export const iconButtonColors = {
  INHERIT: 'inherit',
  ...omit(['TEXT'], themeColors)
};

export default MuiIconButton;
