// @ts-ignore
import { Autocomplete, textFieldVariants } from '@ghs/components';
import { useUnit } from 'effector-react';
import { SplFormByName } from './types';
import { Stack } from '@mui/material';
import { $$splPage } from './model';
import { $$personaService } from '../../services/PersonaService/model';

type LookupSearchFiltersProps = {
  formData: Partial<SplFormByName>;
  onChange: (value: Partial<SplFormByName>) => void;
};

/**
 * LookupSearchFilters
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function LookupSearchFilters(props: LookupSearchFiltersProps) {
  const { onChange } = props;

  const isMapPersona = useUnit($$personaService.$isMapActivePersona);
  const customers = useUnit($$splPage.$customers).map(customer => ({ id: customer.id, label: customer.name }));
  const selectedCustomer = useUnit($$splPage.$selectedCustomer.map(customer => (customer ? { id: customer.id, label: customer.name } : null)));
  const linesOfBusiness = useUnit($$splPage.$linesOfBusiness).map(lob => ({ id: lob.id, label: lob.name }));
  const selectedLob = useUnit($$splPage.$selectedLob.map(lob => (lob ? { id: lob.id, label: lob.name } : null)));
  const campaigns = useUnit($$splPage.$campaigns)?.map(campaign => ({ id: campaign.id, label: campaign.name + ' - ' + campaign.number }));
  const $selectedCampaign = useUnit($$splPage.$selectedCampaign.map(campaign => (campaign ? { id: campaign.id, label: campaign.name + ' - ' + campaign.number } : null)));

  return (
    <Stack direction="row">
      <Autocomplete
        data-testid="spl-customer-filter-autocomplete"
        id="spl-customer-filter"
        label={isMapPersona ? 'Search Client' : 'Search Customer'}
        placeholder="Select"
        options={customers}
        variant={textFieldVariants.OUTLINED}
        sx={{ mr: 2, display: 'flex', flex: 1, maxWidth: 275 }}
        value={selectedCustomer}
        onChange={(_event, value) => onChange({ customerId: value?.id || null })}
        getOptionKey={option => option.id}
      />
      <Autocomplete
        data-testid="spl-lob-filter-autocomplete"
        id="spl-lob-filter"
        label="Search Line of Business"
        placeholder="Select"
        options={linesOfBusiness}
        variant={textFieldVariants.OUTLINED}
        sx={{ mr: 2, display: 'flex', flex: 1, maxWidth: 275 }}
        value={selectedLob}
        onChange={(_event, value) => onChange({ lineOfBusinessId: value?.id || null })}
      />
      <Autocomplete
        data-testid="spl-campaign-filter-autocomplete"
        id="spl-campaign-filter"
        label="Search Campaign"
        placeholder="Select"
        options={campaigns}
        variant={textFieldVariants.OUTLINED}
        sx={{ display: 'flex', flex: 1, maxWidth: 275 }}
        value={$selectedCampaign}
        onChange={(_event, value) => onChange({ campaignId: value?.id || null })}
      />
    </Stack>
  );
}
