import { createEvent, createStore, sample } from 'effector';
import { persist } from 'effector-storage/local';
import { palettes } from '@ghs/components';
import '@mui/material/styles';

/** @typedef {import('@mui/material/styles').Theme} Theme */

const $selectedTheme = createStore<'light' | 'dark' | 'system'>('light');

const $systemTheme = createStore<'light' | 'dark'>(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

const $selectedPaletteKey = createStore<keyof typeof palettes>('default');

const $theme = sample({
  source: {
    selectedTheme: $selectedTheme,
    systemTheme: $systemTheme
  },
  fn: ({ selectedTheme, systemTheme }) => (selectedTheme === 'system' ? systemTheme : selectedTheme)
});

const $palette = sample({
  source: {
    theme: $theme,
    selectedPaletteName: $selectedPaletteKey
  },
  fn: ({ theme, selectedPaletteName }) => palettes[selectedPaletteName][theme]
});

const $themePalettes = sample({
  source: $selectedPaletteKey,
  fn: selectedPaletteName => palettes[selectedPaletteName]
});

const changeTheme = createEvent<'light' | 'dark' | 'system'>();

const systemThemeChanged = createEvent<'light' | 'dark'>();

const changePalette = createEvent<keyof typeof palettes>();

persist({
  store: $selectedTheme,
  key: 'ghs-theme'
});

sample({
  clock: changeTheme,
  target: $selectedTheme
});

sample({
  clock: changePalette,
  target: $selectedPaletteKey
});

sample({
  clock: systemThemeChanged,
  target: $systemTheme
});

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
  systemThemeChanged(e.matches ? 'dark' : 'light');
});

export const $$theme = {
  $theme,
  $mode: $selectedTheme,
  $palette,
  $paletteKey: $selectedPaletteKey,
  $themePalettes,

  changeTheme,
  changePalette
};
