import { Skeleton } from '@ghs/components';
import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { useUnit } from 'effector-react';
import Address from './Address';
import MailingInformationAttribute from './MailingInformationAttribute';
import { $$splResultsPage } from './model';

export default function MailingInformation() {
  const data = useUnit($$splResultsPage.$mailPiece);
  const isLoading = useUnit($$splResultsPage.$isMailDetailsFxPending);

  if (isLoading) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
      </Stack>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Card data-testid="mailing-information-container" sx={{ boxShadow: 'none', borderRadius: '1rem', border: '1px solid', borderColor: 'secondary.dark' }}>
      <CardContent sx={{ p: 3, color: 'text.secondary', display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, gap: 2 }}>
        <Stack sx={{ display: 'flex', flex: 1, alignItems: 'start' }}>
          <CardHeader title="Mailing Information" sx={{ px: 0, color: 'text.primary', fontSize: '2.4rem' }} />
          <Grid container>
            <MailingInformationAttribute label="Unique Record ID: " value={data.uniqueRecordId} isLoaded={Boolean(data)} />
            <MailingInformationAttribute label="IMb: " value={data.imb} isLoaded={Boolean(data)} />
            <MailingInformationAttribute label="Mail Date: " value={data.mailDate} isLoaded={Boolean(data)} />
            <MailingInformationAttribute label="Induction Date: " value={data.inductionDate} isLoaded={Boolean(data)} />
            <MailingInformationAttribute label="Delivery Date: " value={data.deliveryDate} isLoaded={Boolean(data)} hideIfEmpty={false} />
            <MailingInformationAttribute label="Line of Business: " value={data.lineOfBusiness} isLoaded={Boolean(data)} />
            <MailingInformationAttribute label="Campaign Name: " value={data.campaignName} isLoaded={Boolean(data)} />
            <MailingInformationAttribute label="Campaign Nbr: " value={data.campaignNumber} isLoaded={Boolean(data)} />
            <MailingInformationAttribute label="Version Name: " value={data.versionName} isLoaded={Boolean(data)} />
            <MailingInformationAttribute label="Version Nbr: " value={data.versionNumber} isLoaded={Boolean(data)} />
            <MailingInformationAttribute label="Mail Class: " value={data.mailClass} isLoaded={Boolean(data)} />
            <MailingInformationAttribute label="Branch: " value={data.branch} isLoaded={Boolean(data)} />
            <MailingInformationAttribute label="Mail Service Provider: " value={data.mailServiceProvider} isLoaded={Boolean(data)} /> {/*Only MT*/}
            <MailingInformationAttribute label="User field 1: " value={data.userField1} isLoaded={Boolean(data)} /> {/*Only ST*/}
            <MailingInformationAttribute label="User field 2: " value={data.userField2} isLoaded={Boolean(data)} /> {/*Only ST*/}
            <MailingInformationAttribute label="User field 3: " value={data.userField3} isLoaded={Boolean(data)} /> {/*Only ST*/}
          </Grid>
        </Stack>
        <Address />
      </CardContent>
    </Card>
  );
}
