import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import CustomRangeShortcuts from './CustomRangeShortcuts';
import { $$splPage } from './model';
import { FormErrors, SplFormByName } from './types';
import { shortcutsItems } from './utils';

type SplDateRangePickerProps = {
  formData: Partial<SplFormByName>;
  errors: FormErrors;
};

export default function SplDateRangePicker(props: SplDateRangePickerProps) {
  const { formData, errors } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        value={[dayjs((formData as SplFormByName).startDate), dayjs((formData as SplFormByName).endDate)]}
        onChange={newValue => $$splPage.setFormData({ startDate: newValue[0]?.format('MM/DD/YYYY') || '', endDate: newValue[1]?.format('MM/DD/YYYY') || '' })}
        localeText={{ start: 'Start Date', end: 'End Date' }}
        slots={{ shortcuts: CustomRangeShortcuts }}
        slotProps={{
          shortcuts: { items: shortcutsItems },
          toolbar: { hidden: true },
          actionBar: { hidden: true },
          field: { dateSeparator: '' },
          textField: ({ position }) => {
            if (position === 'start') {
              return { error: Boolean(errors.startDate), sx: { maxWidth: 275 } };
            }
            if (position === 'end') {
              return { error: Boolean(errors.endDate), sx: { maxWidth: 275 } };
            }
            return {};
          }
        }}
        calendars={2}
        sx={{ '> *': { marginLeft: '0px !important' }, gap: 1, width: '100%', display: 'flex', flex: 2 }}
      />
    </LocalizationProvider>
  );
}
