import { Button, Tab, Tabs } from '@ghs/components';
import { Container, Stack, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { useMemo } from 'react';
import TabPanel from '../../components/TabPanel';
import { $$tutorialVideos } from '../../components/TutorialVideosModal/model';
import TutorialVideoButton from '../../components/TutorialVideosModal/TutorialVideoButton';
import useMountedEvent from '../../hooks/useMountedEvent';
import { $$personaService } from '../../services/PersonaService/model';
import LookupByName from './LookupByName';
import LookupByPieceNumber from './LookupByPieceNumber';
import { $$splPage } from './model';
import SearchResults from './SearchResults';
import SplAlerts from './SplAlerts';
import { SplTabSlug, splTabSlugs } from './types';

export default function SinglePieceLookupPage() {
  useMountedEvent($$splPage.pageMounted);

  const formData = useUnit($$splPage.$formData);
  const errors = useUnit($$splPage.$errors);
  const selectedTab = useUnit($$splPage.$selectedTab);
  const searchInProgress = useUnit($$splPage.$searchInProgress);
  const searchResults = useUnit($$splPage.$searchResults);
  const activePersonaGhsSystem = useUnit($$personaService.$activePersona.map(persona => persona?.ghsSystem || null));

  return (
    <Container id="lookup-form" data-testid="lookup-form" component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TutorialVideoButton url={$$tutorialVideos.links[activePersonaGhsSystem === 'MAP' ? 'SPL:MT_PERSONA' : 'SPL:ST_PERSONA']} />

      <Typography variant="h4" color="text.primary">
        Single Piece Lookup
      </Typography>
      <SplAlerts />
      <Tabs value={selectedTab} variant="standard" scrollButtons="auto" onChange={(_, newTabSelection: SplTabSlug) => $$splPage.setSelectedTab(newTabSelection)}>
        <Tab label="Lookup by Piece Number" value="by-piece-number" sx={{ textTransform: 'none' }} />
        <Tab label="Lookup by Name" value="by-name" sx={{ textTransform: 'none' }} />
      </Tabs>
      <TabPanel value={selectedTab} index={splTabSlugs.byPieceNumber}>
        <LookupByPieceNumber formData={formData} onChange={$$splPage.setFormData} errors={errors} />
      </TabPanel>
      <TabPanel value={selectedTab} index={splTabSlugs.byName}>
        <LookupByName formData={formData} onChange={$$splPage.setFormData} errors={errors} />
      </TabPanel>
      <Stack direction="row" spacing={2}>
        <Button data-testid="clear-button" color="primary" onClick={() => $$splPage.clearButtonClicked()} size="medium" variant="outlined" disabled={searchInProgress}>
          Clear
        </Button>
        <Button
          data-testid="submit-button"
          type="submit"
          color="primary"
          onClick={event => {
            event.preventDefault();
            $$splPage.submitButtonClicked();
          }}
          size="medium"
          variant="contained"
          disabled={searchInProgress}
        >
          Search
        </Button>
      </Stack>
      {useMemo(
        () => (
          <SearchResults data={searchResults} loading={searchInProgress} />
        ),
        [JSON.stringify(searchResults), searchInProgress]
      )}
    </Container>
  );
}
