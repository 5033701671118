import { PickersShortcutsItem } from '@mui/x-date-pickers';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { fromPairs } from 'lodash';
import { z } from 'zod';
import { SplFormByNameZod, SplFormByPieceNumberZod, SplTabSlug, splTabSlugs } from './types';

dayjs.extend(customParseFormat);

export const defaultDateRange = {
  startDate: dayjs().subtract(1, 'month'),
  endDate: dayjs()
} as const;

export const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    }
  },
  {
    label: 'Last 3 Months',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(3, 'month'), today];
    }
  },
  {
    label: 'This Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    }
  },
  { label: 'Reset', getValue: () => [defaultDateRange.startDate, defaultDateRange.endDate] }
];

export const validateForm = (params: { formData: unknown; selectedTab: SplTabSlug }) => {
  const { formData, selectedTab } = params;

  try {
    if (selectedTab === splTabSlugs.byPieceNumber) {
      SplFormByPieceNumberZod.parse(formData);
    } else {
      SplFormByNameZod.parse(formData);
    }
    return {};
  } catch (error) {
    if (error instanceof z.ZodError) {
      return fromPairs(error.errors.map(e => [e.path[0], e.message]));
    } else {
      return {};
    }
  }
};
