import { DeleteForever } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DataGridStylesWithoutSelectionOverride } from '../../util/DataGridStyles';
import { $$manageUsersAssignedToRoleModal } from './model';

/**
 * UserRolesTable component
 *
 * @returns {React.ReactNode} UserRolesTable
 */
export default function UserRolesTable() {
  const dataGridProps = $$manageUsersAssignedToRoleModal.$$rolesDataGrid.useDataGrid();

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Users.User, any, any>[]} */
  const customerTableColumns = [
    { field: 'name', headerName: 'Users', minWidth: 160, flex: 4 },
    { field: 'email', headerName: 'Email', minWidth: 160, flex: 6 },
    {
      field: 'actions',
      type: 'actions',
      editable: false,
      headerName: 'Actions',
      headerAlign: 'center',
      getActions: ({ id }) => [
        <IconButton key="delete" onClick={() => $$manageUsersAssignedToRoleModal.openDeleteConfirmation(id + '')}>
          <DeleteForever fontSize="small" color="warning" />
        </IconButton>
      ]
    }
  ];

  return (
    <DataGridPro
      {...dataGridProps}
      autoHeight
      columns={customerTableColumns}
      disableRowSelectionOnClick
      editMode="row"
      initialState={{
        columns: { columnVisibilityModel: { id: false } },
        pagination: { paginationModel: { pageSize: 10 } }
      }}
      pagination={true}
      pageSizeOptions={[5, 10, 25, 50]}
      autosizeOnMount={false} // vitest is failing beause of this prop
      autosizeOptions={{
        includeOutliers: true,
        includeHeaders: true
      }}
      sx={DataGridStylesWithoutSelectionOverride}
    />
  );
}
