import { omit } from 'lodash';
import { forwardRef } from 'react';

import { ButtonProps, Button as MuiButton, Typography, useTheme } from '@mui/material';

import { colors as themeColors } from '~/theme';

export const buttonVariants = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text'
} as const;

export const buttonColors = {
  ...omit(['TEXT'], themeColors),
  INHERIT: 'inherit' as const
};

type ButtonColors = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'error' | 'grey' | 'inherit';

export const buttonSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small'
} as const;

const typeSizeMap = {
  large: 'buttonLarge',
  medium: 'button',
  small: 'buttonSmall'
} as const;

const Button = forwardRef<HTMLButtonElement, Omit<ButtonProps, 'color'> & { color?: ButtonColors }>((props, ref) => {
  const { children, variant, color, size, startIcon, endIcon, disabled, onClick, ...overrides } = props;

  const theme = useTheme();
  const colorTextPrimary = theme.palette.text.primary;

  const needsContrast = variant !== buttonVariants.CONTAINED && color === buttonColors.GREY;

  return (
    <MuiButton
      ref={ref}
      variant={variant}
      color={color as ButtonProps['color']}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      onClick={onClick}
      sx={
        needsContrast
          ? {
              borderColor: colorTextPrimary
            }
          : null
      }
      {...overrides}
    >
      {typeof children === 'string' ? (
        <Typography variant={size && typeSizeMap[size] ? typeSizeMap[size] : 'button'} color={needsContrast ? colorTextPrimary : 'inherit'}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </MuiButton>
  );
});

export default Button;
