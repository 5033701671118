import { useEffect, useState } from 'react';
import { getAdminDashboards, updateAdminDashboardMetadata } from '../../../services/BiService';

/**
 * @typedef {import('../../services/BiService.js').DashboardView} DashboardView
 */

/**
 * Hook to fetch the admin dashboards
 *
 * @returns {{
 *   dashboards: Pick<DashboardView, 'id' | 'title' | 'description'>[],
 *   isDashboardsLoading: boolean,
 *   isUpdateMetadataLoading: boolean,
 *   isEditDialogOpened: boolean,
 *   dashboardToEdit: Pick<DashboardView, 'id' | 'title' | 'description'> | null
 *   openEditDialog: (id: string) => void,
 *   closeEditDialog: () => void,
 *   handleSave: Function,
 * }} - dashboards array
 */
export function useGhsAdminDashboards() {
  const [dashboards, setDashboards] = useState([]);
  const [isDashboardsLoading, setIsDashboardsLoading] = useState(false);
  const [selectedToEditDahboardId, setSelectedToEditDahboardId] = useState(null);
  const [isUpdateMetadataLoading, setIsUpdateMetadataLoading] = useState(false);

  useEffect(() => {
    setIsDashboardsLoading(true);
    getAdminDashboards()
      .then(setDashboards)
      .finally(() => setIsDashboardsLoading(false));
  }, []);

  return {
    dashboards,
    isDashboardsLoading,
    isUpdateMetadataLoading,
    isEditDialogOpened: selectedToEditDahboardId !== null,
    dashboardToEdit: dashboards.find(dashboard => dashboard.id === selectedToEditDahboardId) || null,
    openEditDialog: id => {
      setSelectedToEditDahboardId(id);
    },
    closeEditDialog: () => {
      setSelectedToEditDahboardId(null);
    },
    /**
     * @param {{
     *   id: string;
     *   description: string;
     *   thumbnailLightBase64: File;
     *   thumbnailDarkBase64: File;
     * }} data - The data to save
     */
    handleSave: data => {
      setIsUpdateMetadataLoading(true);
      updateAdminDashboardMetadata(data)
        .then(getAdminDashboards)
        .then(setDashboards)
        .finally(() => setIsUpdateMetadataLoading(false))
        .finally(() => setSelectedToEditDahboardId(null));
    }
  };
}
