import {useEffect, useState} from "react";
import { IconButton, Card, CardContent, Typography, Divider, CardActions, Button, Snackbar, Tooltip } from '@mui/material'
import PropTypes from "prop-types";
import UndoIcon from '@mui/icons-material/Undo';
import CloseIcon from "@mui/icons-material/Close";
import {copyTextToClipboard} from "../../../../util/ClipboardUtils";
import {FormDataPropTypes} from "../../utils/PropTypes";
import {getStateSearchParamWithValidationOrDefault} from "../../../../util/FormUtil";

/**
 * The SubmittedAddress component is used for displaying input data from searchParams in an address form. It also
 * allows users to copy the address form to their clipboard and repopulate the input data to the AddressInput form to
 * edit the fields.
 *
 * @param {object} props - The properties passed to the component.
 * @param {object} props.searchParams - The searchParams object that contains the URL params from the current request.
 * @param {object} props.formData - The current formData object that contains the values used to make VerifyAQ request.
 * @param {Function} props.setFormData - function allows the setting of a new formData object.
 * @returns {React.ReactNode} React JSX element representing the SubmittedAddress component.
 */
const SubmittedAddress = ({searchParams, formData, setFormData}) => {
    const [submittedTextToCopy, setSubmittedTextToCopy] = useState("")
    const [openCopySnackBar, setOpenCopySnackBar] = useState(false);

    const getStandardizedAddress = () => {
        let address = "";
        if (searchParams.get('company')) {
            address += (searchParams.get('company') + "\n")
        }
        if (searchParams.get('urbanization')) {
            address += (searchParams.get('urbanization') + "\n")
        }
        if (searchParams.get('street')){
            address += (searchParams.get('street') + "\n")
        }
        if (searchParams.get('street2')) {
            address += (searchParams.get('street2') + "\n")
        }
        if (searchParams.get('city')){
            address += searchParams.get('city')
        }
        address += ", "
        let selectedState = getStateSearchParamWithValidationOrDefault(searchParams,"state", "");
        if (selectedState){
            address += selectedState;
            address += " "
        }
        if (searchParams.get('zip')) {
            address += searchParams.get('zip')
        }
        setSubmittedTextToCopy(address)
    }

    const handleRepopulate = () => {
        const updatedFormData = {};
        for (const key of Object.keys(formData)) {
            if (searchParams.has(key)) {
                const searchParamValue = searchParams.get(key);
                if (searchParamValue === 'true') {
                    updatedFormData[key] = true;
                } else if (searchParamValue === 'false') {
                    updatedFormData[key] = false;
                } else {
                    updatedFormData[key] = searchParamValue;
                }
            }
        }

        const finalFormData = {...formData, ...updatedFormData,}

        setFormData(finalFormData);
    }

    const handleSubmittedCopy = async () => {
        await copyTextToClipboard(submittedTextToCopy)
        setOpenCopySnackBar(true);
    };

    const handleCloseCopySnackbar = () => {
        setOpenCopySnackBar(false);
    };

    useEffect(() => {
        getStandardizedAddress();
    }, [searchParams]);

    return (

        <Card sx={{flexGrow: 1}}>
            <CardContent>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Submitted Address
                </Typography>
                <Divider sx={{width: '100%', marginTop: 1, marginBottom: 1}}></Divider>
                <div>
                    <Typography data-testid="submittedText-typography" variant={"h6"} style={{whiteSpace: 'pre-line'}} noWrap>
                        {submittedTextToCopy}
                    </Typography>
                </div>
            </CardContent>
            <CardActions>
                <Button data-testid="copy-button-submitted-address" size="small" onClick={handleSubmittedCopy}>Copy</Button>
                <Snackbar
                    open={openCopySnackBar}
                    color="inherit"
                    autoHideDuration={3000}
                    onClose={handleCloseCopySnackbar}
                    message="Copied to clipboard!"
                    action={
                        <>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleCloseCopySnackbar}
                            >
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        </>
                    }
                />
                <Tooltip title="Repopulate this address if edits are needed" arrow>
                    <IconButton data-testid="repopulate-button" size="small" onClick={handleRepopulate}>
                        <UndoIcon/>
                    </IconButton>
                </Tooltip>
            </CardActions>
        </Card>
    )
}
SubmittedAddress.propTypes = {
    searchParams: PropTypes.object,
    formData: FormDataPropTypes,
    setFormData: PropTypes.func
}

export default SubmittedAddress;