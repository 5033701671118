import { Typography, List, ListItem } from '@mui/material'
import PropTypes from "prop-types";
import {useUnit} from "effector-react";
import {$$resellerLogosPage} from "./model";

/**
 * Component for the content of the Admin Logos Help dialog.
 *
 * @param {object} props the props for this component
 * @param {string} props.type whether the content is for customers or resellers
 * @returns {JSX.Element} the content for the Logo help dialog
 */
function AdminLogosHelpDialogContent({ type }) {
  const logoCriteria = useUnit($$resellerLogosPage.$logoCriteria);

  return (
    <>
      <Typography variant="h6" color="text.primary">{ type === 'customer' ? 'Customer' : 'Reseller' } Instructions:</Typography>
      <Typography color="text.primary">Up to 5 custom logos may be uploaded.</Typography>
      <List dense sx={{ pl: 3, listStyleType: 'disc' }}>
        <ListItem sx={{ display: 'list-item' }} disableGutters><Typography variant="body2" color="text.primary">Choose “Upload Logo”</Typography></ListItem>
        <ListItem sx={{ display: 'list-item' }} disableGutters><Typography variant="body2" color="text.primary">Give the logo a name</Typography></ListItem>
        <ListItem sx={{ display: 'list-item' }} disableGutters><Typography variant="body2" color="text.primary">Choose to upload a logo for the light and dark theme</Typography></ListItem>
        <ListItem sx={{ display: 'list-item' }} disableGutters><Typography variant="body2" color="text.primary">Logos must be one of the following formats: .png, .jpg</Typography></ListItem>
        <ListItem sx={{ display: 'list-item' }} disableGutters><Typography variant="body2" color="text.primary">Each logo must be at most {logoCriteria?.maxSizeInBytes / 1024} KB</Typography></ListItem>
        <ListItem sx={{ display: 'list-item' }} disableGutters><Typography variant="body2" color="text.primary">When complete, click “Upload”</Typography></ListItem>
        <ListItem sx={{ display: 'list-item' }} disableGutters><Typography variant="body2" color="text.primary">Your logos will show on the main page</Typography></ListItem>
        { type === 'customer' ? null : <ListItem sx={{ display: 'list-item' }} disableGutters><Typography variant="body2" color="text.primary">The GHS current logo will always be available to choose and cannot be changed or deleted</Typography></ListItem>}
        <ListItem sx={{ display: 'list-item' }} disableGutters><Typography variant="body2" color="text.primary">Click the radio button for the logo you wish to use within the Portal</Typography></ListItem>
        <ListItem sx={{ display: 'list-item' }} disableGutters><Typography variant="body2" color="text.primary">Custom logos can be deleted. If a change is needed, delete the logo and create the replacement one</Typography></ListItem>
      </List>
    </>
  );
}

AdminLogosHelpDialogContent.propTypes = {
  type: PropTypes.string.isRequired
}

export default AdminLogosHelpDialogContent;