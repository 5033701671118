import { Button } from '@ghs/components';
import { Box, Container } from '@mui/material';
import * as React from 'react';
import DeleteLogoDialog from '../../components/logos/DeleteLogoDialog';
import LogosTable from '../../components/logos/LogosTable';
import UploadLogoDialog from '../../components/logos/UploadLogoDialog';
import { $$logo, deleteLogoFx, listLogosFx, setCurrentLogoFx, uploadLogoSetFx } from '../../services/LogoService';

/**
 * Component for displaying the form to manage logos.
 *
 * @returns {React.ReactNode} GhsAdminLogos component
 * @class
 */
function GhsAdminLogos() {
  const [open, setOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [isChangingLogo, setIsChangingLogo] = React.useState(false);
  const [isUploadingLogo, setIsUploadingLogo] = React.useState(false);
  const [isNewTheme, setIsNewTheme] = React.useState(false);
  const [selectedLogo, setSelectedLogo] = React.useState([]);
  const [logoSets, setLogoSets] = React.useState([]);

  const handleChangeTheme = async () => {
    setIsChangingLogo(true);
    const newThemeName = logoSets.find(({ id }) => id === selectedLogo[0])?.name;
    // Pending: Handle errors
    await setCurrentLogoFx({ newThemeName });
    setIsChangingLogo(false);
    $$logo.logoUpdated();
  };

  const handleDeleteTheme = async () => {
    setIsChangingLogo(true);
    const themeName = logoSets.find(({ id }) => id === selectedLogo[0])?.name;
    // Pending: Handle errors
    await deleteLogoFx({ themeName });
    setIsChangingLogo(false);
    setDeleteDialogOpen(false);
    loadData();
  };

  const isCurrentSelected = selectedId => {
    return logoSets.find(({ id }) => id === selectedId)?.name === 'current';
  };

  const handleSelectionChange = newSelectionModel => {
    setSelectedLogo(newSelectionModel);
    setIsNewTheme(!isCurrentSelected(newSelectionModel[0]));
  };

  const closeUploadDialog = (_e, reloadData) => {
    setOpen(false);
    if (reloadData) {
      loadData();
    }
  };

  const handleUpload = async ({ name, lightFile, darkFile }) => {
    setIsUploadingLogo(true);
    try {
      await uploadLogoSetFx({ name, lightFile, darkFile });
      closeUploadDialog(null, true);
    } finally {
      setIsUploadingLogo(false);
    }
  };

  const loadData = async () => {
    setLoadingData(true);
    // Pending: Handle errors
    const logos = await listLogosFx();
    let sortedLogos = [
      ...logos
        .filter(({ name }) => name === 'current')
        .map(logo => {
          // Bust cache by adding timestamp to current logos
          const now = new Date().getTime();
          return { ...logo, light: logo.light + `?ts=${now}`, dark: logo.dark + `?ts=${now}` };
        }),
      ...logos.filter(({ name }) => name !== 'current')
    ];

    setLogoSets(sortedLogos);
    setSelectedLogo([sortedLogos.find(l => l.name === 'current')?.id]);
    setLoadingData(false);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <LogosTable logoSets={logoSets} loading={loadingData} selectionModel={selectedLogo} handleSelectionChange={handleSelectionChange} />
      <Box sx={{ display: 'flex' }}>
        <Button color="primary" onClick={handleChangeTheme} size="medium" variant="contained" disabled={isChangingLogo || !isNewTheme} sx={{ mt: 1 }}>
          Change Theme
        </Button>
        <Button color="error" onClick={() => setDeleteDialogOpen(true)} size="medium" variant="contained" disabled={isChangingLogo || !isNewTheme} sx={{ mt: 1, ml: 1 }}>
          Delete Theme
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="secondary" onClick={() => setOpen(true)} size="medium" variant="contained" disabled={null} sx={{ mt: 1 }}>
          Upload Logo
        </Button>
      </Box>
      {open && <UploadLogoDialog onClose={closeUploadDialog} handleUpload={handleUpload} uploadInProgress={isUploadingLogo} open={open} />}
      {deleteDialogOpen && (
        <DeleteLogoDialog open={deleteDialogOpen} logoName={logoSets.find(({ id }) => id === selectedLogo[0])?.name} onClose={() => setDeleteDialogOpen(false)} onDelete={handleDeleteTheme} />
      )}
    </Container>
  );
}
GhsAdminLogos.propTypes = {};

export default GhsAdminLogos;
