import {fetchDataAxios, postDataAxios} from "./DataService";

export const fetchFileProcessingGetFiles = async (fileProcessingGetFilesRequest, accessToken) => {
    // prettier-ignore
    return fetchDataAxios(`${import.meta.env.VITE_API_SERVER_URL}/fileProcessing/getFiles`, accessToken, { // NOSONAR
        params: { ...Object.fromEntries(Object.entries(fileProcessingGetFilesRequest).filter(([, value]) => value !== '')) }
    });
}

export const postFileProcessingKillFile = async (fileProcessingKillRequest, accessToken) => {
    // prettier-ignore
    return postDataAxios(`${import.meta.env.VITE_API_SERVER_URL}/fileProcessing/killFile`, accessToken, null, { // NOSONAR
        params: { ...Object.fromEntries(Object.entries(fileProcessingKillRequest).filter(([, value]) => value !== '')) }
    });
}
