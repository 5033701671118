import { Link } from '@ghs/components';
import { createEffect } from 'effector';
import { toCanvas } from 'html-to-image';
import jsPDF from 'jspdf';
import { Link as RouterLink } from 'react-router-dom';
import { AcsEvent, MailPiece, Scan } from '../../../services/SplService';
import { formatAddressCityStateZip, formatCityStateZip, formatDestination } from '../../../util/AddressUtils';
import { ErrorMessageInfo } from '../../../util/ErrorUtils';
import generateMailInfo from './generateMailInfo';

type ExportPDFParams = {
  mailPiece: MailPiece | null;
  acsEvents: AcsEvent[];
  scans: Scan[];
};

export const exportPDFFx = createEffect<ExportPDFParams, void>(async params => {
  const { mailPiece, acsEvents, scans } = params;

  const pdf = new jsPDF('p', 'mm', 'a4');
  const leftMargin = 10;
  const topMargin = 5;
  let y = topMargin;
  const newLine = 4;
  const font = 'Helvetica';
  pdf.setFontSize(10);

  const tableConfig = {
    autoSize: false,
    printHeaders: true,
    fontSize: 8,
    padding: 1
  };

  const logo = new Image();
  logo.src = `/GrayHair-logo-black.t.png`;
  pdf.addImage(logo, 'PNG', leftMargin, topMargin, 72, 12, '', 'FAST');
  y += newLine * 3;
  pdf.text('_'.repeat(90), leftMargin, y);

  const column1Width = 40;
  const column2Width = 65;
  const column1Left = leftMargin;
  const column2Left = column1Left + column1Width;
  const column3Left = column2Left + column2Width;

  // Add Address section
  y += newLine * 2;
  const mailDetailsYStart = y;
  pdf.setFont(font, 'bold');
  pdf.text('Address', column3Left, y);
  pdf.text('________', column3Left, y);
  pdf.setFont(font, 'normal');
  const addressMaxLength = 75;
  const addressFields = [
    ...(mailPiece?.name ? pdf.splitTextToSize(`${mailPiece.name}`, addressMaxLength) : []),
    ...(mailPiece?.businessName ? pdf.splitTextToSize(`${mailPiece.businessName}`, addressMaxLength) : []),
    ...(mailPiece?.address1 ? pdf.splitTextToSize(`${mailPiece.address1}`, addressMaxLength) : []),
    ...(mailPiece?.address2 ? pdf.splitTextToSize(`${mailPiece.address2}`, addressMaxLength) : []),
    ...(mailPiece?.address3 ? pdf.splitTextToSize(`${mailPiece.address3}`, addressMaxLength) : []),
    ...pdf.splitTextToSize(formatCityStateZip(mailPiece?.city || '', mailPiece?.state || '', mailPiece?.zip5d, mailPiece?.zipPlus4), addressMaxLength)
  ];
  y += newLine;
  pdf.text(addressFields, column3Left, y);

  // Add Mailing Information section

  const { mailInfoLabels, mailInfoValues } = generateMailInfo({ pdf, mailPiece, columnWidth: column2Width });
  y = mailDetailsYStart;
  pdf.setFont(font, 'bold');
  pdf.text('Mailing Information', column1Left, y);
  pdf.text('_________________', column1Left, y);
  y += newLine;
  pdf.text(mailInfoLabels, column1Left, y);
  pdf.setFont(font, 'normal');
  pdf.text(mailInfoValues, column2Left, y);
  y += newLine * mailInfoLabels.length;

  // Add ACS Events table
  if (acsEvents?.length) {
    pdf.setFont(font, 'bold');
    y += newLine;
    pdf.text('ACS Events', leftMargin, y);
    pdf.text('__________', leftMargin, y);
    const acsTableHeaders = ['ACS Event Received', 'Reason', 'New Address'];
    const acsTableData = acsEvents.map(acsEvent => {
      const formattedAddress = formatAddressCityStateZip(acsEvent.newAddress, acsEvent.newCity, acsEvent.newState, `${acsEvent.new5Zip || ''}${acsEvent.newPlus4 || ''}`);
      // Ensure minimum length for the column by adding empty spaces (MER-123)
      const spacesToAdd = Math.max(0, 40 - formattedAddress.length);
      return {
        // Replace nulls with a single space so that they render properly in PDF table
        'ACS Event Received': acsEvent?.dateReceived || ' ',
        Reason: acsEvent?.reason || ' ',
        'New Address': formattedAddress + ' '.repeat(spacesToAdd)
      };
    });
    y += newLine;
    pdf.table(leftMargin, y, acsTableData, acsTableHeaders, tableConfig);
    y += newLine * 4 * acsEvents.length;
  }

  // Add Mailing Details section
  if (scans?.length) {
    pdf.setFontSize(10);
    pdf.setFont(font, 'bold');
    y += newLine;
    pdf.text(`Scans Details ${mailPiece?.inductionDate ? '(*Travel days are calculated off of Induction Date)' : ''}`, leftMargin, y);
    pdf.text('_____________', leftMargin, y);
    const scansTableHeaders = ['Mail\u00A0Piece\u00A0Destination', 'Scan\u00A0Date/Time', 'Scan\u00A0Site\u00A0ZIP', 'Scan\u00A0City/State', 'Activity', 'Travel\u00A0Days'];
    const scansTableData = scans.map(scan => ({
      // Replace nulls with a single space so that they render properly in PDF table
      'Mail\u00A0Piece\u00A0Destination': `${formatDestination(scan.destination)} `,
      'Scan\u00A0Date/Time': scan.scanDateTime || ' ',
      'Scan\u00A0Site\u00A0ZIP': scan.scanSiteZip || ' ',
      'Scan\u00A0City/State': scan.scanCityState?.trim() || ' ',
      Activity: scan.details?.trim() || ' ',
      'Travel\u00A0Days': scan.travelDays?.toString() || ' '
    }));

    pdf.table(leftMargin, y + newLine, scansTableData, scansTableHeaders, tableConfig);
  }

  // Add map image
  const map = document.getElementById('map');

  if (map) {
    try {
      // PDF via html-to-image (see options here: https://github.com/bubkoo/html-to-image#options)
      const canvas = await toCanvas(map, { skipFonts: true, includeQueryParams: true });
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL('img/png');
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', leftMargin, topMargin, imgWidth, imgHeight, '', 'FAST');
    } catch (err) {
      console.error('Error adding map to pdf.', err);
    }
  }

  pdf.save(`${mailPiece?.imb}.pdf`);
});

export function dataNotFoundErrorAlert(params: { imb: string; packageId: string }): ErrorMessageInfo {
  const { imb, packageId } = params;
  return {
    severity: 'error',
    title: 'Oops!',
    message: (
      <>
        We didn&apos;t find any results for the provided search parameters. Check your IMb and PkgId or return to the{' '}
        <Link to="/lookup" component={RouterLink}>
          Single Piece Lookup
        </Link>{' '}
        page to try another search.
        <br />
        <br />
        IMb: {imb}
        <br />
        PkgId: {packageId}
      </>
    )
  };
}
