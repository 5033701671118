import { useGate } from 'effector-react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { $$router } from './RouterUtils';

/**
 * @note
 * **Unit tests**
 *
 * Don't forget to use `useRouterGate` while implementing unit tests for components with model that imports `$$router`.
 * Since `useLocation` (used in `useRouterGate`) can only be used within the scope of the router, you need to wrap your component with `MemoryRouter` and add `RouterGateRoot` component that has `useRouterGate` hook inside of the router.
 *
 * Example:
 * ```TSX
 * import useRouterGate from 'hooks/useRouterGate';
 *
 * function RouterGateRoot() {
 *   useRouterGate();
 *   return null;
 * }
 *
 * render(
 *   <MemoryRouter initialEntries={['/path?query=string']}>
 *     <RouterGateRoot />
 *     <MyComponent />
 *   </MemoryRouter>
 * )
 * ```
 *
 *
 * To avoid creating `RouterGateRoot` component that does nothing but importing `useRouterGate` hook, you can use import this hook as a functional component:
 *
 * ```TSX
 * import RouterGateRoot from 'hooks/useRouterGate';
 *
 * render(
 *   <MemoryRouter initialEntries={['/path?query=string']}>
 *     <RouterGateRoot />
 *     <MyComponent />
 *   </MemoryRouter>
 * )
 * ```
 */
export default function useRouterGate() {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  useGate($$router.__.LocationGate, location);
  useGate($$router.__.SetSearchParamsGate, setSearchParams);

  return null;
}
