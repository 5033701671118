import { LinkTypeMap, Link as MuiLink } from '@mui/material';

import { typography as variants } from '~/theme';
import { OverridableComponentProps } from '~/types';

export { variants as linkVariants };

export const linkColors = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  GREY: 'grey'
};

export const linkSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small'
};

export const linkUnderlines = {
  NONE: 'none',
  HOVER: 'hover',
  ALWAYS: 'always'
};

type LinkProps = OverridableComponentProps<LinkTypeMap, 'a'> & { external?: boolean };

export default function Link(props: LinkProps) {
  const { external = false, underline = 'always', ...overrides } = props;

  return <MuiLink className="Link-root" underline={underline} {...(external && { target: '_blank', rel: 'noopener' })} {...overrides} />;
}
