import { TableContainer, Paper, Table, TableBody, TableRow, TableCell, Typography, Tooltip, Skeleton } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from "prop-types";

/**
 * The TableFromSpecifications component is used creating a table from the given searchResults using the specifications
 * provided.
 *
 * @param {object} props - The properties passed to the component.
 * @param {object} props.searchResults - The searchResults data object that contains the data from VerifyAQ request.
 * @param {Array} props.specifications - The specifications array holds the name, jsonName, and type of each value that
 * needs to be retrieved from the searchResults to create the table.
 * @returns {React.ReactNode} React JSX element representing the TableFromSpecifications component.
 */
const TableFromSpecifications = ({specifications, searchResults}) => {

    return (
        <TableContainer component={Paper}>
            <Table size={"small"}>
                <TableBody>
                    {specifications.map(({name, jsonName, type}) => (
                        <TableRow key={jsonName}>
                            <TableCell style={{whiteSpace: 'nowrap'}}>
                                <div style={{display: 'inline-block'}}>
                                    <Typography variant={"body2"} fontWeight={"bold"}>{name}</Typography>
                                </div>
                            </TableCell>
                            <TableCell style={{width: '100%'}}>
                                {getTypeValueAsString(jsonName, searchResults[jsonName], type)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
TableFromSpecifications.propTypes = {
    searchResults: PropTypes.object,
    specifications: PropTypes.array
};


/**
 * The TableSkeleton component is used creating a table skeleton using the specifications provided.
 *
 * @param {object} props - The properties passed to the component.
 * @param {Array} props.specifications - The specifications array holds the name, jsonName, and type of each value that
 * needs to be created as skeleton in order to create the table.
 * @returns {React.ReactNode} React JSX element representing the TableSkeleton component.
 */
const TableSkeleton = ({specifications}) => {
    return (
        <TableContainer component={Paper}>
            <Table size={"small"}>
                <TableBody>
                    {specifications.map(({name, jsonName, description}) => (
                        <TableRow key={jsonName}>
                            <TableCell style={{whiteSpace: 'nowrap'}}>
                                <div style={{display: 'inline-block'}}>
                                    <Typography variant={"body2"} fontWeight={"bold"}>{name}</Typography>
                                </div>
                                <div style={{display: 'inline-block'}}>
                                    <Tooltip title={description} sx={{fontSize: 1, whiteSpace: 'pre-line'}} arrow>
                                        <InfoOutlinedIcon
                                            sx={{color: "primary.main", fontSize: 13, verticalAlign: "super"}}/>
                                    </Tooltip>
                                </div>
                            </TableCell>
                            <TableCell style={{width: '100%'}}>
                                <Skeleton animation={"wave"} sx={{flexGrow: 1}}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
TableSkeleton.propTypes = {
    specifications: PropTypes.object
};

/**
 * Converts a value to a string based on its type, with a special case for 'dpv' were we only return the first char.
 *
 * @param {string} jsonName - The name of the JSON property.
 * @param {*} value - The value to be converted to a string.
 * @param {string} type - The type of the value ('string' or 'boolean').
 * @returns {string} The converted value as a string.
 */
const getTypeValueAsString = (jsonName, value, type) => {
    let returnValue = "";

    if (type === 'string' || type === 'boolean') {
        if (jsonName === 'dpv' && value.length > 0) {
            returnValue = String(value)[0]
        } else {
            returnValue = String(value);
        }
    }

    return returnValue
}

/**
 * The OutputDetailsDataTable component is in charge creating a table using the provided specifications. It also
 * decided which type of table is going to be used based on the isRequestError object.
 *
 * @param {object} props - The properties passed to the component.
 * @param {object} props.searchResults - The searchResults data object that contains the data from VerifyAQ request.
 * @param {object} props.isRequestError - An isRequestError object that will contain an object if there was an error making a request to VerifyAQ.
 * @param {Array} props.specifications - The specifications array holds the name, jsonName, and type of each value that
 * needs to be present in the table.
 * @returns {React.ReactNode} React JSX element representing the TableFromSpecifications component.
 */
const OutputDetailsDataTable = ({searchResults, isRequestError, specifications}) => {

    if (isRequestError === null) {
        return (
            <TableFromSpecifications specifications={specifications} searchResults={searchResults}/>
        )
    } else {
        return (
            <TableSkeleton specifications={specifications}/>
        )
    }
}
OutputDetailsDataTable.propTypes = {
    searchResults: PropTypes.object,
    isRequestError: PropTypes.object,
    specifications: PropTypes.array
};

export default OutputDetailsDataTable;