import { Alert } from '@ghs/components';
import CloseIcon from '@mui/icons-material/Close';
import { Collapse, IconButton, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { $$splPage } from './model';

export default function SplAlerts() {
  const alertData = useUnit($$splPage.$alertData);

  return (
    <Collapse in={Boolean(alertData) && Boolean(alertData?.title || alertData?.message)}>
      <Alert
        data-testid="lookup-form-alert"
        severity={alertData?.severity}
        action={
          <IconButton onClick={() => $$splPage.setAlertData({ severity: 'info', ...alertData, title: '', message: '' })}>
            <CloseIcon />
          </IconButton>
        }
      >
        <Typography variant="h6">{alertData?.title}</Typography>
        <Typography>{alertData?.message}</Typography>
      </Alert>
    </Collapse>
  );
}
