import { createGate } from 'effector-react';

/** @type {import('effector-react').Gate<import('@auth0/auth0-react').Auth0ContextInterface | null>} */
const Auth0Gate = createGate('auth0');

export const $$auth = {
  $user: Auth0Gate.state.map(state => state?.user || null),

  __: {
    Auth0Gate
  }
};
