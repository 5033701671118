import CloseIcon from '@mui/icons-material/Close';
import { Collapse, IconButton, Typography } from '@mui/material';

import { Alert } from '@ghs/components';

import { useUnit } from 'effector-react';
import { $$splResultsPage } from './model';

export default function SplResultsAlerts() {
  const alertData = useUnit($$splResultsPage.$alertData);

  return (
    <Collapse in={!!alertData && (Boolean(alertData.title) || Boolean(alertData.message))}>
      <Alert
        severity={alertData?.severity}
        action={
          <IconButton onClick={() => $$splResultsPage.closeAlertClicked()}>
            <CloseIcon />
          </IconButton>
        }
      >
        <Typography variant="h6">{alertData?.title}</Typography>
        <Typography>{alertData?.message}</Typography>
      </Alert>
    </Collapse>
  );
}
