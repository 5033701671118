import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { DataGridStylesWithoutSelectionOverride } from '../../../util/DataGridStyles';

type DataAccessUsersTableProps<T extends Reseller.User | Reseller.LineOfBusiness> = {
  dataGridProps: ReturnType<DataGridFactory<T>['useDataGrid']>;
  columns: GridColDef<Reseller.User | Reseller.LineOfBusiness>[];
};

export default function DataAccessUsersTable<T extends Reseller.User | Reseller.LineOfBusiness>(props: DataAccessUsersTableProps<T>) {
  const { dataGridProps, columns } = props;

  return (
    <DataGridPro
      {...dataGridProps}
      autoHeight
      columns={columns}
      disableRowSelectionOnClick
      editMode="row"
      getRowId={row => row.id}
      initialState={{ columns: { columnVisibilityModel: { id: false } }, pagination: { paginationModel: { pageSize: 10 } } }}
      pagination
      pageSizeOptions={[5, 10]}
      autosizeOnMount={false} // vitest is failing beause of this prop
      autosizeOptions={{ includeOutliers: true, includeHeaders: true }}
      sx={{
        ...DataGridStylesWithoutSelectionOverride,
        '.MuiDataGrid-columnHeaderTitleContainerContent': {
          width: '100%'
        }
      }}
    />
  );
}

DataAccessUsersTable.propTypes = {
  dataGridProps: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired
};
