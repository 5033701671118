import { AxiosError } from 'axios';
import { attach, createEvent, createStore, sample } from 'effector';
import { spread } from 'patronum';
import { $$auth } from '../../services/AuthService/model';
import { $$contactService } from '../../services/ContactService/model';
import { $$splService } from '../../services/SplService/model';
import { AcsEvent, MailPiece, Scan } from '../../services/SplService/types';
import { ErrorMessageInfo, generateErrorAlertWithContact, generateTimeoutErrorAlert, isTimeoutError } from '../../util/ErrorUtils';
import { $$router } from '../../util/RouterUtils/RouterUtils';
import { dataNotFoundErrorAlert, exportPDFFx } from './utils/utils';

const pageMounted = createEvent<boolean>();

const closeAlertClicked = createEvent<void>();

const exportPdfClicked = createEvent<void>();

const $mailPiece = createStore<MailPiece | null>(null).reset(pageMounted);

const $acsEvents = createStore<AcsEvent[]>([]).reset(pageMounted);

const $scans = createStore<Scan[]>([]).reset(pageMounted);

const $alertData = createStore<ErrorMessageInfo | null>(null).reset(pageMounted);

const $trackCode = $$router.$search.map(search => search?.trackCode || '');

const $packageId = $$router.$search.map(search => search?.pkgId || '');

const loadDataFx = attach({
  source: {
    imb: $trackCode,
    pkgId: $packageId
  },
  effect: $$splService.getMailDetailsFx
});

sample({
  source: $alertData,
  clock: closeAlertClicked,
  filter: Boolean,
  fn: ({ title: _title, message: _message, ...alert }) => ({ ...alert, title: '', message: '' }),
  target: $alertData
});

sample({
  source: {
    mailPiece: $mailPiece,
    acsEvents: $acsEvents,
    scans: $scans
  },
  clock: exportPdfClicked,
  fn: ({ mailPiece, acsEvents, scans }) => ({ mailPiece, acsEvents, scans }),
  target: exportPDFFx
});

sample({
  source: {
    contactInfo: $$contactService.$contactInfo,
    user: $$auth.$user
  },
  clock: exportPDFFx.failData,
  fn: ({ contactInfo, user }, error) => {
    console.error(error);
    return generateErrorAlertWithContact({
      contactInfo: contactInfo,
      userEmail: user?.email || '',
      errorMessage: 'There was an error exporting this page to PDF',
      requestId: (error as AxiosError)?.response?.headers?.['Request-Id']
    });
  },
  target: $alertData
});

sample({
  clock: pageMounted.filter({ fn: Boolean }),
  target: loadDataFx
});

sample({
  clock: $$splService.getAcsEventsFx.doneData,
  target: $acsEvents
});

sample({
  clock: $$splService.getScanDataFx.doneData,
  target: $scans
});

sample({
  clock: $$splService.getMailDetailsFx.doneData,
  target: $mailPiece
});

sample({
  source: {
    trackCode: $trackCode,
    pkgId: $packageId
  },
  clock: $$splService.getMailDetailsFx.doneData,
  fn: ({ trackCode, pkgId }, { mailDate }) => ({
    getAcsEvents: { trackCode, pkgId, mailDate: mailDate },
    getScanData: { trackCode, pkgId, date: mailDate }
  }),
  target: spread({
    getAcsEvents: $$splService.getAcsEventsFx,
    getScanData: $$splService.getScanDataFx
  })
});

sample({
  source: {
    trackCode: $trackCode,
    pkgId: $packageId,
    contactInfo: $$contactService.$contactInfo,
    user: $$auth.$user
  },

  clock: [$$splService.getMailDetailsFx.failData, $$splService.getAcsEventsFx.failData, $$splService.getScanDataFx.failData],
  fn: ({ trackCode, pkgId, contactInfo, user }, error) => {
    if ((error as AxiosError)?.response?.status === 404) {
      return dataNotFoundErrorAlert({ imb: trackCode, packageId: pkgId });
    }
    if (isTimeoutError(error as AxiosError)) {
      return generateTimeoutErrorAlert({ email: user?.email || '', url: window.location.href });
    }
    console.error('Error fetching data: ', error);
    return generateErrorAlertWithContact({
      contactInfo: contactInfo,
      userEmail: user?.email || '',
      requestId: (error as AxiosError)?.response?.headers?.['Request-Id'],
      errorMessage: 'There was an error loading your search results. Please retry your search'
    });
  },
  target: $alertData
});

export const $$splResultsPage = {
  pageMounted,
  closeAlertClicked,
  exportPdfClicked,

  loadDataFx,

  $isMailDetailsFxPending: $$splService.getMailDetailsFx.pending,
  $isAcsEventsFxPending: $$splService.getAcsEventsFx.pending,
  $isScanDataFxPending: $$splService.getScanDataFx.pending,
  $isLoading: sample({
    source: [$$splService.getMailDetailsFx.pending, $$splService.getAcsEventsFx.pending, $$splService.getScanDataFx.pending],
    fn: states => states.some(Boolean)
  }),
  $isExportingPdf: exportPDFFx.pending,
  $mailPiece,
  $acsEvents,
  $scans,
  $alertData,
  $trackCode,
  $packageId
};
