import { DataGrid, Link } from '@ghs/components';
import { Box, CircularProgress, List, ListItem, ListItemText, Typography } from '@mui/material'
import { DataGridProProps, GridColDef } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { formatZip } from '../../util/AddressUtils';
import { SearchResultItem } from './types';

type SearchResultsProps = {
  data: {
    rows: SearchResultItem[];
    isLoaded: boolean;
  };
  loading: boolean;
};

export default function SearchResults(props: SearchResultsProps) {
  const { data, loading } = props;

  const userDetails = useContext(UserContext);

  if (!data.isLoaded) {
    return (
      <Box sx={{ display: 'flex' }} justifyContent="center">
        <CircularProgress sx={{ ...(!loading && { display: 'none' }) }} />
      </Box>
    );
  }

  return (
    <Box id="search-results-container" data-testid="search-results-container">
      <Typography variant="body2" color="text.primary" sx={{ fontStyle: 'italic' }}>
        * indicates ACS data in the record
      </Typography>
      <DataGrid
        columns={columns}
        rows={data.rows}
        rowSelection={false}
        autoHeight
        pagination
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
        pageSizeOptions={[5, 10, 25]}
        // Wrap long cells
        getRowHeight={() => 'auto'}
        // Detail panel configuration
        getDetailPanelContent={userDetails.isSt ? getDetailPanelContent : undefined}
        getDetailPanelHeight={userDetails.isSt ? () => 'auto' : undefined}
        getDetailPanelVariant={userDetails.isSt ? 'standard' : undefined}
        // Restore padding due to using getRowHeight & disable cell selection outline
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          '& .MuiDataGrid-cell:focus': { outline: 'none' },
          '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
          '.MuiDataGrid-cell': { overflowWrap: 'anywhere' }
        }}
        localeText={{
          noRowsLabel: 'No results'
        }}
      />
    </Box>
  );
}

function buildName(row: SearchResultItem): string {
  return [row.name, row.businessName].filter(Boolean).join('\n');
}

function buildAddress(row: SearchResultItem): string {
  return [row.address, [row.city, row.state, formatZip(row.zip)].filter(Boolean).join(', ')].join('\n');
}

function getUserFields(row: SearchResultItem): string[] {
  return [row.userField1, row.userField2, row.userField3].filter(Boolean);
}

const columns: GridColDef<SearchResultItem>[] = [
  {
    renderCell: ({ row }) => <Link component={RouterLink} to={`/splResults?pkgId=${row.pkgId}&trackCode=${row.trackCode}`}>{`${row.clientSeqNum ?? 'BLANK'}${row.hasAcs ? '*' : ''}`}</Link>,
    valueFormatter: value => value ?? 'BLANK',
    field: 'clientSeqNum',
    headerName: 'Unique Record ID',
    flex: 1,
    hideable: false
  },
  {
    field: 'mailDate',
    headerName: 'Mail Date',
    flex: 0.5,
    minWidth: 120,
    valueGetter: params => dayjs(params).toDate(),
    valueFormatter: date => dayjs(date).format('MM/DD/YYYY')
  },
  { field: 'product', headerName: 'Campaign', flex: 1 },
  {
    field: 'name',
    headerName: 'Name / Business Name',
    renderCell: params => (
      <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
        {buildName(params.row)}
      </Typography>
    ),
    flex: 1
  },
  {
    field: 'address',
    headerName: 'Address',
    renderCell: params => (
      <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
        {buildAddress(params.row)}
      </Typography>
    ),
    flex: 1
  }
];

const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = ({ row }) => {
  const userFields = getUserFields(row);
  if (userFields.length > 0) {
    return (
      <List sx={{ p: 1, ml: 1 }}>
        {userFields.map(userField => (
          <ListItem key={`user-field-list-item-${userField}`} sx={{ my: 0, p: 0 }}>
            <ListItemText primary={userField} />
          </ListItem>
        ))}
      </List>
    );
  }
  // By returning null, the expand button will be disabled
  return null;
};
