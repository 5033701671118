import PropTypes from 'prop-types';
import React from 'react';

import { Skeleton as MuiSkeleton } from '@mui/material';

export const skeletonVariants = {
  TEXT: 'text',
  CIRCULAR: 'circular',
  RECTANGULAR: 'rectangular'
};

/** @type {React.FC<import('@mui/material').SkeletonProps>} */
const Skeleton = ({ variant, width, height, animation = 'pulse', ...overrides }) => {
  return <MuiSkeleton width={width} height={height} variant={variant} animation={animation} {...overrides} />;
};

Skeleton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  variant: PropTypes.oneOf(Object.values(skeletonVariants))
};

Skeleton.defaultProps = {
  variant: 'text'
};

export default Skeleton;
