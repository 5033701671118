import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { Autocomplete } from '@ghs/components';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SnackbarRoot from '../../components/SnackbarRoot/SnackbarRoot';
import RateTable from './utils/RateTable';
import { useUnit } from 'effector-react';
import useMountedEvent from '../../hooks/useMountedEvent';
import { $$mailTrakAutoRatingPage } from './model';
import { createFilterOptions } from '@mui/base';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MailTrakAutoRating = () => {
  useMountedEvent($$mailTrakAutoRatingPage.pageMounted);
  const clientList = useUnit($$mailTrakAutoRatingPage.$clientList);
  const lobList = useUnit($$mailTrakAutoRatingPage.$lobList);
  const stidList = useUnit($$mailTrakAutoRatingPage.$stidList);
  const currentRates = useUnit($$mailTrakAutoRatingPage.$currentRates);

  const clientValue = useUnit($$mailTrakAutoRatingPage.$clientValue);
  const lobValue = useUnit($$mailTrakAutoRatingPage.$lobValue);
  const stidValue = useUnit($$mailTrakAutoRatingPage.$stidValue);
  const disableGetExistingRates = useUnit($$mailTrakAutoRatingPage.$getExistingRatesDisabled);

  const editRatesFormData = useUnit($$mailTrakAutoRatingPage.$editRatesFormData);
  const editRatesFormDataErrors = useUnit($$mailTrakAutoRatingPage.$editRatesFormDataErrors);
  const disableSaveRate = useUnit($$mailTrakAutoRatingPage.$disableSaveRate);
  const disableDeleteRate = useUnit($$mailTrakAutoRatingPage.$disableDeleteRate);

  const currentRatesExpanded = useUnit($$mailTrakAutoRatingPage.$currentRatesExpanded);
  const currentRatesLoading = useUnit($$mailTrakAutoRatingPage.$rateReportLoading);
  const clientListLoading = useUnit($$mailTrakAutoRatingPage.$clientListLoading);
  const stidListLoading = useUnit($$mailTrakAutoRatingPage.$stidListLoading);

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Autocomplete
              options={clientList || []}
              autoHighlight
              data-testid="client-list-autocomplete"
              value={clientValue}
              loading={clientListLoading}
              getOptionLabel={option => option.clientName + ' (' + option.clientId + ')'}
              renderInput={params => <TextField {...params} label="Client" autoFocus />}
              onChange={(_event, value) => {
                if (value != null) {
                  $$mailTrakAutoRatingPage.clientSelected(value);
                } else {
                  $$mailTrakAutoRatingPage.noClientSelected();
                }
              }}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Autocomplete
              multiple
              data-testid="lob-list-autocomplete"
              disableCloseOnSelect
              options={lobList || []}
              filterOptions={(options, params) => {
                const filter = createFilterOptions();
                const filtered = filter(options, params);
                return ['Select All', ...filtered];
              }}
              value={lobValue}
              disabled={clientValue === null}
              getOptionLabel={option => typeof option === 'string' ? option : `${option.lobName} (${option.childId})`}
              renderInput={params => <TextField {...params} label="Line Of Business" />}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{ marginRight: 2 }} checked={option === 'Select All' ? lobValue.length === lobList.length : selected} />
                    {typeof option === 'string' ? option : `${option.lobName} (${option.childId})`}
                  </li>
                );
              }}
              onChange={(_event, value) => {
                if (Array.isArray(value) && value.length > 0) {
                  if (value.find(option => option === 'Select All')) {
                    $$mailTrakAutoRatingPage.lobSelected(lobValue.length === lobList.length ? [] : lobList);
                  } else {
                    $$mailTrakAutoRatingPage.lobSelected(value);
                  }
                } else {
                  $$mailTrakAutoRatingPage.noLOBSelected();
                }
              }}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Autocomplete
              multiple
              data-testid="stid-list-autocomplete"
              disableCloseOnSelect
              options={stidList || []}
              filterOptions={(options, params) => {
                const filter = createFilterOptions();
                const filtered = filter(options, params);
                return ['Select All', ...filtered];
              }}
              value={stidValue}
              loading={stidListLoading}
              disabled={Array.isArray(lobValue) && lobValue.length < 1}
              getOptionLabel={option => option.toString()}
              renderInput={params => <TextField {...params} label="STID" />}
              renderOption={(props, option, { selected }) => {
                const { ...optionProps } = props;
                return (
                  <li {...optionProps}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{ marginRight: 2 }} checked={option === 'Select All' ? stidValue.length === stidList.length : selected} />
                    {option}
                  </li>
                );
              }}
              onChange={(_event, value) => {
                if (Array.isArray(value) && value.length > 0) {
                  if (value.find(option => option === 'Select All')) {
                    $$mailTrakAutoRatingPage.stidSelected(stidValue.length === stidList.length ? [] : stidList);
                  } else {
                    $$mailTrakAutoRatingPage.stidSelected(value);
                  }
                } else {
                  $$mailTrakAutoRatingPage.noSTIDSelected();
                }
              }}
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <Button data-testid="clear-button" variant="contained" sx={{ height: '100%' }} color="secondary" fullWidth onClick={$$mailTrakAutoRatingPage.clearClicked}>
              Clear
            </Button>
          </Grid>
          <Grid item sm={6} md={3}>
            <Button
              variant="contained"
              data-testid="get-existing-rates-button"
              sx={{ height: '100%' }}
              color="primary"
              fullWidth
              onClick={() => $$mailTrakAutoRatingPage.getExistingRatesClicked()}
              disabled={disableGetExistingRates}
            >
              Get Existing Rates
            </Button>
          </Grid>
          <Grid item sm={12}>
            <Paper variant={'elevation'} elevation={3}>
              <AppBar elevation={1} position="static" sx={{ color: 'primary.contrastText', bgcolor: 'primary.main', borderRadius: '4px 4px 0 0' }}>
                <Typography variant={'h6'} sx={{ m: 2 }}>
                  Edit Rate
                </Typography>
              </AppBar>
              <Box sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                  <Grid item sm={6} md={3}>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        label="Rate"
                        data-testid="rate-textfield"
                        variant="standard"
                        error={!!editRatesFormDataErrors.rateValueError}
                        helperText={editRatesFormDataErrors.rateValueError}
                        value={editRatesFormData.rateValue}
                        onChange={event =>
                          $$mailTrakAutoRatingPage.editRatesFormValueChanged({
                            name: 'rateValue',
                            value: event.target.value
                          })
                        }
                      />
                      <RadioGroup
                        row
                        sx={{ flexGrow: 1, flexFlow: 'nowrap', alignItems: 'end' }}
                        value={editRatesFormData.rateDecoratorValue}
                        onChange={event => {
                            $$mailTrakAutoRatingPage.resetEditRate()
                            $$mailTrakAutoRatingPage.editRatesFormValueChanged({
                              name: 'rateDecoratorValue',
                              value: event.target.value
                            })
                          }
                        }
                      >
                        <FormControlLabel value="/M" control={<Radio data-testid="m-radio" />} label="/M" data-testid="m-radio-control-label" />
                        <FormControlLabel value="Flat" control={<Radio data-testid="flat-radio" />} label="Flat" data-testid="flat-radio-control-label" />
                      </RadioGroup>
                    </Stack>
                  </Grid>
                  <Grid item sm={6} md={3}>
                    <TextField
                      label="Minimum Rate Per Version"
                      data-testid="min-rate-per-version-textfield"
                      variant="standard"
                      fullWidth
                      error={!!editRatesFormDataErrors.minRatePerVersionValueError}
                      helperText={editRatesFormDataErrors.minRatePerVersionValueError}
                      value={editRatesFormData.minRatePerVersionValue}
                      disabled={editRatesFormData.rateDecoratorValue === "Flat"}
                      onChange={event =>
                        $$mailTrakAutoRatingPage.editRatesFormValueChanged({
                          name: 'minRatePerVersionValue',
                          value: event.target.value
                        })
                      }
                    />
                  </Grid>
                  <Grid item sm={6} md={3}>
                    <TextField
                      label="Minimum Rate Per Job"
                      data-testid="min-rate-per-job-textfield"
                      variant="standard"
                      fullWidth
                      error={!!editRatesFormDataErrors.minRatePerJobValueError}
                      helperText={editRatesFormDataErrors.minRatePerJobValueError}
                      value={editRatesFormData.minRatePerJobValue}
                      onChange={event =>
                        $$mailTrakAutoRatingPage.editRatesFormValueChanged({
                          name: 'minRatePerJobValue',
                          value: event.target.value
                        })
                      }
                    />
                  </Grid>
                  <Grid item sm={6} md={3}>
                    <TextField
                      label="Break Point"
                      data-testid="breakpoint-textfield"
                      variant="standard"
                      fullWidth
                      error={!!editRatesFormDataErrors.breakPointValueError}
                      helperText={editRatesFormDataErrors.breakPointValueError}
                      value={editRatesFormData.breakPointValue}
                      sx={{
                        pointerEvents: 'none',
                      }}
                      slotProps={{
                        input: {
                          readOnly: true,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ padding: 2 }}>
                <Button
                  data-testid="save-button"
                  variant="contained"
                  className="gridButton"
                  color="primary"
                  sx={{ marginRight: 1 }}
                  onClick={() => $$mailTrakAutoRatingPage.saveRateClickedValidation()}
                  disabled={disableSaveRate}
                >
                  Save
                </Button>
                <Button
                  data-testid="delete-button"
                  variant="contained"
                  className="gridButton"
                  color="error"
                  sx={{ marginLeft: 1 }}
                  disabled={disableDeleteRate}
                  onClick={() => $$mailTrakAutoRatingPage.deleteRateClicked()}
                >
                  Delete
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid item sm={12}>
            <Accordion
              data-testid="current-rate-accordion"
              elevation={3}
              expanded={currentRatesExpanded}
              disabled={clientValue === null}
              onChange={(_, isExpanded) => $$mailTrakAutoRatingPage.currentRatesClicked(isExpanded)}
            >
              <AccordionSummary data-testid="current-rate-accordion-summary" expandIcon={<ExpandMoreIcon sx={{ color: 'grey.main' }} />} sx={{ bgcolor: 'primary.main', borderRadius: '4px 4px 0 0' }}>
                <Typography variant={'h6'} sx={{ color: 'primary.contrastText', flexGrow: 1 }}>
                  Current Rates
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RateTable clientName={clientValue?.clientName} ratesLoading={currentRatesLoading} currentRates={currentRates} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Box>
      <SnackbarRoot snackbarProps={{ autoHideDuration: 3000 }} />
    </div>
  );
};

export default MailTrakAutoRating;
