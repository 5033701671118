import { Button } from '@ghs/components';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Container, Stack, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';
import { DeliveryMap } from '../../components/DeliveryMap';
import { $$tutorialVideos } from '../../components/TutorialVideosModal/model';
import TutorialVideoButton from '../../components/TutorialVideosModal/TutorialVideoButton';
import useMountedEvent from '../../hooks/useMountedEvent';
import { $$personaService } from '../../services/PersonaService/model';
import AcsEvents from './AcsEvents';
import MailingInformation from './MailingInformation';
import { $$splResultsPage } from './model';
import MailingDetails from './ScanDetails';
import SplResultsAlerts from './SplResultsAlerts';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDsjP_bJpNny1UPNjus_3K3oSs5qALVtUw';

export default function SplResultsPage() {
  useMountedEvent($$splResultsPage.pageMounted);

  const navigate = useNavigate();

  const isLoading = useUnit($$splResultsPage.$isLoading);
  const isExportingPdf = useUnit($$splResultsPage.$isExportingPdf);
  const activePersonaGhsSystem = useUnit($$personaService.$activePersona.map(persona => persona?.ghsSystem || null));

  return (
    <Container data-testid="spl-results-container" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <TutorialVideoButton url={$$tutorialVideos.links[activePersonaGhsSystem === 'MAP' ? 'SPL:MT_PERSONA' : 'SPL:ST_PERSONA']} />

      <Typography variant="h4" color="text.primary">
        Single Piece Lookup
      </Typography>
      <SplResultsAlerts />

      <Stack direction="row" spacing={2}>
        <Button variant="outlined" size="medium" color="primary" onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button color="primary" variant="contained" onClick={() => $$splResultsPage.exportPdfClicked()} disabled={isLoading || isExportingPdf}>
          Export to PDF
        </Button>
      </Stack>
      <MailingInformation />
      <AcsEvents />
      <MailingDetails />
      <Wrapper apiKey={GOOGLE_MAPS_API_KEY} render={MapRenderer}>
        <DeliveryMap />
      </Wrapper>
    </Container>
  );
}

function MapRenderer(status: Status) {
  if (status === Status.LOADING || status === Status.FAILURE) {
    return (
      <Typography variant="h5" color="text.primary">
        {status} ..
      </Typography>
    );
  }

  return <Typography />;
}
