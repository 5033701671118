import { ExpandMore } from '@mui/icons-material';
import { AutocompleteProps, ChipTypeMap, Autocomplete as MuiAutocomplete, TextFieldVariants } from '@mui/material';
import TextField from '~/components/TextField';
import { DropdownSxOverrides } from '../Select';

export default function Autocomplete<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>(props: Omit<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>, 'renderInput'> & { label?: string; placeholder?: string; variant?: TextFieldVariants }) {
  const { options, label, placeholder, variant, ...overrides } = props;

  return (
    <MuiAutocomplete
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          variant={variant}
          placeholder={placeholder}
          label={label}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
      popupIcon={<ExpandMore />}
      slotProps={{ paper: { sx: DropdownSxOverrides } }}
      {...overrides}
    />
  );
}
