import { Box, Container, Switch, Tooltip, Typography, Stack, Divider, IconButton } from '@mui/material';
import LogosTable from '../../../components/logos/LogosTable';
import { $$resellerLogosPage } from './model';
import { useUnit } from 'effector-react';
import useMountedEvent from '../../../hooks/useMountedEvent';
import * as React from 'react';
import { Autocomplete, Button, textFieldVariants } from '@ghs/components';
import UploadLogoDialog from '../../../components/logos/UploadLogoDialog';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SnackbarRoot from '../../../components/SnackbarRoot/SnackbarRoot';
import TutorialVideoButton from '../../../components/TutorialVideosModal/TutorialVideoButton';
import { $$tutorialVideos } from '../../../components/TutorialVideosModal/model';

/**
 * Component for displaying the form to manage customers' logos.
 *
 * @returns {React.ReactNode} AdminLogos component
 */
function AdminLogos() {
  useMountedEvent($$resellerLogosPage.tableMounted);

  const logoCriteria = useUnit($$resellerLogosPage.$logoCriteria);
  const isMaxResellerLogosReached = useUnit($$resellerLogosPage.$isMaxResellerLogosReached);
  const isMaxCustomerLogosReached = useUnit($$resellerLogosPage.$isMaxCustomerLogosReached);
  const isResellerUser = useUnit($$resellerLogosPage.$isResellerUser);
  const customers = useUnit($$resellerLogosPage.$customers);
  const selectedCustomer = useUnit($$resellerLogosPage.$selectedCustomer);

  const resellerLogos = useUnit($$resellerLogosPage.$resellerLogos);
  const resellerLogosLoading = useUnit($$resellerLogosPage.$resellerLogosLoading);
  const resellerSelectionModel = useUnit($$resellerLogosPage.$resellerSelectionModel);
  const resellerUploadDialogOpen = useUnit($$resellerLogosPage.$resellerUploadDialogOpen);
  const resellerDeleteDisabled = useUnit($$resellerLogosPage.$resellerDeleteDisabled);
  const resellerActivateDisabled = useUnit($$resellerLogosPage.$resellerActivateDisabled);
  const resellerUploadLogoInProgress = useUnit($$resellerLogosPage.$resellerUploadLogoInProgress);

  const customerLogos = useUnit($$resellerLogosPage.$customerLogos);
  const customerLogosLoading = useUnit($$resellerLogosPage.$customerLogosLoading);
  const customerSelectionModel = useUnit($$resellerLogosPage.$customerSelectionModel);
  const customerUploadDialogOpen = useUnit($$resellerLogosPage.$customerUploadDialogOpen);
  const customerDeleteDisabled = useUnit($$resellerLogosPage.$customerDeleteDisabled);
  const customerActivateDisabled = useUnit($$resellerLogosPage.$customerActivateDisabled);
  const customerUploadLogoInProgress = useUnit($$resellerLogosPage.$customerUploadLogoInProgress);

  const bannerOptIn = useUnit($$resellerLogosPage.$bannerOptIn);

  return (
    <Container id="external-admin-logos-container">
      <ConfirmationDialog />
      <TutorialVideoButton url={isResellerUser ? $$tutorialVideos.links['ST_ADMIN:LOGOS:RESELLER_PERSONA'] : $$tutorialVideos.links['ST_ADMIN:LOGOS:CUSTOMER_PERSONA']} />

      <SnackbarRoot snackbarProps={{ autoHideDuration: 3000 }} />
      <Stack direction="row">
        {!isResellerUser ? null : (
          <Container>
            <Stack direction="row" sx={{ mb: 11 }}>
              <Typography variant="h4" color="text.primary" sx={{ mb: 6 }}>
                Reseller Logo Configuration
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={$$resellerLogosPage.resellerHelpClicked} sx={{ p: 0 }}>
                <HelpOutlineIcon color="primary" />
              </IconButton>
            </Stack>
            <LogosTable loading={resellerLogosLoading} logoSets={resellerLogos} selectionModel={resellerSelectionModel} handleSelectionChange={$$resellerLogosPage.resellerLogoSelected} />
            <Box sx={{ display: 'flex' }}>
              <Button color="primary" onClick={$$resellerLogosPage.resellerActivateClicked} size="medium" variant="contained" disabled={resellerActivateDisabled} sx={{ mt: 1 }}>
                Activate
              </Button>
              <Button color="error" onClick={$$resellerLogosPage.resellerDeleteClicked} size="medium" variant="contained" disabled={resellerDeleteDisabled} sx={{ mt: 1, ml: 1 }}>
                Delete
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Tooltip title={isMaxResellerLogosReached ? 'There is a limit of 5 custom logos' : ''} arrow>
                <span>
                  <Button color="secondary" onClick={$$resellerLogosPage.resellerTableUploadClicked} size="medium" variant="contained" disabled={isMaxResellerLogosReached} sx={{ mt: 1 }}>
                    Upload
                  </Button>
                </span>
              </Tooltip>
            </Box>
            <UploadLogoDialog
              onClose={$$resellerLogosPage.resellerUploadDialogClosed}
              handleUpload={$$resellerLogosPage.resellerUploadDialogUploadClicked}
              uploadInProgress={resellerUploadLogoInProgress}
              open={resellerUploadDialogOpen}
              imageMaxSizeInBytes={logoCriteria?.maxSizeInBytes}
            />
          </Container>
        )}
        <Container>
          <Stack direction="row" sx={{ mb: 2 }}>
            <Typography variant="h4" color="text.primary" sx={{ mb: 6 }}>
              Customer Logo Configuration
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={$$resellerLogosPage.customerHelpClicked} sx={{ p: 0 }}>
              <HelpOutlineIcon color="primary" />
            </IconButton>
          </Stack>
          {!isResellerUser ? null : (
            <Autocomplete
              id="customer-select"
              label="Select Customer"
              placeholder="Select"
              options={customers}
              variant={textFieldVariants.OUTLINED}
              sx={{ mb: 2 }}
              name="lob"
              value={selectedCustomer}
              onChange={(_event, value) => $$resellerLogosPage.customerSelected(value)}
              getOptionKey={option => option.id}
            />
          )}
          <LogosTable loading={customerLogosLoading} logoSets={customerLogos} selectionModel={customerSelectionModel} handleSelectionChange={$$resellerLogosPage.customerLogoSelected} />
          <Box sx={{ display: 'flex' }}>
            <Button color="primary" onClick={$$resellerLogosPage.customerActivateClicked} size="medium" variant="contained" disabled={!selectedCustomer || customerActivateDisabled} sx={{ mt: 1 }}>
              Activate
            </Button>
            <Button color="error" onClick={$$resellerLogosPage.customerDeleteClicked} size="medium" variant="contained" disabled={!selectedCustomer || customerDeleteDisabled} sx={{ mt: 1, ml: 1 }}>
              Delete
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip title={isMaxCustomerLogosReached ? 'There is a limit of 5 custom logos' : ''} arrow>
              <span>
                <Button
                  color="secondary"
                  onClick={$$resellerLogosPage.customerTableUploadClicked}
                  size="medium"
                  variant="contained"
                  disabled={!selectedCustomer || isMaxCustomerLogosReached}
                  sx={{ mt: 1 }}
                >
                  Upload
                </Button>
              </span>
            </Tooltip>
          </Box>
          <UploadLogoDialog
            onClose={$$resellerLogosPage.customerUploadDialogClosed}
            handleUpload={$$resellerLogosPage.customerUploadDialogUploadClicked}
            uploadInProgress={customerUploadLogoInProgress}
            open={customerUploadDialogOpen}
            imageMaxSizeInBytes={logoCriteria?.maxSizeInBytes}
          />
        </Container>
      </Stack>
      {!isResellerUser ? null : (
        <>
          <Divider sx={{ mt: 4, mb: 4 }} />
          <Container>
            <Typography variant="h4" color="text.primary" sx={{ mb: 6 }}>
              GHS Banner Configuration
            </Typography>
            <Typography color="text.primary">
              By default, GHS banners that display information related to service outages, scan delays, etc. will not be displayed on the portal pages for your Customers. You may choose to Opt In.
            </Typography>
            <Stack direction="row" alignItems="center">
              <Switch checked={bannerOptIn} onChange={$$resellerLogosPage.bannerOptInSwitchClicked} value={bannerOptIn} />
              <Typography color="text.primary">Show GHS Banners</Typography>
            </Stack>
          </Container>
        </>
      )}
    </Container>
  );
}

AdminLogos.propTypes = {};

export default AdminLogos;
