import { DataGridPro, gridClasses, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const createColumn = (field, headerName, flex, type, renderCell) => {
  const column = {
    field,
    headerName,
    flex
  };

  if (type) {
    column.type = type;
  }

  if (renderCell) {
    column.renderCell = renderCell;
  }

  return column;
};

const CustomToolbar = ({ clientName }) => {
  return (
    <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }} />
      <div style={{ paddingRight: '1px' }}>
        <GridToolbarExport
          csvOptions={{
            fileName: `${clientName} Report`
          }}
          slotProps={{
            tooltip: { title: 'Export data' },
            button: { variant: 'outlined' }
          }}
        />
      </div>
    </GridToolbarContainer>
  );
};
CustomToolbar.propTypes = {
  clientName: PropTypes.string
};

const RateTable = ({ clientName, ratesLoading, currentRates }) => {
  const getRowId = row => {
    return row.uid;
  };

  const columns = [
    createColumn('lobName', 'LOB', 1),
    createColumn('serviceTypeName', 'STID', 1),
    createColumn('extraService', 'Extra Service', 1),
    createColumn('unit', 'Unit', 1),
    createColumn('rate', 'Rate', 1, 'number'),
    createColumn('minRatePerVersion', 'Min Rate Per Version', 1, 'number'),
    createColumn('minRatePerJob', 'Min Rate Per Job', 1, 'number'),
    createColumn('breakPoint', 'Break Point', 1, 'number'),
    createColumn('creationDate', 'Date Added', 1.5, null, (params) => <span data-testid="datagrid-cell">{new Date(params.value).toLocaleString('en-US', { timeZone: 'America/New_York' }).replace(', ', ' ')}</span>),
  ];

  return (
    <DataGridPro
      initialState={{
        pagination: { paginationModel: { pageSize: 5 } }
      }}
      sx={{
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none'
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none'
        },
        '& .MuiDataGrid-cell': {
          alignContent: 'center'
        }
      }}
      slots={{ toolbar: CustomToolbar }}
      slotProps={{ toolbar: { clientName } }}
      loading={ratesLoading}
      columns={columns || []}
      rows={currentRates}
      pageSizeOptions={[5, 10, 25]}
      getRowId={getRowId}
      pagination
      disableMultipleRowSelection
      autoHeight
      disableRowSelectionOnClick
    ></DataGridPro>
  );
};
RateTable.propTypes = {
  clientName: PropTypes.string,
  ratesLoading: PropTypes.bool,
  currentRates: PropTypes.array
};

export default RateTable;
