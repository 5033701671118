import { Box, Chip, Divider, List, ListItem } from '@mui/material';
import { DateRange, PickersShortcutsProps } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';

export default function CustomRangeShortcuts(props: PickersShortcutsProps<DateRange<Dayjs>>) {
  const { items, onChange, isValid } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  return (
    <Box sx={{ gridRow: 1, gridColumn: 2 }}>
      <List
        dense
        sx={{
          display: 'flex',
          px: theme => theme.spacing(4),
          '& .MuiListItem-root': {
            pt: 0,
            pl: 0,
            pr: theme => theme.spacing(1)
          }
        }}
      >
        {items.map(item => (
          <ListItem key={item.label}>
            <Chip label={item.label} onClick={() => onChange(item.getValue({ isValid }), 'set', { label: item.label })} disabled={!isValid(item.getValue({ isValid }))} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );
}
