import { ToggleButton as MuiToggleButton, ToggleButtonGroup } from '@mui/material';

type ToggleButtonSize = Exclude<Parameters<typeof MuiToggleButton>[0]['size'], undefined>;

type ToggleButonProps = Parameters<typeof MuiToggleButton>[0] & {
  label?: string;
  divider?: boolean;
};

const paddings: Record<ToggleButtonSize, string> = {
  small: '11px',
  medium: '15px',
  large: '19px'
};

export default function ToggleButton(props: ToggleButonProps) {
  const { children, size = 'medium', disabled, label, sx, divider, ...overrides } = props;

  return (
    <MuiToggleButton
      size={size}
      disabled={disabled}
      sx={{
        gap: 1.5,
        px: paddings[size],
        ...(!divider && { borderWidth: 0 }),
        ...(divider && { borderWidth: 0, borderRightWidth: 1 }),
        borderRadius: '4px',
        '&.Mui-disabled': {
          ...(!divider && { borderWidth: 0 }),
          ...(divider && { borderWidth: 0, borderRightWidth: 1 })
        },
        '&.MuiButtonBase-root': {
          borderRadius: '4px'
        },
        ...sx
      }}
      {...overrides}
    >
      {children}
      {label}
    </MuiToggleButton>
  );
}

export { ToggleButtonGroup };
