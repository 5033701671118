import { Link } from '@ghs/components';
import { AlertColor, Typography } from '@mui/material';
import { AxiosError } from 'axios';

export type ErrorMessageInfo = {
  severity: AlertColor;
  title: string;
  message: React.ReactNode;
};

/**
 * Generate an alert for a timeout error.
 */
export const generateTimeoutErrorAlert = (params: { email: string; url: string }): ErrorMessageInfo => {
  const { email, url } = params;

  const timeoutMessages = [
    <>It&apos;s taking our carrier pigeon a bit longer than we had hoped to return your results. Please try again.</>,
    <>While we typically move faster than a mail carrier in a snowstorm, it seems your results have hit a snowbank. Please try again.</>,
    <>Looks like our hamster fell off his wheel trying to return your results. Please try again.</>
  ];

  return generateErrorAlert({ email, url, errorMessage: timeoutMessages[Date.now() % timeoutMessages.length] });
};

/**
 * Generate an alert for a timeout error.
 */
export const generateErrorAlert = (params: { email: string; url: string; errorMessage: React.ReactNode }): ErrorMessageInfo => {
  const { email, url, errorMessage } = params;
  return {
    severity: 'error',
    title: 'Oops!',
    message: (
      <>
        {errorMessage}
        <br />
        <br />
        Email: {email}
        <br />
        URL: {url}
      </>
    )
  };
};

/**
 * Generate an alert for a timeout error.
 */
export const generateErrorAlertWithContact = (props: { contactInfo: ContactInfo | null; userEmail: string; requestId: string; errorMessage: React.ReactNode }): ErrorMessageInfo => {
  const { contactInfo, userEmail, requestId, errorMessage } = props;
  return {
    severity: 'error',
    title: 'Oops!',
    message: (
      <>
        {errorMessage ? errorMessage : 'There was an error processing your request'}. If this error persists, please contact{' '}
        {contactInfo?.email ? <Link href={`mailto:` + contactInfo.email}>{contactInfo.email}</Link> : 'your tracking supplier'} and include the following information: <br />
        <br />
        Email: {userEmail}
        <br />
        <Typography sx={{ overflowWrap: 'anywhere' }}>URL: {window.location.href}</Typography>
        <br />
        {requestId && <span>Request ID: {requestId}</span>}
      </>
    )
  };
};

/**
 * Determine if an error is due to a timeout.
 */
export const isTimeoutError = (err: AxiosError): boolean => {
  return err?.response?.status === 504 || (err?.code === 'ECONNABORTED' && ['timeout', 'exceeded'].every(s => err?.message?.includes(s)));
};
