import { useAuth0 } from '@auth0/auth0-react';
import { useGate } from 'effector-react';
import { $$auth } from '../services/AuthService/model';

export default function useAuth0Gate() {
  const auth0 = useAuth0();

  useGate($$auth.__.Auth0Gate, auth0);

  return null;
}
