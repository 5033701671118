import PropTypes from 'prop-types';
import { transformDashboardsThumbnailsUrls } from '../util/ThumbnailsUtils';
import { ghApi, sisenseApi } from './DataService';
import { addServerUrlToImage } from './LogoService';
import { $$theme } from './ThemeService/model';

const SisenseAuthenticationStatus = {
  /** @type {'NOT_AUTHENTICATED'} */
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  /** @type {'AUTHENTICATED'} */
  AUTHENTICATED: 'AUTHENTICATED'
};

/**
 * Returns the BI server SSO login URL with a valid JWT token.
 *
 * @param {string} accessToken Access token
 * @returns {Promise<string>} The BI server SSO login URL.
 */
export const getBiLoginRedirectUrl = async accessToken => {
  return ghApi
    .get('/api/bi/jwtToken', { headers: { Authorization: `Bearer ${accessToken}` } })
    .then(response => response.data)
    .then(data => data.jwtToken)
    .then(biServerToken => `${import.meta.env.VITE_BI_SERVER_URL}/jwt?jwt=${biServerToken}`);
};

/**
 * Checks whether user is authenticated against BI server or not.
 *
 * @returns {Promise<boolean>} True if the user is authenticated against BI server. False otherwise.
 */
async function isUserAuthenticatedAgainstBiServer() {
  return sisenseApi
    .get('/dashboards', { params: { fields: 'title' } })
    .then(() => true)
    .catch(d => d.response.status !== 401);
}

/**
 * Ensures the user is authenticated against BI server. If not authenticated, the authentication is done.
 *
 * @param {string} accessToken Access token
 * @returns {Promise<keyof typeof SisenseAuthenticationStatus>} Result of authentication against Sisense.
 */
export const ensureBiServerLogin = async accessToken => {
  try {
    // Check whether user is authenticated or not
    const isAuthenticated = await isUserAuthenticatedAgainstBiServer();
    if (isAuthenticated) {
      console.log('User is authenticated against BI server');
    } else {
      // If user isn't authenticated, the authentication is performed
      await performBiServerLogin(accessToken);
    }

    return SisenseAuthenticationStatus.AUTHENTICATED;
  } catch (authError) {
    console.error('Failed to ensure login against BI server: ' + authError);
    return SisenseAuthenticationStatus.NOT_AUTHENTICATED;
  }
};

/**
 * Performs authentication against BI server.
 *
 * @param {string} accessToken Access token
 * @returns {Promise<string>} Promise containing the result of authentication.
 */
export const performBiServerLogin = async accessToken => {
  try {
    console.log('Authenticating against BI server...');

    const biLoginUrl = await getBiLoginRedirectUrl(accessToken);

    const fetchOptions = {
      method: 'GET',
      mode: 'cors',
      redirect: 'follow',
      credentials: 'include'
    };

    await fetch(biLoginUrl, fetchOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`BI server network response was not ok. ${response.status} - ${response.statusText}`);
        }
        console.log('Authentication against BI server succeeded');
      })
      .catch(error => {
        console.error(`Error authenticating against BI server. ${error}`);
        throw error;
      });
  } catch (authError) {
    console.error('Failed to ensure login against BI server: ' + authError);
  }
};

/**
 * Performs logout from BI server and deletes session info.
 *
 * @param {boolean | null} silentMode Boolean flag indicating whether it's executed in silent mode. If enabled, exceptions won't be
 * thrown upstream.
 */
export async function performBiServerLogout(silentMode = true) {
  // Delete Sisense dashboards info so that it's reloaded for the new selected persona
  sessionStorage.removeItem('dashboards-info');

  // Logout to invalidate all current user's sessions
  return sisenseApi
    .get('/authentication/logout_all')
    .then(() => true)
    .catch(error => {
      console.error(`Could not perform BI server logout. ${error.response?.status} - ${error.response?.statusText}`);
      // Show full error to identify unexpected errors. This will be deleted after some time running with no issues
      console.error(`Full BI server error: ${JSON.stringify(error)}`);
      if (!silentMode) {
        throw error;
      }
    });
}

/**
 * Parses the dashboards information JSON string.
 *
 * @param {string | undefined | null} dashboardsInfoJSONString Dashboards information JSON string.
 * @returns {SisenseInfo} Parsed dashboards information.
 */
function safeParseDashboardsInfo(dashboardsInfoJSONString) {
  try {
    if (dashboardsInfoJSONString === null) {
      return {};
    }
    return JSON.parse(dashboardsInfoJSONString);
  } catch (error) {
    console.error('Failed to parse dashboards info: ' + error);
    return {};
  }
}

/**
 * Loads the user status against Sisense server.
 *
 * @param {string} accessToken Access token
 * @param {number} sisenseReloadCount Reload count
 * @returns {Promise<SisenseInfo>} Sisense user status.
 */
export async function getSisenseInfo(accessToken, sisenseReloadCount = 0) {
  try {
    /** @type {string | undefined | null} Dashboards information JSON. */
    const dashboardsInfoJSONString = sessionStorage.getItem('dashboards-info');

    /** @type {SisenseInfo} Sisense user status. */
    const sisenseInfo = safeParseDashboardsInfo(dashboardsInfoJSONString);

    // If dashboards information doesn't exist or the user isn't authenticated, the information is reloaded
    if (!sisenseInfo || !sisenseInfo.authenticated) {
      console.log('Reloading dashboards info from BI server...');

      // Ensure that the user is logged into Sisense server
      const sisenseAuthStatus = await ensureBiServerLogin(accessToken);

      const dashboards = await getUserDashboardsFromSisense(accessToken);

      sisenseInfo.authenticated = SisenseAuthenticationStatus.AUTHENTICATED === sisenseAuthStatus;
      sisenseInfo.dashboardsNo = (dashboards || []).length;
      sisenseInfo.dashboards = dashboards;
      const defaultDashboardIds = import.meta.env.VITE_BI_SERVER_DEFAULT_DASHBOARD_IDS.split(',');

      // Set the default dashboard to the first default dashboard id that the user has access to
      sisenseInfo.defaultDashboardId = defaultDashboardIds.find(id => dashboards.map(d => d.id).includes(id)) || null;
      sisenseInfo.reloadCount = sisenseReloadCount;

      sessionStorage.setItem('dashboards-info', JSON.stringify(sisenseInfo));
    }

    return sisenseInfo;
  } catch (authError) {
    console.error('Failed to load status from BI server: ' + authError);
    return {};
  }
}

/**
 * Fetches, from Sisense server, all dashboards for current user.
 *
 * @param {string} accessToken Access token
 * @returns {Promise<Pick<DashboardView, 'id' | 'title'>[]>} Promise containing all the dashboards for current user.
 */
const getUserDashboardsFromSisense = async accessToken => {
  let maxAttempts = 3;
  let currentAttempt = 1;

  while (currentAttempt <= maxAttempts) {
    try {
      return await getSisenseDashboards();
    } catch (error) {
      if (currentAttempt === maxAttempts) {
        console.error('Failed to load dashboards list after 3 attempts');
        throw error;
      } else {
        if (error instanceof BiAuthenticationError) {
          console.warn('Not authenticated against BI server. Re-authenticating...');
          await performBiServerLogin(accessToken);
        }
        currentAttempt++;
      }
    }
  }
};

/**
 * Fetches, from Sisense, all dashboards for current user.
 *
 * @returns {Promise<Pick<DashboardView, 'id' | 'title'>[]>} Promise containing all the dashboards for current user.
 */
async function getSisenseDashboards() {
  // Configure fetch options to allow redirection to the page that triggered the authentication (/dashboards or /dashboard) once authentication is done
  return (
    sisenseApi
      .get('/dashboards', { params: { fields: 'title' } })
      .catch(response => {
        if (response.status === 401) {
          throw new BiAuthenticationError();
        }
        throw new Error(`Could not load dashboards. ${response.status} - ${response.statusText}`);
      })
      .then(response => response.data)
      // Dashboards that start with `_` should be hidden, so we exclude them
      .then(data => data.filter(dashboard => !dashboard.title?.startsWith('_')))
      .then(data => data.map(dashboard => ({ id: dashboard.oid, title: dashboard.title })))
  );
}

/**
 * @typedef {{
 *   id: string;
 *   title: string;
 *   description: string;
 *   thumbnailLight: string;
 *   thumbnailDark: string;
 * }} DashboardView Interface for dashboard view.
 */
export const DashboardViewPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  thumbnailLight: PropTypes.string.isRequired,
  thumbnailDark: PropTypes.string.isRequired
});

/**
 * @typedef {Partial<{
 *   authenticated: boolean;
 *   dashboardsNo: number;
 *   defaultDashboardId: string;
 *   dashboards: Pick<DashboardView, 'id' | 'title'>[];
 *   reloadCount: number;
 * }>} SisenseInfo Interface for dashboard view.
 */
export const SisenseInfoPropTypes = PropTypes.shape({
  authenticated: PropTypes.bool,
  dashboardsNo: PropTypes.number,
  defaultDashboardId: PropTypes.string,
  dashboards: PropTypes.arrayOf(DashboardViewPropTypes),
  reloadCount: PropTypes.number
});

/**
 * Fetches all dashboards for current user.
 *
 * @param {string} accessToken Access token
 * @returns {Promise<DashboardView[]>} Promise containing all the dashboards for current user.
 */
export const getUserDashboards = async accessToken => {
  const sisenseInfo = await getSisenseInfo(accessToken);
  const ids = sisenseInfo.dashboards.map(dashboard => dashboard.id).join(',');
  return ghApi
    .get('/api/bi/dashboards', { params: { ids }, headers: { Authorization: `Bearer ${accessToken}` } })
    .then(response => response.data)
    .then(dashboards =>
      dashboards.map(dashboard => ({
        ...dashboard,
        thumbnailLight: addServerUrlToImage(dashboard.thumbnailLight),
        thumbnailDark: addServerUrlToImage(dashboard.thumbnailDark)
      }))
    );
};

/**
 * Fetches all dashboards for admin user.
 *
 * @returns {Promise<DashboardView[]>} Promise containing all the dashboards for admin user.
 */
export async function getAdminDashboards() {
  return ghApi
    .get('/api/bi/admin/dashboards')
    .then(response => response.data)
    .then(transformDashboardsThumbnailsUrls);
}

/**
 * Updates the metadata (thumbnail image, dashboard description) for a specific dashboard.
 *
 * @param {DashboardView} data Data to update
 * @returns {Promise<any|undefined>} Promise containing the request result
 */
export const updateAdminDashboardMetadata = async ({ id, ...data }) => {
  return ghApi.post(`/api/bi/admin/dashboards/${id}`, data).then(response => response.data);
};

/** @type {{[key in 'light' | 'dark']: string}} */
const themesMap = {
  light: import.meta.env.VITE_BI_SERVER_GRAYHAIR_LIGHT_THEME_ID,
  dark: import.meta.env.VITE_BI_SERVER_GRAYHAIR_DARK_THEME_ID
};

class BiAuthenticationError extends Error {
  constructor(response) {
    super(`BI server auth Error`);
    this.response = response;
  }
}

/** @type {Store<string>} */
const $biThemeId = $$theme.$theme.map(theme => themesMap[theme] || null);

export const $$bi = {
  $biThemeId
};
