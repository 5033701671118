import {useEffect, useState} from "react";
import { Paper, AppBar, Typography, Box ,Grid, TextField, Tooltip, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material'
import { Autocomplete } from '@ghs/components'
import PropTypes from "prop-types";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {areObjectStringValuesEmpty, getNewSearchParamsFromObject} from "../../../util/FormUtil";
import {addressInputHelpDescriptions, advancedOptionsHelpDescriptions} from "./AddressInputDescriptions";
import {validStates} from "../../../util/UsStateUtils";
import {FormDataPropTypes} from "../utils/PropTypes";

/**
 * AddressInput component for handling address input in a form.
 *
 * @param {object} props - The properties passed to the component.
 * @param {object} props.formData - The current formData object that contains the values used to make VerifyAQ request.
 * @param {Function} props.setSearchParams - A function to set search parameters.
 * @returns {React.ReactNode} React JSX element representing the AddressInput component.
 */
const AddressInput = ({ formData, setSearchParams }) => {

    const [localFormData, setLocalFormData] = useState(formData);
    const [focusStates, setFocusStates] = useState({});
    const [inputErrors, setInputErrors] = useState({zip: false});

    useEffect(() => {

        const selectedState = validStates.find(state =>
            state.abbreviation.toLowerCase() === formData.state.toLowerCase() ||
            state.name.toLowerCase() === formData.state.toLowerCase()
        );

        const updatedFormData = {
            ...formData,
            state: selectedState ? selectedState.abbreviation : ''
        };

        setLocalFormData(updatedFormData)

    }, [formData]);

    useEffect(() => {
        const { useMixedCase, retainAddressLocation, suiteLinkToAddress2 } = localFormData;
        localStorage.setItem('advanced-options-state', JSON.stringify({ useMixedCase, retainAddressLocation, suiteLinkToAddress2 }));
    }, [localFormData]);

    const handleFocus = (fieldName) => {
        setFocusStates((prevStates) => ({ ...prevStates, [fieldName]: true }));
    };

    const handleBlur = (fieldName) => {
        setFocusStates((prevStates) => ({ ...prevStates, [fieldName]: false }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const newSearchParams = getNewSearchParamsFromObject(localFormData);

        setSearchParams(newSearchParams)
    };


    const handleZipFieldChange = (event) => {
        const {name, value} = event.target

        const valueWithNoDash = value.replace(/-/g, '');

        const mainZip = valueWithNoDash.substring(0, 5);
        const zipPlusFour = valueWithNoDash.substring(5);

        setLocalFormData((prevFormData) => ({
            ...prevFormData,
            [name]: mainZip,
            zipPlusFour,
        }));

        setInputErrors((prevErrors) => ({
            ...prevErrors,
            [name]: name === 'zip' && (valueWithNoDash.trim() !== '' && !/^\d+$/.test(valueWithNoDash)),
        }));
    }

    const handleTextFieldChange = (event) => {
        const {name, value} = event.target
        setLocalFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    const handleClearTextField = () => {
        setLocalFormData(prevFormData => {
            const clearedFormData = { ...prevFormData };

            for (const key in clearedFormData) {
                if (typeof clearedFormData[key] === 'string') {
                    clearedFormData[key] = '';
                }
            }
            return clearedFormData;
        });
    }

    const handleCheckboxChange = (event) => {
        const {name, checked} = event.target;
        setLocalFormData((prevFormData) => ({
            ...prevFormData,
            [name]: checked,
        }));
    };


    const filterOptions = (options, {inputValue}) => {
        const normalizedInput = inputValue.trim().toLowerCase();
        return options.filter(
            (option) =>
                option.name.toLowerCase().startsWith(normalizedInput) ||
                option.abbreviation.toLowerCase().startsWith(normalizedInput)
        );
    };

    const DefaultTextFieldInputLabel = ({fieldName, labelTitle, fieldDescription}) => {
      return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '2px' }}>{labelTitle}</span>
              {focusStates[fieldName] && (
                  <Tooltip title={fieldDescription} sx={{fontSize: 1,}} arrow>
                      <InfoOutlinedIcon
                          sx={{fontSize: 15, verticalAlign: "super"}}/>
                  </Tooltip>
              )}
          </div>
      );
    }
    DefaultTextFieldInputLabel.propTypes = {
        fieldName: PropTypes.string,
        labelTitle: PropTypes.string,
        fieldDescription: PropTypes.node
    }

    const DefaultCheckboxFieldInputLabel = ({labelTitle, fieldDescription}) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '3px' }}>{labelTitle}</span>
                <Tooltip title={fieldDescription} sx={{fontSize: 1}} arrow>
                    <InfoOutlinedIcon
                        sx={{fontSize: 15,verticalAlign: "super"}}/>
                </Tooltip>
            </div>
        );
    }
    DefaultCheckboxFieldInputLabel.propTypes = {
        labelTitle: PropTypes.string,
        fieldDescription: PropTypes.node
    }

    return (
        <Paper id="address-input" variant={"elevation"} elevation={3} sx={{m: 3}}>
            <AppBar elevation={1} position="static"
                    sx={{color: "primary.contrastText", bgcolor: "primary.dark", borderRadius: "4px 4px 0 0"}}>
                <Typography variant={"h6"} sx={{m: 2}}>Address Input</Typography>
            </AppBar>
            <Box component={"form"} sx={{m: 2}} onSubmit={handleSubmit}>
                <Grid container spacing={1} justifyContent={"space-evenly"} alignContent={"center"}>
                    <Grid item xs={6}>
                        <TextField
                            id="company"
                            name="company"
                            value={localFormData.company}
                            variant="standard"
                            fullWidth
                            onChange={handleTextFieldChange}
                            onFocus={() => handleFocus('company')}
                            onBlur={() => handleBlur('company')}
                            label={<DefaultTextFieldInputLabel fieldName={'company'} labelTitle={'Business Name'} fieldDescription={addressInputHelpDescriptions.company}/>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="urbanization"
                            name="urbanization"
                            value={localFormData.urbanization}
                            variant="standard"
                            fullWidth
                            onChange={handleTextFieldChange}
                            onFocus={() => handleFocus('urbanization')}
                            onBlur={() => handleBlur('urbanization')}
                            label={<DefaultTextFieldInputLabel fieldName={'urbanization'} labelTitle={'Urbanization Code'} fieldDescription={addressInputHelpDescriptions.urbanization}/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="street"
                            name="street"
                            value={localFormData.street}
                            variant="standard"
                            fullWidth
                            onChange={handleTextFieldChange}
                            onFocus={() => handleFocus('street')}
                            onBlur={() => handleBlur('street')}
                            label={<DefaultTextFieldInputLabel fieldName={'street'} labelTitle={'Address Line 1'} fieldDescription={addressInputHelpDescriptions.street}/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="street2"
                            name="street2"
                            value={localFormData.street2}
                            variant="standard"
                            fullWidth
                            onChange={handleTextFieldChange}
                            onFocus={() => handleFocus('street2')}
                            onBlur={() => handleBlur('street2')}
                            label={<DefaultTextFieldInputLabel fieldName={'street2'} labelTitle={'Address Line 2'} fieldDescription={addressInputHelpDescriptions.street2}/>}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            id="city"
                            name="city"
                            value={localFormData.city}
                            variant="standard"
                            fullWidth
                            onChange={handleTextFieldChange}
                            onFocus={() => handleFocus('city')}
                            onBlur={() => handleBlur('city')}
                            label={<DefaultTextFieldInputLabel fieldName={'city'} labelTitle={'City'} fieldDescription={addressInputHelpDescriptions.city}/>}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Autocomplete
                            autoHighlight
                            autoSelect
                            id="state"
                            options={validStates}
                            isOptionEqualToValue={(option, value) => option.abbreviation.toLowerCase() === value.toLowerCase()}
                            getOptionLabel={(option) => (typeof option === 'object' ? option.name : option || '')}
                            value={validStates.find((state) => state.abbreviation.toLowerCase() === localFormData.state.toLowerCase()) ? validStates.find((state) => state.abbreviation.toLowerCase() === localFormData.state.toLowerCase()).name : ''}
                            filterOptions={filterOptions}
                            onChange={(_event, newValue) => {
                                let selectedValue;
                                if (newValue) {
                                    if (typeof newValue === 'object') {
                                        selectedValue = newValue.abbreviation;
                                    } else {
                                        selectedValue = newValue;
                                    }
                                } else {
                                    selectedValue = '';
                                }

                                handleTextFieldChange({target: {name: 'state', value: selectedValue}});
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={<DefaultTextFieldInputLabel fieldName={'state'} labelTitle={'State'} fieldDescription={addressInputHelpDescriptions.state}/>}
                                    variant="standard"
                                    fullWidth
                                    onFocus={() => handleFocus('state')}
                                    onBlur={() => handleBlur('state')}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            id="zip"
                            name="zip"
                            value={localFormData.zipPlusFour ? `${localFormData.zip}-${localFormData.zipPlusFour}` : `${localFormData.zip}`}
                            variant="standard"
                            fullWidth
                            error={inputErrors.zip}
                            helperText={inputErrors.zip ? 'Please enter a valid zip code' : ''}
                            onChange={handleZipFieldChange}
                            onFocus={() => handleFocus('zip')}
                            onBlur={() => handleBlur('zip')}
                            inputProps={{maxLength: 10}}
                            label={<DefaultTextFieldInputLabel fieldName={'zip'} labelTitle={'Zip'} fieldDescription={addressInputHelpDescriptions.zip}/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Accordion sx={{boxShadow: 'none', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))'}}>
                                <Tooltip title="These settings will always default to what was used on your previous search." placement="right" arrow>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{m: 0, p: 0}}/>}
                                                  aria-controls="panel2-content" id="panel2-header"
                                                  sx={{p: 0, display: "inline-flex", minHeight: '24px'}}>
                                    <Typography sx={{color: "primary.main"}}>Advanced Options</Typography>
                                </AccordionSummary>
                                </Tooltip>
                                <AccordionDetails sx={{p: 0}}>
                                    <FormGroup row sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: 'flex-start'
                                    }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={localFormData.useMixedCase}
                                                    name="useMixedCase"
                                                    onChange={handleCheckboxChange}
                                                />
                                            }
                                            label={<DefaultCheckboxFieldInputLabel labelTitle={'Use Mixed Case'} fieldDescription={advancedOptionsHelpDescriptions.useMixedCase}/>}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={localFormData.retainAddressLocation}
                                                    name="retainAddressLocation"
                                                    onChange={handleCheckboxChange}
                                                />
                                            }
                                            label={<DefaultCheckboxFieldInputLabel labelTitle={'Retain Address Location'} fieldDescription={advancedOptionsHelpDescriptions.retainAddressLocation}/>}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={localFormData.suiteLinkToAddress2}
                                                    name="suiteLinkToAddress2"
                                                    onChange={handleCheckboxChange}
                                                />
                                            }
                                            label={<DefaultCheckboxFieldInputLabel labelTitle={'SuiteLink to Address2'} fieldDescription={advancedOptionsHelpDescriptions.suiteLinkToAddress2}/>}
                                        />
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Button data-testid="clear-button" onClick={handleClearTextField} disabled={areObjectStringValuesEmpty(localFormData)}
                                variant="contained" color="secondary" fullWidth>
                            Clear
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button data-testid="submit-button" disabled={areObjectStringValuesEmpty(localFormData) || inputErrors.zip} type="submit"
                                variant="contained" color="primary" fullWidth>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Paper>

    )
}
AddressInput.propTypes = {
    formData: FormDataPropTypes,
    setSearchParams: PropTypes.func
};

export default AddressInput;