import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, IconButton, Stack, Typography, Box } from '@mui/material';
import { useUnit } from 'effector-react';
import CampaignVersionsFilters from './CampaignVersionsFilters';
import CampaignVersionsTable from './CampaignVersionsTable';
import { $$campaignVersionsDialog } from './model';

/** @typedef {import('@mui/x-data-grid-pro').GridRowId} GridRowId */

/**
 * CampaignVersionsDialog
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function CampaignVersionsDialog() {
  const campaign = useUnit($$campaignVersionsDialog.$campaign);
  const open = useUnit($$campaignVersionsDialog.$isOpen);
  const hasUnsavedChanges = useUnit($$campaignVersionsDialog.$$versions.$unsavedChanges.map(changes => changes.length > 0));

  const handleDialogClose = () => {
    setTimeout(() => {
      if (hasUnsavedChanges) {
        $$campaignVersionsDialog.openUnsavedCloseConfirmation({ saveAll: () => $$campaignVersionsDialog.saveAllClicked(), discardChanges: () => $$campaignVersionsDialog.discardClicked() });
      } else {
        $$campaignVersionsDialog.close();
      }
    }, 0);
  };

  return (
    <Dialog id="campaign-versions-dialog" data-testId="campaign-versions-dialog" open={open} onClose={handleDialogClose} fullWidth={true} maxWidth="lg" disableEnforceFocus>
      <Stack sx={{ p: 2, pb: 0 }}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', flex: 1, padding: 0 }}>
          <Typography variant="h4">Edit Versions</Typography>
          <IconButton onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Typography sx={{ mb: 2 }}>{campaign?.name}</Typography>
        <CampaignVersionsFilters hasUnsavedChanges={hasUnsavedChanges} />
      </Stack>
      <Typography data-testId="note" variant="body2" sx={{ p: 2, fontStyle: 'italic', color: 'sec' }}>
        {`Note: If using the 'select all' option, changes will apply only to the rows visible on this page. To edit rows on other pages, please navigate to the respective page.`}
      </Typography>
      <Box sx={{ height: '50vh' }}>
        <CampaignVersionsTable />
      </Box>
    </Dialog>
  );
}
