import { AlertProps, AlertTitle, Alert as MuiAlert } from '@mui/material';

export const alertSeverities = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

export const alertVariants = {
  STANDARD: 'standard',
  OUTLINED: 'outlined',
  FILLED: 'filled'
};

export default function Alert(props: AlertProps) {
  const { children, variant, title, sx, ...overrides } = props;

  return (
    <MuiAlert
      variant={variant}
      sx={{
        ...(variant === alertVariants.OUTLINED && {
          bgcolor: 'background.paper'
        }),
        ...sx
      }}
      {...overrides}
    >
      {title ? <AlertTitle>{title}</AlertTitle> : null}
      {children}
    </MuiAlert>
  );
}
