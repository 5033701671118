// @ts-ignore
import { Autocomplete, textFieldVariants } from '@ghs/components';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useUnit } from 'effector-react';
import { DataGridStylesWithoutSelectionOverride } from '../../../util/DataGridStyles';
import { $$adminDataAccessPage } from './model';

/**
 * LinesOfBusinessTable
 *
 * @returns {React.ReactNode} LinesOfBusinessTable
 */
export default function LinesOfBusinessTable() {
  const dataGridProps = $$adminDataAccessPage.$$lobs.useDataGrid();
  const selectedLobId = useUnit($$adminDataAccessPage.$selectedFilterLobId);

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Reseller.LineOfBusiness>[]} */
  const customerTableColumns = [{ field: 'name', headerName: 'Line of Business', minWidth: 160, flex: 6 }];

  return (
    <>
      <Autocomplete
        id="admin-data-access-lobs-autocomplete"
        label="Search Line of Business"
        placeholder="Select"
        options={dataGridProps.rows.map(row => ({ id: row.id, label: row.name }))}
        onChange={(_, value) => $$adminDataAccessPage.filterLobId(value?.id ?? null)}
        variant={textFieldVariants.OUTLINED}
        fullWidth
        sx={{ mb: 2 }}
      />
      <DataGridPro
        data-testid="admin-data-access-lobs-datagrid"
        {...dataGridProps}
        rows={dataGridProps.rows.filter(row => !selectedLobId || row.id === selectedLobId)}
        autoHeight
        columns={customerTableColumns}
        editMode="row"
        getRowId={row => row.id}
        onRowClick={({ id }) => $$adminDataAccessPage.selectLobId(Number(id))}
        initialState={{
          columns: { columnVisibilityModel: { id: false } },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pagination
        pageSizeOptions={[5, 10]}
        autosizeOnMount={false} // vitest is failing beause of this prop
        autosizeOptions={{
          includeOutliers: true,
          includeHeaders: true
        }}
        sx={{
          ...DataGridStylesWithoutSelectionOverride,
          '.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--moving.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeader--last': { padding: 0 },
          '.MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%'
          }
        }}
      />
    </>
  );
}
