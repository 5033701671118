import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { TreeView as MuiTreeView, TreeItem } from '@mui/x-tree-view';
import PropTypes from 'prop-types';

const TreeView = ({
  children,
  selected,
  expanded,
  onNodeToggle,
  onNodeSelect,
  ...overrides
}) => (
  <Box sx={{ flexGrow: 1 }}>
    <MuiTreeView
      aria-label="rich object"
      selected={selected}
      expanded={expanded}
      onNodeToggle={onNodeToggle}
      onNodeSelect={onNodeSelect}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: 'auto', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
      {...overrides}
    >
      {children}
    </MuiTreeView>
  </Box>
);

TreeView.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.array,
  expanded: PropTypes.array,
  onNodeToggle: PropTypes.func,
  onNodeSelect: PropTypes.func
};

export { TreeItem };

export default TreeView;
