import { createDialog } from '../../util/createDialog';

const links = {
  ['ST_ADMIN:MANAGE_USERS_DATA_ACCESS']:
    'https://24420982.fs1.hubspotusercontent-na1.net/hubfs/24420982/GCP%20Instructional%20Videos/ST%20Migration%20to%20GCP%20-%20Manage%20Users%20&%20Data%20Access.mp4',
  ['ST_ADMIN:ROLES_AND_PERMISSIONS']: 'https://24420982.fs1.hubspotusercontent-na1.net/hubfs/24420982/GCP%20Instructional%20Videos/ST%20Migration%20to%20GCP%20-%20Roles%20&%20Permissions.mp4',
  ['ST_ADMIN:EDIT_CAMPAIGNS_VERSIONS']:
    'https://24420982.fs1.hubspotusercontent-na1.net/hubfs/24420982/GCP%20Instructional%20Videos/ST%20Migration%20to%20GCP%20-%20Edit%20Campaigns%20%20&%20Versions.mp4',
  ['ST_ADMIN:IN_HOME_WINDOWS']: 'https://24420982.fs1.hubspotusercontent-na1.net/hubfs/24420982/GCP%20Instructional%20Videos/ST%20Migration%20to%20GCP%20-%20In-Home%20Windows.mp4',
  ['ST_ADMIN:EDIT_CUSTOMER_LOB']: 'https://24420982.fs1.hubspotusercontent-na1.net/hubfs/24420982/GCP%20Instructional%20Videos/ST%20Migration%20to%20GCP%20-%20Edit%20CustomerLOB.mp4',
  ['ST_ADMIN:LOGOS:RESELLER_PERSONA']: 'https://24420982.fs1.hubspotusercontent-na1.net/hubfs/24420982/GCP%20Instructional%20Videos/ST%20Migration%20to%20GCP%20-%20Logos%20-%20Reseller.mp4',
  ['ST_ADMIN:LOGOS:CUSTOMER_PERSONA']: 'https://24420982.fs1.hubspotusercontent-na1.net/hubfs/24420982/GCP%20Instructional%20Videos/ST%20Migration%20to%20GCP%20-%20Logos%20-%20Customer.mp4',
  ['SPL:MT_PERSONA']: 'https://24420982.fs1.hubspotusercontent-na1.net/hubfs/24420982/GCP%20Instructional%20Videos/ST%20Migration%20to%20GCP%20-%20SPL%20-%20MT%20usersV2.mp4',
  ['SPL:ST_PERSONA']: 'https://24420982.fs1.hubspotusercontent-na1.net/hubfs/24420982/GCP%20Instructional%20Videos/ST%20Migration%20to%20GCP%20-%20SPL%20-%20ST%20users-v2.mp4',
  ['ADDRESS_VERIFICATION']: 'https://24420982.fs1.hubspotusercontent-na1.net/hubfs/24420982/GCP%20Instructional%20Videos/ST%20Migration%20to%20GCP%20-%20Address%20Verification.mp4'
} as const;

const $$dialog = createDialog<{ url: (typeof links)[keyof typeof links] }>();

export const $$tutorialVideos = {
  ...$$dialog,

  links
};
