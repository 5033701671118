import { Alert as MuiAlert, Snackbar } from '@mui/material';
import { useUnit } from 'effector-react';
import PropTypes from 'prop-types';
import { $$snackbar } from './model';

/**
 * SnackbarRoot
 *
 * @param {object} props - props
 * @param {object} props.snackbarProps - snackbarProps
 * @returns {React.ReactNode} SnackbarRoot component
 */
export default function SnackbarRoot(props) {
  const { snackbarProps } = props;

  const open = useUnit($$snackbar.$isOpen);
  const state = useUnit($$snackbar.$state);

  if (!state) {
    return null;
  }

  const { message, severity, autoHideDuration } = state;

  // Use specific autohide duration if defined. If not, use the default value from the SnackBar props
   
  const { autoHideDuration: _, sx, ...restSnackbarProps } = snackbarProps;
  const snackbarAutoHideDuration = autoHideDuration ?? snackbarProps.autoHideDuration;

  return (
    <Snackbar open={open}
              onClose={() => $$snackbar.close()}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              autoHideDuration={snackbarAutoHideDuration}
              sx={{
                maxWidth: '500px',
                ...sx
              }}
              {...restSnackbarProps}>
      <MuiAlert onClose={() => $$snackbar.close()} severity={severity} variant="filled">
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

SnackbarRoot.propTypes = {
  snackbarProps: PropTypes.object
};
