import { AxiosError } from 'axios';
import { createEffect, createStore, sample } from 'effector';
import { omit } from 'lodash';
import { $$infoDialog } from '../../components/InfoDialog/model';
import { emailRegex, mergeByMultipleKeys } from '../../util/JsonUtils';
import { ghApi } from '../DataService';

const CAMPAIGNS_TO_PERSIST = 100;

const $inHomeWindowRanges = createStore(/** @type {(Parameters<typeof getInHomeWindowsDetailFx>[0] & Awaited<ReturnType<typeof getInHomeWindowsDetailFx>>)[]} */ ([]));

const $campaigns = createStore(/** @type {Reseller.Campaign[]} */ ([]));

/** @type {import('effector').Effect<Partial<{ search: string; startDate: string; endDate: string }>, Reseller.Customer[]>} */
const getCustomersFx = createEffect(async params => {
  return ghApi.get('/customers', { params }).then(response => response.data);
});

/** @type {import('effector').Effect<Partial<{ startDate: string; endDate: string }>, Reseller.CustomerListItem[]>} */
const getCustomerListFx = createEffect(async params => {
  return ghApi.get('/customers/list', { params }).then(response => response.data);
});

/** @type {import('effector').Effect<Reseller.Customer, unknown>} */
const updateCustomerFx = createEffect(async ({ id, ...data }) => {
  return ghApi.put(`/customers/${id}`, data).then(response => response.data);
});

/** @type {import('effector').Effect<Reseller.Customer['id'], unknown>} */
const deleteCustomerFx = createEffect(async id => {
  return ghApi.delete(`/customers/${id}`).then(response => response.data);
});

/** @type {import('effector').Effect<{ customerId: unknown } & Partial<{ size: number; page: number; sort: string }>, PaginatedResponse<Reseller.User>>} */
const getCustomersUsersFx = createEffect(async ({ customerId, ...params }) => {
  return ghApi.get(`/customers/${customerId}/users`, { params }).then(response => response.data);
});

/** @type {import('effector').Effect<{ customerId: unknown; data: { email: string } }, Reseller.User[]>} */
const addCustomersUserFx = createEffect(async ({ customerId, data }) => {
  if (!emailRegex.test(data.email)) {
    throw new Error('Invalid email');
  }
  return ghApi.post(`/customers/${customerId}/users`, data).then(response => response.data);
});

/** @type {import('effector').Effect<{ customerId: unknown; data: { email: string }[] }, Reseller.User[]>} */
const addCustomersUsersFx = createEffect(async ({ customerId, data }) => {
  if (!data.every(({ email }) => emailRegex.test(email))) {
    throw new Error('Invalid email');
  }
  return ghApi.post(`/customers/${customerId}/users`, data).then(response => response.data);
});

/** @type {import('effector').Effect<{ customerId: unknown; data: Pick<Reseller.User, 'firstName' | 'lastName' | 'email'> & { userId: Reseller.User['id'] } }, Reseller.User[]>} */
const editCustomersUserFx = createEffect(async ({ customerId, data }) => {
  if (!emailRegex.test(data.email)) {
    throw new Error('Invalid email');
  }
  return ghApi.put(`/customers/${customerId}/user`, data).then(response => response.data);
});

/** @type {import('effector').Effect<{ customerId: unknown; data: { userId: string } }, Reseller.User[]>} */
const deleteCustomersUserFx = createEffect(async ({ customerId, data }) => {
  return ghApi.delete(`/customers/${customerId}/user`, { data }).then(response => response.data);
});

/** @type {import('effector').Effect<{ customerId: unknown; data: { userId: string }[] }, Reseller.User[]>} */
const deleteCustomersUsersFx = createEffect(async ({ customerId, data }) => {
  return ghApi.delete(`/customers/${customerId}/users`, { data }).then(response => response.data);
});

/** @type {import('effector').Effect<Partial<{ search: string; startDate: string; endDate: string; customerIds: Reseller.Customer['id'][] }>, Reseller.LineOfBusiness[]>} */
const getLineOfBusinessFx = createEffect(async params => {
  return ghApi.get('/linesOfBusiness', { params }).then(response => response.data);
});

/** @type {import('effector').Effect<Partial<{ startDate: string; endDate: string; customerIds: Reseller.Customer['id'][] }>, Reseller.LineOfBusinessListItem[]>} */
const getLineOfBusinessListFx = createEffect(async params => {
  return ghApi.get('/linesOfBusiness/list', { params }).then(response => response.data);
});

/** @type {import('effector').Effect<Partial<Reseller.Customer>, unknown>} */
const updateLineOfBusinessFx = createEffect(async ({ id, ...data }) => {
  return ghApi.put(`/linesOfBusiness/${id}`, data).then(response => response.data);
});

/** @type {import('effector').Effect<Reseller.LineOfBusiness['id'], unknown>} */
const deleteLineOfBusinessFx = createEffect(async id => {
  return ghApi.delete(`/linesOfBusiness/${id}`).then(response => response.data);
});

/** @type {import('effector').Effect<{ lineOfBusinessId: Reseller.LineOfBusiness['id'] } & Partial<{ size: number; page: number; sort: string }>, PaginatedResponse<Reseller.User>>} */
const getLobUsersFx = createEffect(async ({ lineOfBusinessId, ...params }) => {
  return ghApi.get(`/linesOfBusiness/${lineOfBusinessId}/users`, { params }).then(response => response.data);
});

/** @type {import('effector').Effect<{ lineOfBusinessId: Reseller.LineOfBusiness['id']; data: Pick<Reseller.User, 'email'> & Partial<Pick<Reseller.User, | 'firstName' | 'lastName'> & { customerId: Reseller.LineOfBusiness['customerId'] }> }, Reseller.User[]>} */
const addLobUserFx = createEffect(async ({ lineOfBusinessId, data }) => {
  if (!emailRegex.test(data.email)) {
    throw new Error('Invalid email');
  }
  return ghApi.post(`/linesOfBusiness/${lineOfBusinessId}/users`, data).then(response => response.data);
});

/** @type {import('effector').Effect<{ lineOfBusinessId: Reseller.LineOfBusiness['id']; data: Pick<Reseller.User, 'email'> & Partial<Pick<Reseller.User, | 'firstName' | 'lastName'> & { customerId: Reseller.LineOfBusiness['customerId'] }>[] }, Reseller.User[]>} */
const addLobUsersFx = createEffect(async ({ lineOfBusinessId, data }) => {
  if (!data.every(({ email }) => emailRegex.test(email))) {
    throw new Error('Invalid email');
  }
  return ghApi.post(`/linesOfBusiness/${lineOfBusinessId}/users`, data).then(response => response.data);
});

/** @type {import('effector').Effect<{ lineOfBusinessId: Reseller.LineOfBusiness['id']; data: Pick<Reseller.User, 'firstName' | 'lastName' | 'email'> & { userId: Reseller.User['id'] } }, Reseller.User[]>} */
const editLobUserFx = createEffect(async ({ lineOfBusinessId, data }) => {
  if (!emailRegex.test(data.email)) {
    throw new Error('Invalid email');
  }
  return ghApi.put(`/linesOfBusiness/${lineOfBusinessId}/user`, data).then(response => response.data);
});

/** @type {import('effector').Effect<{ lineOfBusinessId: Reseller.LineOfBusiness['id']; data: { userId: string } }, Reseller.User[]>} */
const deleteLobUserFx = createEffect(async ({ lineOfBusinessId, data }) => {
  return ghApi.delete(`/linesOfBusiness/${lineOfBusinessId}/user`, { data }).then(response => response.data);
});

/** @type {import('effector').Effect<{ lineOfBusinessId: Reseller.LineOfBusiness['id']; data: { userId: string }[] }, Reseller.User[]>} */
const deleteLobUsersFx = createEffect(async ({ lineOfBusinessId, data }) => {
  return ghApi.delete(`/linesOfBusiness/${lineOfBusinessId}/users`, { data }).then(response => response.data);
});

/** @type {import('effector').Effect<string, boolean>} */
const validateUsersEmailFx = createEffect(async email => {
  return ghApi
    .get(`/resellers/users/personas/${email || '-'}`)
    .then(() => true)
    .catch(() => false);
});

const getResellerUsersFx = createEffect(async () => {
  return ghApi.get(`/resellers/users/export`).then(response => response.data);
});

/** @type {import('effector').Effect<Partial<{ customersIds: string; lineOfBusinessIds: string; campaignNumber: string; campaignName: string; mailDateStart: string; mailDateEnd: string; search: string; size: number; page: number; sort: string; persistResult?: boolean }>, PaginatedResponse<Reseller.Campaign>>} */
const getCampaignsFx = createEffect(async params => {
  return ghApi.get('/campaigns', { params: omit(params, ['persistResult']) }).then(response => response.data);
});

/** @type {import('effector').Effect<Partial<{ customersIds: string; lineOfBusinessIds: string; mailDateStart: string; mailDateEnd: string }>, Reseller.CampaignListItem[]>} */
const getCampaignListFx = createEffect(async params => {
  return ghApi.get('/campaigns/list', { params: omit(params, ['persistResult']) }).then(response => response.data);
});

/** @type {import('effector').Effect<Reseller.Campaign, Reseller.Campaign>} */
const updateCampaignsFx = createEffect(async data => {
  return ghApi.put(`/campaigns/${data.id}`, data).then(response => response.data);
});

/** @type {import('effector').Effect<Reseller.Campaign['id'], unknown>} */
const deleteCampaignsFx = createEffect(async id => {
  return ghApi.delete(`/campaigns/${id}`).then(response => response.data);
});

/** @type {import('effector').Effect<Partial<{ search: string; size: number; page: number; sort: string; campaignId: Reseller.Campaign['id'] }>, PaginatedResponse<Reseller.Version>>} */
const getVersionsFx = createEffect(async params => {
  return ghApi.get('/versions', { params }).then(response => response.data);
});

/** @type {import('effector').Effect<Pick<Reseller.Version, 'id' | 'campaignId' | 'name' | 'number'>, boolean>} */
const validateVersionNameFx = createEffect(async data => {
  return ghApi
    .post('/versions/validate', data)
    .then(() => true)
    .catch(() => false);
});

/** @type {import('effector').Effect<Reseller.Version[], PaginatedResponse<Reseller.Version>>} */
const updateVersionsFx = createEffect(async data => {
  return ghApi.put('/versions', data).then(response => response.data);
});

/** @type {import('effector').Effect<Reseller.Version['id'][], PaginatedResponse<Reseller.Version>>} */
const deleteVersionsFx = createEffect(async data => {
  return ghApi.delete('/versions', { data }).then(response => response.data);
});

const getInHomeWindowsFx = createEffect(async () => {
  return ghApi.get(`/resellers/inhomewindow`).then(response => response.data);
});

const deleteInHomeWindowFx = createEffect(async data => {
  return ghApi.delete('/resellers/inhomewindow', { data }).then(response => response.data);
});

const updateLobInHomeWindowFx = createEffect(async data => {
  return ghApi.put('/resellers/inhomewindow', data).then(response => response.data);
});

const updateCustomerInHomeWindowFx = createEffect(async data => {
  const { customerId, ...body } = data;
  return ghApi.put(`/resellers/inhomewindow/customer/${customerId}`, body).then(response => response.data);
});

const getDefaultInHomeWindowsFx = createEffect(async () => {
  return ghApi.get(`/resellers/inhomewindow/default`).then(response => response.data);
});

/** @type {import('effector').Effect<{ mailClassId: Reseller.Version['mailClass']['id']; customerId: Reseller.Campaign['customer']['id']; lineOfBusinessId: Reseller.LineOfBusiness['id'] }, Reseller.InHomeWindowRange>} */
const getInHomeWindowsDetailFx = createEffect(async params => {
  return ghApi.get(`/resellers/inhomewindow/detail`, { params }).then(response => response.data);
});

sample({
  source: $inHomeWindowRanges,
  clock: getInHomeWindowsDetailFx.done,
  fn: (ranges, { params, result }) => /** @type {typeof $inHomeWindowRanges['__']} */ (mergeByMultipleKeys(ranges, [{ ...result, ...params }], /** @type {keyof {}} */ (Object.keys(params)))),
  target: $inHomeWindowRanges
});

sample({
  clock: [addCustomersUserFx.fail, addLobUserFx.fail, editCustomersUserFx.fail, editLobUserFx.fail, deleteCustomersUserFx.fail, deleteLobUserFx.fail],
  filter: ({ error }) => error instanceof AxiosError && typeof error.response?.data.message === 'string',
  fn: ({ error }) => ({
    title: error.response?.data.error || 'Operation failed',
    content: error.response?.data.message ?? 'An error occurred while deleting the customer.'
  }),
  target: $$infoDialog.open
});

sample({
  source: $campaigns,
  clock: getCampaignsFx.done.filter({ fn: ({ params }) => params.persistResult === true }).map(({ result }) => result.content),
  fn: (storedCampaigns, receivedCampaigns) => [...storedCampaigns.filter(c => !receivedCampaigns.some(({ id }) => id === c.id)), ...receivedCampaigns].slice(-CAMPAIGNS_TO_PERSIST),
  target: $campaigns
});

export const $$reseller = {
  $inHomeWindowRanges,
  $campaigns,

  getCustomersFx,
  getCustomerListFx,
  updateCustomerFx,
  deleteCustomerFx,
  getCustomersUsersFx,
  addCustomersUserFx,
  addCustomersUsersFx,
  editCustomersUserFx,
  deleteCustomersUserFx,
  deleteCustomersUsersFx,

  getLineOfBusinessFx,
  getLineOfBusinessListFx,
  updateLineOfBusinessFx,
  deleteLineOfBusinessFx,
  getLobUsersFx,
  addLobUserFx,
  addLobUsersFx,
  editLobUserFx,
  deleteLobUserFx,
  deleteLobUsersFx,

  validateUsersEmailFx,
  getResellerUsersFx,

  getCampaignsFx,
  getCampaignListFx,
  updateCampaignsFx,
  deleteCampaignsFx,
  getVersionsFx,
  validateVersionNameFx,
  updateVersionsFx,
  deleteVersionsFx,
  getInHomeWindowsFx,
  deleteInHomeWindowFx,
  updateLobInHomeWindowFx,
  updateCustomerInHomeWindowFx,
  getDefaultInHomeWindowsFx,
  getInHomeWindowsDetailFx
};
