import { DataGrid, Skeleton } from '@ghs/components';
import { Stack, StackProps, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useUnit } from 'effector-react';
import { AcsEvent } from '../../services/SplService/types';
import { formatAddressCityStateZip } from '../../util/AddressUtils';
import { $$splResultsPage } from './model';

type AcsEventsProps = StackProps;

export default function AcsEvents(props: AcsEventsProps) {
  const { ...overrides } = props;

  const data = useUnit($$splResultsPage.$acsEvents);
  const isLoading = useUnit($$splResultsPage.$isAcsEventsFxPending);

  if (isLoading) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
      </Stack>
    );
  }

  if (!data.length) {
    return null;
  }

  return (
    <Stack {...overrides} data-testid="acs-events-container" spacing={2}>
      <Typography variant="h5" color="text.primary">
        ACS Events
      </Typography>
      <DataGrid
        columns={columns}
        rows={data}
        rowSelection={false}
        getRowHeight={() => 'auto'}
        // Restore padding due to using getRowHeight & disable cell selection outline
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          '& .MuiDataGrid-cell:focus': { outline: 'none' },
          '& .MuiDataGrid-cell:focus-within': { outline: 'none' }
        }}
      />
    </Stack>
  );
}

const columns: GridColDef<AcsEvent>[] = [
  { field: 'dateReceived', headerName: 'ACS Event Received', flex: 0.5 },
  { field: 'reason', headerName: 'Reason', flex: 0.5 },
  {
    field: 'newAddress',
    headerName: 'New Address',
    valueGetter: (_, row) => formatAddressCityStateZip(row.newAddress, row.newCity, row.newState, `${row.new5Zip}${row.newPlus4}`),
    flex: 1
  }
];
