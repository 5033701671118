import * as React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Button, Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, AppBar as MuiAppBar, Drawer as MuiDrawer, Toolbar, Typography, Collapse, ListItemIcon } from '@mui/material'
import { Avatar } from "@ghs/components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PropTypes from "prop-types";
import BannerContainer from "./BannerContainer";
import { useIdleTimer } from "react-idle-timer";
import useAuth0WithErrorHandling from "../hooks/useAuth0WithErrorHandling";
import { useAuth0 } from "@auth0/auth0-react";
import { SessionTimeoutDialog, convertSecondsToMinutesAndSeconds } from "./SessionTimeoutDialog";
import BiDashboard from "../pages/BiDashboard";
import { SisenseInfoPropTypes } from "../services/BiService.js";
import { useUnit } from "effector-react";
import { $$logo } from "../services/LogoService.js";
import {UserContext} from "../context/UserContext";
import {UserMenu} from "./UserMenu"
import { mapValues } from "lodash";

/**
 * @typedef {import('../services/BiService.js').DashboardView} DashboardView
 * @typedef {import('../services/BiService.js').SisenseInfo} SisenseInfo
 * @typedef {import('../services/PermissionsService.js').Permission} Permission
 * @typedef {{
 *   name: string;
 *   path: string;
 *   icon: Element;
 *   exclude?: boolean | ((permissions: Permission[], sisenseInfo?: DashboardView[] | null) => boolean);
 *   showBanners?: boolean;
 * }[]} Page
 */

const drawerWidth = 255;
const timeout = import.meta.env.VITE_SESSION_TIMEOUT * 1000;
const promptBeforeIdle = import.meta.env.VITE_SESSION_TIMEOUT_WARNING * 1000;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

// Memoize the BiDashboard component and ignore prop updates to prevent rendering every time the layout renders
const MemoizedBiDashboard = React.memo(BiDashboard, () => true);

/**
 * Layout component for the main layout for this application.
 *
 * @param {object} props props for the component
 * @param {Page[]} props.pages array of objects describing the pages in the application to list on the sidebar
 * @param {SisenseInfo[]} props.sisenseInfo Dashboards info
 * @param {Function} props.handlePersonaChange handler to change the user persona
 * @returns {React.ReactNode} Layout component
 */
const Layout = ({ pages, sisenseInfo, handlePersonaChange }) => {
    const primaryLogoUrl = useUnit($$logo.$primaryLogo);
    const secondaryLogoUrl = useUnit($$logo.$secondaryLogo);
    const theme = useTheme();
    const location = useLocation();
    const [open, setOpen] = React.useState(localStorage.getItem('ghs-drawer-open') ? localStorage.getItem('ghs-drawer-open')?.toLowerCase() === 'true' : true);
    const navigate = useNavigate();
    const [avatarEl, setAvatarEl] = React.useState(null);
    const [timeoutDialogOpen, setTimeoutDialogOpen] = React.useState(false);
    const [inactivityTimer, setInactivityTimer] = React.useState(timeout);
    const { getAccessTokenSilently } = useAuth0WithErrorHandling();
    const sisenseContainerElement = React.createRef();
    const { user } = useAuth0();
    const userDetails = React.useContext(UserContext);
    const [menuItemsOpen, setMenuItemsOpen] = React.useState(
        pages
            .filter(page => !page.subPages)
            .reduce((acc, page) => {
            acc[page.name] = false;
            return acc;
        }, {})
    );

    const handleMenuItemClick = (page) => {
        if (page.subPages) {
            handleSubMenuItemClick(page.name);
        } else if (page.isExternalLink) {
            window.open(page.path, '_blank').focus();
        } else {
            navigate(page.path)
        }
    };

    const handleSubMenuItemClick = (pageName) => {
        // Open drawer if a submenu item is clicked
        if (!open) {
            handleDrawerOpen();
        }
        setMenuItemsOpen(prevState => ({
            ...prevState,
            [pageName]: !prevState[pageName]
        }));
    };

    const handleDrawerOpen = () => {
        setOpen(true);
        localStorage.setItem('ghs-drawer-open', 'true');
    };

    const handleDrawerClose = () => {
        // Close submenus when the drawer closes
        setMenuItemsOpen(prevState => mapValues(prevState, () => false));
        setOpen(false);
        localStorage.setItem('ghs-drawer-open', 'false');
    };

    const handleAvatarClick = (e) => {
        setAvatarEl(e.currentTarget);
    };

    const handleAvatarClose = () => {
        setAvatarEl(null);
    };

    const getUserInitials = () => {
        if (user?.given_name && user?.family_name) {
            return `${user.given_name.charAt(0)}${user.family_name.charAt(0)}`.toUpperCase();
        } else if (user?.name) {
            return user.name.charAt(0).toUpperCase();
        }
        return '';
    };

    const stringToColour = (str) => {
        let hash = 0;
        str.split('').forEach(char => {
            hash = char.charCodeAt(0) + ((hash << 5) - hash);
        });
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            colour += value.toString(16).padStart(2, '0');
        }
        return colour;
    }

    const onIdle = () => {
        setTimeoutDialogOpen(false)
        navigate('/logout');
    }

    const onActive = () => {
        setTimeoutDialogOpen(false)
    }

    const onPrompt = () => {
        setTimeoutDialogOpen(true)
    }

    //Callback when receive message from another idle timer instance
    const onMessage = ()  => {
        activate();
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        onMessage,
        timeout,
        promptBeforeIdle,
        throttle: 5000,
        crossTab: true,
        syncTimers: 500
    });

    React.useEffect(() => {
        const interval = setInterval(() => {
            setInactivityTimer(Math.floor(getRemainingTime() / 1000))
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    });

    return (
        <Box sx={{display: 'flex', minHeight: '100vh'}}>
            <AppBar color="secondary" enableColorOnDark position="fixed" open={open}>
                <Toolbar sx={{px: 1.5, bgcolor: 'primary.dark'}}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Box sx={{ height: '50px', maxWidth: '240px', pt: 1, pb: 1 }}>
                        {React.useMemo(() => <img src={primaryLogoUrl} alt="GrayHair Logo" style={{ width: '100%', height: '100%', objectFit: 'contain', }} />, [primaryLogoUrl])}
                    </Box>
                    <Box sx={{ height: '50px', maxWidth: '240px', pt: 1, pb: 1, ml: 2 }}>
                        {
                            React.useMemo(() => {
                                return secondaryLogoUrl ? <img src={secondaryLogoUrl} alt="GrayHair Logo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                  : <Typography variant="h6" noWrap sx={{ position: 'relative', top: '50%', transform: 'translateY(-50%)'}}>Client Portal</Typography>;
                            }, [secondaryLogoUrl])
                        }
                    </Box>
                    <Box sx={{ flexGrow: 1}}/>
                    {/* Countdown that doesn't render in production environments. When it completes it will show the session timeout dialog. */}
                    <Box sx={{...(import.meta.env.VITE_SHOW_SESSION_TIMER !== 'true' && { display: 'none' })}}>{convertSecondsToMinutesAndSeconds(Math.max(inactivityTimer - promptBeforeIdle / 1000, 0))}</Box>
                    <Button onClick={handleAvatarClick}>
                        {/* Use picture if available. Otherwise, generate avatar with initials and generated color. */}
                        <Avatar src={user?.picture} sx={{ bgcolor: stringToColour(getUserInitials())}} alt="User Profile Avatar">{getUserInitials()}</Avatar>
                    </Button>
                </Toolbar>
            </AppBar>
            <UserMenu
                anchorEl={avatarEl}
                user={user}
                handleClose={handleAvatarClose}
                handlePersonaChange={handlePersonaChange}
            />
            <Drawer variant="permanent" open={open}
                    PaperProps={{sx: {backgroundColor: theme.palette.background.default}}}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    {pages.map((page) => (page.exclude instanceof Function ? !page.exclude(userDetails.permissions, sisenseInfo) : !page.exclude) && (
                        <ListItem key={page.name} disablePadding sx={{display: 'block', ...(location.pathname === page.path && { backgroundColor: 'action.selected' })}}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                onClick={() => handleMenuItemClick(page)}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {page.icon}
                                </ListItemIcon>
                                <ListItemText primary={page.name}
                                              primaryTypographyProps={{fontSize: theme.typography.fontSize}}
                                              sx={{opacity: open ? 1 : 0}}/>
                                { page.subPages && (menuItemsOpen[page.name] ? <ExpandLess /> : <ExpandMore />)}
                            </ListItemButton>
                            {page.subPages?.map((subPage, index) => (subPage.exclude instanceof Function ? !subPage.exclude(userDetails.permissions, sisenseInfo) : !subPage.exclude) && (
                                <Collapse key={`subPage-${index}`} in={menuItemsOpen[page.name]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton
                                                        sx={{
                                                            minHeight: 48,
                                                            justifyContent: menuItemsOpen[page.name] ? 'initial' : 'center',
                                                            px: 2.5,
                                                            pl: 4
                                                        }}
                                                        onClick={() => navigate(subPage.path)}>
                                            <ListItemText primary={subPage.name}
                                                          primaryTypographyProps={{fontSize: theme.typography.fontSize}}
                                                          sx={{opacity: menuItemsOpen[page.name] ? 1 : 0}}/>
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            ))}
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{flex: 1, overflow: 'hidden', p: 3, backgroundColor: theme.palette.background.paper}}
                 id='outlet-box'>
                <DrawerHeader/>
                <BannerContainer/>
                <Box data-testid="home-dashboard-container" sx={{ display: location.pathname === "/" ? 'block' : 'none' }}>
                <MemoizedBiDashboard
                    key={sisenseInfo?.reloadCount || 1}
                    dashboardId={sisenseInfo?.defaultDashboardId}
                    unmountShouldDestroyFrame={false}
                    unmountShouldUnloadEmbedSdk={false}
                    containerElement={sisenseContainerElement}
                    containerElementId="home-dashboard-container"
                    permissions={userDetails.permissions}
                />
                </Box>
                <Outlet/>
            </Box>
            <SessionTimeoutDialog
                open={timeoutDialogOpen}
                onClose={async () => {
                    setTimeoutDialogOpen(false);
                    activate();
                    // Trigger a refresh of the access token
                    await getAccessTokenSilently({cacheMode: 'off'});
                }}
                logoutFn={() => {
                    setTimeoutDialogOpen(false);
                    navigate("/logout");
                }}
                secondsToLogout={inactivityTimer} />
        </Box>
    );
}

Layout.propTypes = {
    pages: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        path: PropTypes.string,
        icon: PropTypes.element,
        exclude: PropTypes.any,
        showBanners: PropTypes.bool,
        subPages: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            icon: PropTypes.element,
            exclude: PropTypes.any,
            showBanners: PropTypes.bool
        })),
        isExternalLink: PropTypes.bool
    })),
    sisenseInfo: SisenseInfoPropTypes,
    handlePersonaChange: PropTypes.func
};

export default Layout;