import { Button, TextField, ThemeProvider } from '@ghs/components';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import BiThumbnailCard from '../../../components/BiThumbnailCard';
import GrayhairDropZone from '../../../components/GrayhairDropzone';
import { $$theme } from '../../../services/ThemeService/model';
import { downscaleImage } from '../../../util/ThumbnailsUtils';

/**
 * @typedef {import('../../../services/BiService.js').DashboardView} DashboardView
 */

/**
 * Component for editing the thumbnails of a dashboard.
 *
 * @param {{
 *   open: boolean;
 *   dashboard: DashboardView | null;
 *   handleClose: () => void;
 *   handleSave: (data: Pick<DashboardView, 'id' | 'description'> & { thumbnailLightBase64: string; thumbnailDarkBase64: string }) => void;
 *   isUpdateMetadataLoading: boolean;
 * }} props - The props of the component.
 * @returns {React.ReactNode} AdminThumbnailsEditDialog component
 * @class
 */
export default function AdminThumbnailsEditDialog(props) {
  const { open, handleClose, handleSave, dashboard, isUpdateMetadataLoading } = props;

  const palettes = useUnit($$theme.$themePalettes);

  const [description, setDescription] = useState('');
  const [thumbnailLightObj, setThumbnailLightObj] = useState(null);
  const [thumbnailDarkObj, setThumbnailDarkObj] = useState(null);

  useEffect(() => {
    if (dashboard) {
      setDescription(dashboard.description);
      setThumbnailLightObj({ previewURL: dashboard.thumbnailLight });
      setThumbnailDarkObj({ previewURL: dashboard.thumbnailDark });
    } else {
      setDescription('');
      setThumbnailLightObj(null);
      setThumbnailDarkObj(null);
    }
  }, [dashboard]);

  if (!dashboard) {
    return null;
  }

  const cardProps = {
    ...dashboard,
    description,
    thumbnailLight: thumbnailLightObj?.previewURL,
    thumbnailDark: thumbnailDarkObj?.previewURL
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <span>{dashboard.title}</span>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          (ID: {dashboard.id})
        </Typography>
      </DialogTitle>
      <DialogContent component="form" id="banner-form" sx={{ px: 3, py: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField name="description" label="Description" multiline maxRows={4} value={description} onChange={e => setDescription(e.target.value)} disabled={isUpdateMetadataLoading} />
        <Stack direction="row" justifyContent="center" sx={{ display: 'flex', gap: 2 }}>
          <Box display="flex" flexDirection="column" flex="1">
            <Typography>Light theme</Typography>
            <GrayhairDropZone
              inputDataTestId="light-input"
              maxFiles={1}
              accept={{ 'image/jpeg': [], 'image/png': [] }}
              label="Select thumbnail for light theme"
              onDropAcceptedExtended={d => downscaleImage(d[0].previewURL).then(downsampled => setThumbnailLightObj({ ...d[0], ...downsampled }))}
              withPreview={false}
            />
            <ThemeProvider theme={palettes.light}>
              <BiThumbnailCard key={dashboard.id + '-card'} {...cardProps} theme="light" />
            </ThemeProvider>
          </Box>
          <Box display="flex" flexDirection="column" flex="1">
            <Typography>Dark theme</Typography>
            <GrayhairDropZone
              inputDataTestId="dark-input"
              maxFiles={1}
              accept={{ 'image/jpeg': [], 'image/png': [] }}
              label="Select thumbnail for dark theme"
              onDropAcceptedExtended={d => downscaleImage(d[0].previewURL).then(downsampled => setThumbnailDarkObj({ ...d[0], ...downsampled }))}
              withPreview={false}
            />
            <ThemeProvider theme={palettes.dark}>
              <BiThumbnailCard key={dashboard.id + '-card'} {...cardProps} theme="dark" />
            </ThemeProvider>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={() => handleClose()} variant="plain" sx={{ mt: 1, ml: 1 }} disabled={isUpdateMetadataLoading}>
          Cancel
        </Button>
        <Button onClick={() => handleSave({ id: dashboard.id, description, thumbnailLightBase64: thumbnailLightObj.base64, thumbnailDarkBase64: thumbnailDarkObj.base64 })} variant="contained" sx={{ mt: 1, ml: 1 }} disabled={isUpdateMetadataLoading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AdminThumbnailsEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  dashboard: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    thumbnailLight: PropTypes.string.isRequired,
    thumbnailDark: PropTypes.string.isRequired
  }),
  isUpdateMetadataLoading: PropTypes.bool.isRequired
};
