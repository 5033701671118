import { defaultTo } from 'lodash';

/**
 * Format the city, state, zip line for an address. (Use to display these fields all on one line)
 *
 * @param {string} city city
 * @param {string} state state
 * @param {string} zip zip code
 * @param {string} zip4 the zip4 digits
 * @returns {string} the formatted cityStateZip
 */
export const formatCityStateZip = (city, state, zip = '', zip4 = '') => {
  const combinedZip = defaultTo(zip, '') + defaultTo(zip4, '');
  const formattedZip = formatZip(combinedZip);
  return `${[city, state].filter(s => !!s).join(', ')} ${formattedZip}`.trim();
};

/**
 * Format the address, city, state, zip line for an address. (Use to display these fields all on one line)
 *
 * @param {string} address address
 * @param {string} city city
 * @param {string} state state
 * @param {string} zip zip code
 * @returns {string} the formatted address + cityStateZip
 */
export const formatAddressCityStateZip = (address, city, state, zip) => {
  return `${[address, formatCityStateZip(city, state, zip)].filter(s => !!s).join(', ')}`;
};

/**
 * Format a zip code.
 *
 * @param {string} zip the input zip code to format
 * @returns {string} the formatted zip code
 */
export const formatZip = zip => {
  let trimmedZip = zip?.trim();
  if (trimmedZip) {
    trimmedZip = trimmedZip.substring(0, Math.min(9, trimmedZip.length));
    if (trimmedZip.length < 6) {
      return trimmedZip;
    } else {
      return `${trimmedZip.substring(0, 5)}-${trimmedZip.substring(5, trimmedZip.length)}`;
    }
  } else {
    return '';
  }
};

/**
 * Format a destination string (assuming format of {<city> <state> <zip>}).
 *
 * @param {string} destination string in format of {<city> <state> <zip>}
 * @returns {string} formatted destination
 */
export const formatDestination = destination => {
  if (destination) {
    const cityState = destination.substring(0, destination.lastIndexOf(' '));
    const zip = destination.substring(destination.lastIndexOf(' '));
    // Some destinations don't contain a zip (e.g. "USPS Secure Destruction Facility")
    if (zip && /^\d+$/.test(zip.trim())) {
      return `${cityState} ${formatZip(zip)}`;
    } else {
      return destination;
    }
  } else {
    return '';
  }
};

/**
 * Trim all string fields in an object and remove duplicate spaces.
 *
 * @param {object} obj the object to trim
 * @returns {object} the trimmed object
 */
export const trimObject = obj => {
  const trimmedObj = {};
  Object.keys(obj).forEach(k => (trimmedObj[k] = typeof obj[k] == 'string' ? obj[k].trim().replace(/ {2,}/g, ' ') : obj[k]));
  return trimmedObj;
};
