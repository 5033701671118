import { TextField, ToggleButton, ToggleButtonGroup } from '@ghs/components';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import SplDateRangePicker from './SplDateRangePicker';
import { FormErrors, SplFormByPieceNumber } from './types';
import LookupSearchFilters from './LookupSearchFilters.tsx';

type LookupByPieceNumberProps = {
  formData: Partial<SplFormByPieceNumber>;
  errors: FormErrors;
  onChange: (value: Partial<SplFormByPieceNumber>) => void;
};

export default function LookupByPieceNumber(props: LookupByPieceNumberProps) {
  const { formData, errors, onChange } = props;

  function handlePieceNumberType(_event: React.MouseEvent<HTMLElement>, pieceNumberType: SplFormByPieceNumber['pieceNumberType']): void {
    onChange({ pieceNumberType, imb: '', seqNum: '' });
  }

  function handleFormChange(event: React.ChangeEvent<HTMLInputElement>): void {
    onChange({ [event.target.name as keyof SplFormByPieceNumber]: event.target.value });
  }

  useEffect(() => {
    if (!formData.pieceNumberType) {
      onChange({ pieceNumberType: 'imb' });
    }
  }, [formData.pieceNumberType]);

  return (
    <Stack sx={{ gap: 2 }}>
      <ToggleButtonGroup color="primary" exclusive value={formData.pieceNumberType || 'imb'} onChange={handlePieceNumberType} sx={{ mb: 1, gap: 2 }}>
        <ToggleButton value="imb" sx={{ textTransform: 'none' }}>
          IMb
        </ToggleButton>
        <ToggleButton value="uniqueRecordId" sx={{ textTransform: 'none' }}>
          Unique Record ID
        </ToggleButton>
      </ToggleButtonGroup>
      <Stack direction="row">
        {formData.pieceNumberType === 'imb' && (
          <TextField
            name="imb"
            label="IMb (1st 20 digits)"
            value={formData.imb || ''}
            onChange={handleFormChange}
            error={Boolean(errors.imb)}
            helperText={errors.imb}
            inputProps={{ maxLength: 20, ['data-testid']: 'imb-input' }}
            sx={{ mr: 2, display: 'flex', flex: 1, maxWidth: 275 }}
          />
        )}
        {formData.pieceNumberType === 'uniqueRecordId' && (
          <TextField
            name="seqNum"
            label="Unique Record ID"
            value={formData.seqNum || ''}
            onChange={handleFormChange}
            error={Boolean(errors.seqNum)}
            helperText={errors.seqNum}
            inputProps={{ ['data-testid']: 'seqNum-input' }}
            sx={{ mr: 2, display: 'flex', flex: 1, maxWidth: 275 }}
          />
        )}
        <SplDateRangePicker formData={formData} errors={errors} />
      </Stack>
      <LookupSearchFilters formData={formData} onChange={onChange} />
    </Stack>
  );
}
