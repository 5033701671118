import { Button, TextField, DataGrid } from '@ghs/components';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Typography, Container } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/**
 * @typedef {import('../../services/BiService.js').DashboardView} DashboardView
 */

/**
 * Component for displaying the table of thumbnails.
 *
 * @param {{
 *   dashboards: Pick<DashboardView, 'id' | 'title' | 'description'>[],
 *   handleEditDialogOpen: (id: string) => void,
 * }} props - The props of the component.
 * @returns {React.ReactNode} AdminThumbnailsTable component
 */
export default function AdminThumbnailsTable(props) {
  const { dashboards, handleEditDialogOpen } = props;

  const [search, setSearch] = useState(/** @type {string} */ (''));

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box display="flex" flex="1" position="relative">
        <TextField label="Search" value={search} onChange={e => setSearch(e.target.value)} sx={{ width: '100%' }} />
        <Button sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 4, p: 1, minWidth: 'unset' }} onClick={() => setSearch('')}>
          <CloseIcon data-testid="admin-thumbnails-table-clear-search-button" />
        </Button>
      </Box>

      <DataGrid
        columns={[
          {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            renderCell: params => (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <span>{params.row.title}</span>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {params.row.id}
                </Typography>
              </Box>
            )
          },
          { field: 'description', headerName: 'Description', flex: 2, minWidth: 200 },
          {
            field: 'actions',
            headerName: '',
            align: 'right',
            renderCell: params => (
              <Button sx={{ p: 1, minWidth: 'unset' }} onClick={() => handleEditDialogOpen(params.row.id)}>
                <EditIcon data-testid={`admin-thumbnails-table-edit-button-${params.row.id}`} />
              </Button>
            )
          }
        ]}
        rows={dashboards
          .filter(dashboard => dashboard.title.toLowerCase().includes(search.toLowerCase()) || dashboard.id.toLowerCase().includes(search.toLowerCase()))
          .sort((a, b) => a.title.localeCompare(b.title))
          .sort(a => (search && a.title.toLowerCase().startsWith(search.toLowerCase()) ? -1 : 1))}
        getRowId={row => row.id}
        pageSize={50}
        autoHeight
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        disableRowSelectionOnClick
        sx={{
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none !important'
          },
          '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
            outline: 'none !important'
          }
        }}
      />
    </Container>
  );
}

AdminThumbnailsTable.propTypes = {
  dashboards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired,
  handleEditDialogOpen: PropTypes.func.isRequired
};
