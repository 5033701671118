import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

/**
 * Component for a route that requires authentication to access.
 *
 * @param {object} props props for this component
 * @param {Element} props.component the component to require authentication for
 * @param {Function} props.checkAccessFn function to check if user has access to this route
 * @returns {React.ReactNode} ProtectedRoute component
 * @class
 */
const ProtectedRoute = ({ component: Component, checkAccessFn, ...args }) => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated && checkAccessFn && !checkAccessFn()) {
    return <Navigate to={'/notfound'} />;
  }

  return <Component {...args} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  checkAccessFn: PropTypes.func
};

export default ProtectedRoute;
