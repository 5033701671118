// @ts-ignore
import { Autocomplete, textFieldVariants } from '@ghs/components';
import { Stack, Typography, Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { DataGridStylesWithoutSelectionOverride } from '../../util/DataGridStyles';
import { $$manageUsersAssignedToRoleModal } from '../ManageUsersAssignedToRoleModal/model';
import { $$manageRolesTable } from './model';

/**
 * ManageRolesTable component
 *
 * @returns {React.ReactNode} ManageRolesTable component
 */
export default function ManageRolesTab() {
  const dataGridProps = $$manageRolesTable.$$users.useDataGrid();

  const [filterValue, setFilterValue] = useState(/** @type {Roles.Role['id'] | null} */ (null));

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Roles.Role, any, any>[]} */
  const rolesTableColumns = [
    {
      field: 'name',
      headerName: 'Role',
      minWidth: 160,
      flex: 4,
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', cursor: 'pointer' }} onClick={() => $$manageUsersAssignedToRoleModal.open({ roleId: row.id })}>
          <Typography sx={{ textDecoration: 'underline' }}>{row.name || ''}</Typography>
        </Box>
      )
    },
    { field: 'description', headerName: 'Description', minWidth: 160, flex: 6 }
  ];

  return (
    <Stack sx={{ flex: 1, gap: 2 }}>
      <Autocomplete
        id="lobs-batch-actions-autocomplete"
        label="Search Role"
        placeholder="Select"
        options={dataGridProps.rows.map(row => ({ id: row.id, label: row.name }))}
        onChange={(_, value) => setFilterValue(value?.id ?? null)}
        variant={textFieldVariants.OUTLINED}
        value={dataGridProps.rows.find(row => row.id === filterValue)?.name || ''}
        sx={{ maxWidth: 300 }}
      />
      <DataGridPro
        {...dataGridProps}
        autoHeight
        rows={dataGridProps.rows.filter(row => (filterValue !== null ? row.id === filterValue : true))}
        columns={rolesTableColumns}
        disableRowSelectionOnClick
        editMode="row"
        getRowId={row => row.id}
        onRowDoubleClick={({ id }) => $$manageUsersAssignedToRoleModal.open({ roleId: id + '' })}
        initialState={{
          columns: { columnVisibilityModel: { id: false } },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pagination
        pageSizeOptions={[5, 10, 25, 50]}
        autosizeOnMount={false} // vitest is failing beause of this prop
        autosizeOptions={{
          includeOutliers: true,
          includeHeaders: true
        }}
        sx={DataGridStylesWithoutSelectionOverride}
      />
    </Stack>
  );
}
