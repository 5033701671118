import { __, propOr } from 'ramda';
import { Children } from 'react';

import { alpha, Box, FormControl, FormHelperText, InputLabel, Select as MuiSelect, SelectProps, SxProps, Theme } from '@mui/material';
import { red } from '@mui/material/colors';

export const selectVariants = {
  FILLED: 'filled',
  OUTLINED: 'outlined'
} as const;

export const selectSizes = {
  SMALL: 'small',
  MEDIUM: 'medium'
} as const;

export const DropdownSxOverrides: SxProps<Theme> = {
  boxShadow: 8,
  '& .MuiMenuItem-root:hover': {
    background: theme => alpha(theme.palette.info.light, 0.5)
  },
  '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
    background: theme => alpha(theme.palette.info.light, 0.5)
  }
};

export default function Select<Value = unknown>(props: SelectProps<Value> & { formSx?: SxProps<Theme>; helperText?: string; small?: boolean; emptyText?: string; icon?: React.ReactNode }) {
  const { children, variant = 'filled', id, label, helperText, value, error, disabled, small = false, emptyText = 'Select', icon = null, onChange, formSx, sx, ...overrides } = props;

  const labelId = `${id}-label`;

  const valueLabelMap = Children.toArray(children).reduce(
    (acc, { props: { value: childValue, children: childChildren } }) => ({
      ...acc,
      [childValue]: childChildren
    }),
    {} as { value: Value; children: string }[]
  );

  const getRenderedValue = propOr(emptyText, __, valueLabelMap);

  return (
    <FormControl variant={variant} error={error} disabled={disabled} sx={formSx}>
      <InputLabel
        shrink
        id={labelId}
        sx={{
          // fix a contrast error
          '&.Mui-error': { color: red[900] }
        }}
      >
        {label}
      </InputLabel>
      <MuiSelect
        id={id}
        labelId={labelId}
        label={label}
        value={value}
        onChange={onChange}
        size={small ? selectSizes.SMALL : selectSizes.MEDIUM}
        displayEmpty
        renderValue={val =>
          icon ? (
            <Box sx={{ display: 'flex' }}>
              {icon}
              <Box sx={{ ml: 1 }}>{getRenderedValue(val)}</Box>
            </Box>
          ) : (
            getRenderedValue(val)
          )
        }
        MenuProps={{
          PaperProps: {
            sx: DropdownSxOverrides
          }
        }}
        sx={{
          // fix a render bug for outlined labels
          '& legend': { maxWidth: '100%' },
          // disable hover/focus bg colors
          '&.MuiFilledInput-root': { backgroundColor: 'transparent' },
          [`& .MuiFilledInput-input`]: {
            bgcolor: 'misc.filledInputBg'
          },
          // thicker border on disabled state
          '&.Mui-disabled::before': {
            borderBottomWidth: 2
          },
          ...sx
        }}
        {...overrides}
      >
        {children}
      </MuiSelect>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
}
