import { createEffect } from 'effector';
import { SplFormData, SearchResultItem as SplSearchResultItem } from '../../pages/SinglePieceLookupPage/types';
import { trimObject } from '../../util/AddressUtils';
import { ghApi } from '../DataService';
import { AcsEvent, MailPiece, Scan } from './types';

const getMailDetailsFx = createEffect<{ imb: string; pkgId: string }, MailPiece>(async ({ imb, pkgId }) => {
  return ghApi.get('/mailPiece', { params: { imb, pkgId } }).then(res => trimObject(res.data) as MailPiece);
});

const getAcsEventsFx = createEffect<{ trackCode: string; pkgId: string; mailDate: string }, AcsEvent[]>(async params => {
  return ghApi.get('/acsEvents', { params }).then(res => res.data.map((acsEvent: AcsEvent) => trimObject(acsEvent) as AcsEvent));
});

const getScanDataFx = createEffect<{ trackCode: string; pkgId: string; date: string }, Scan[]>(async params => {
  return ghApi.get('/scans', { params }).then(res => res.data.map((scan: Scan) => trimObject(scan) as Scan));
});

const getSplResultsFx = createEffect<SplFormData, SplSearchResultItem[]>(async params => {
  return ghApi.get('/singlePieceLookup', { params: { ...params, origin: false } }).then(res => res.data);
});

export const $$splService = {
  getMailDetailsFx,
  getAcsEventsFx,
  getScanDataFx,
  getSplResultsFx
};
