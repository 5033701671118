import { Grid, Skeleton, Typography } from '@mui/material';

type MailingInformationAttributeProps = {
  label: string;
  value: string;
  isLoaded: boolean;
  hideIfEmpty?: boolean;
};

export default function MailingInformationAttribute(props: MailingInformationAttributeProps) {
  const { label, value, isLoaded, hideIfEmpty = true } = props;

  if (!isLoaded) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={5} sx={{ ...(!value && hideIfEmpty && { display: 'none' }) }}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={7} sx={{ ...(!value && hideIfEmpty && { display: 'none' }) }}>
        <Typography>{value}</Typography>
      </Grid>
    </>
  );
}
