import { attach, createEffect, createEvent, sample } from 'effector';
import { createGate } from 'effector-react';
import { isEmpty, omit } from 'lodash';
import qs from 'qs';
import { Location, SetURLSearchParams } from 'react-router-dom';

type SetByKeyParams = {
  key: string;
  value: unknown;
};

const LocationGate = createGate<Location>('location');

const setSearchByKey = createEvent<SetByKeyParams>();

const $pathname = LocationGate.state.map(state => state?.pathname || '');

const $search = LocationGate.state.map(state => state?.search || '').map(value => qs.parse(value.slice(1)) as Record<string, string>);

const SetSearchParamsGate = createGate<SetURLSearchParams>('set-search-params');

const setSearchByKeyFx = attach({
  source: {
    search: $search,
    setter: SetSearchParamsGate.state
  },
  mapParams: ({ key, value }: { key: string; value: unknown }, { search, setter }) => ({ key, value, search, setter }),
  effect: createEffect<{ key: string; value: unknown; search: Record<string, string>; setter: SetURLSearchParams }, void>(({ key, value, search, setter }) => {
    if (!setter) {
      throw new Error('[persist:query] Setter is not defined');
    }
    setter(qs.stringify(isEmpty(value) ? omit(qs.parse(search), key) : { ...qs.parse(search), [key]: value }));
  })
});

sample({
  clock: setSearchByKey,
  target: setSearchByKeyFx
});

export const $$router = {
  $pathname,
  $search,

  setSearchByKey,

  __: {
    LocationGate,
    SetSearchParamsGate
  }
};
