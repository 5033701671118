import OutputDetailsDataTable from "../utils/OutputDetailsDataTable";
import {IsRequestErrorPropTypes, SearchResultsPropTypes} from "../../utils/PropTypes";

const advancedAddressFieldSpecifications = [
    {name: 'Routing Code Barcode:', jsonName: 'barCode', type: 'string'},
    {name: 'Congressional District:', jsonName: 'congressionalDistrict', type: 'string'},
    {name: 'County Name:', jsonName: 'county', type: 'string'},
    {name: 'County Number:', jsonName: 'countyNumber', type: 'string'},
    {name: 'Carrier Route:', jsonName: 'crrt', type: 'string'},
    {name: 'Delivery Point Validation:', jsonName: 'dpv', type: 'string'},
    {name: 'Delivery Point Validation Code:', jsonName: 'dpvCode', type: 'string'},
    {name: 'Delivery Point Validation Description:', jsonName: 'dpvDescription', type: 'string'},
    {name: 'Highrise Default:', jsonName: 'isHighriseDefault', type: 'boolean'},
    {name: 'Rural Route Default:', jsonName: 'isRuralRouteDefault', type: 'boolean'},
    {name: 'LACSLink:', jsonName: 'lacs', type: 'string'},
    {name: 'Line of Travel:', jsonName: 'lineOfTravel', type: 'string'},
    {name: 'Line of Travel Direction:', jsonName: 'lineOfTravelDirection', type: 'string'},
    {name: 'Error Description:', jsonName: 'lookupReturnDescription', type: 'string'},
    {name: 'Residential Delivery Indicator:', jsonName: 'rdi', type: 'string'},
    {name: 'Record Type:', jsonName: 'recordType', type: 'string'},
];

/**
 * AdvancedAddressInformation component is used for grouping and displaying the certain data from the
 * searchResults object in a table. If there is a request error then it will display a table skeleton.
 *
 * @param {object} props - The properties passed to the component.
 * @param {object} props.searchResults - The searchResults data object that contains the data from VerifyAQ request.
 * @param {object} props.isRequestError - An isRequestError object that will contain an object if there was an error making a request to VerifyAQ.
 * @returns {React.ReactNode} React JSX element representing the AdvancedAddressInformation component.
 */
const AdvancedAddressInformation = ({searchResults, isRequestError}) => {
    return (
        <OutputDetailsDataTable searchResults={searchResults} specifications={advancedAddressFieldSpecifications} isRequestError={isRequestError}/>
    )
}
AdvancedAddressInformation.propTypes = {
    searchResults: SearchResultsPropTypes,
    isRequestError: IsRequestErrorPropTypes,
};


export default AdvancedAddressInformation;