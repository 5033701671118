import * as React from "react";
import { List, ListItem, ListItemButton, ListItemText, Popover, Divider, ListItemIcon, CircularProgress, Box, Typography } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ColorLensIcon from "@mui/icons-material/ColorLens";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { $$theme } from "../services/ThemeService/model";
import { useUnit } from "effector-react";
import { $$personaService } from "../services/PersonaService/model.js";
import { $$blockingDialog } from "./BlockingDialog/model.js";

const THEMES = [
  { name: 'Dark', value: 'dark',  },
  { name: 'Light', value: 'light' },
  { name: 'System', value: 'system' },
];

/**
 * Component for the user menu.
 *
 * @param {object} props props for the component
 * @param {Element} props.anchorEl element to use to attach the Popover to
 * @param {Function} props.handleClose handler to execute when the popover closes
 * @param {object} props.user the current user
 * @param {Function} props.handlePersonaChange handler to execute when changing personas
 * @returns {React.ReactNode} UserMenu component
 * @class
 */
export const UserMenu = ({anchorEl, handleClose, user, handlePersonaChange }) => {
  const navigate = useNavigate();
  const [themeEl, setThemeEl] = React.useState(null);
  const selectedMode = useUnit($$theme.$mode);
  const activePersona = useUnit($$personaService.$activePersona);
  const personas = useUnit($$personaService.$availablePersonas);

  const onClose = () => {
    setThemeEl(null);
    handleClose();
  }

  return (
    <>
      <Popover
        id={anchorEl ? 'avatar-popover' : undefined}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <List disablePadding sx={{ width: '100%', maxWidth: 300 }}>
          {/* NOTE: If adding more items to this list, consider backing it with an array of options and using map */}
          <ListItem>
            <ListItemText primary={user?.name} secondary={activePersona && personas.length > 1 ? activePersona?.displayName : null}/>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <ListItemButton selected={Boolean(themeEl)} onClick={(e) => setThemeEl(e.currentTarget)}>
              <ListItemIcon><ColorLensIcon/></ListItemIcon>
              <ListItemText primary="Theme"/>
              <KeyboardArrowRightIcon sx={{ ml: 3 }}/>
            </ListItemButton>
          </ListItem>
          <Divider/>
          <PersonaSelection personas={personas} handleSelection={async newPersona => {
            $$blockingDialog.open({
              title: 'Changing Persona',
              content: (
                <>
                  <Typography>Please wait while your persona is loaded.</Typography>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                  </Box>
                </>),
            });
            handleClose();
            handlePersonaChange(newPersona)
              .then(() => $$blockingDialog.close())
              .catch(() => {
                $$blockingDialog.open({
                  title: 'Error Changing Persona',
                  content: <Typography>An error occurred while changing your persona. Please reload the application and try again.</Typography>,
                });
              });
          }}/>
          <Divider/>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/logout')}>
              <ListItemIcon><LogoutIcon/></ListItemIcon>
              <ListItemText primary="Logout"/>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      <Popover
        id={themeEl ? 'theme-popover' : undefined}
        anchorEl={themeEl}
        open={Boolean(themeEl)}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left'}}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List disablePadding sx={{ width: '100%', maxWidth: 300 }}>
          {THEMES.map((themeItem) => (
            <ListItem disablePadding key={themeItem.value}>
              <ListItemButton
                disabled={themeItem.disabled}
                onClick={() => {
                  $$theme.changeTheme(themeItem.value);
                  onClose();
                }}
              >
                <ListItemText primary={themeItem.name} secondary={selectedMode === themeItem.value ? 'Current' : ''} sx={{ ml: 2, mr: 2 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}

const Persona = PropTypes.shape({
  id: PropTypes.string,
  displayName: PropTypes.string,
  ghsSystem: PropTypes.string
});

UserMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  user: PropTypes.shape({
    name: PropTypes.string
  }),
  personas: PropTypes.arrayOf(Persona),
  activePersona: Persona,
  handlePersonaChange: PropTypes.func
}

/**
 * Component for the persona selection portion of the user menu. This component will only display if there is more than
 * one persona available to a user.
 *
 * @param {object} props props for the component
 * @param {Array} props.personas list of available personas for the user
 * @param {Function} props.handleSelection handler to execute when changing personas
 * @returns {React.ReactNode} PersonaSelection component
 * @class
 */
const PersonaSelection = ({personas, handleSelection}) => {
    return personas?.length > 1 && <>
        <Divider/>
        <ListItem>
            <ListItemText primary={'Other personas'}/>
        </ListItem>
        {personas.map(persona => {
            return (
                <ListItem disablePadding key={persona.id}>
                    <ListItemButton onClick={() => handleSelection(persona)}>
                        <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                        <ListItemText primary={persona.displayName}/>
                    </ListItemButton>
                </ListItem>
            );
        })}
    </>;
};

PersonaSelection.propTypes = {
    personas: PropTypes.arrayOf(Persona),
    handleSelection: PropTypes.func
}