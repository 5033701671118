import { createContext } from 'react';

type UserContextType = {
  isMt: boolean;
  activePersona: Partial<Persona>;
  permissions: string[];
  isSt: boolean;
  personas: Persona[];
};

/**
 * User context containing data on the user's permissions and personas.
 *
 * NOTE: At some point we can consider incorporating the `user` object from `useAuth0()` hook in here as well. (`$$auth.$user` is also can be used to retrieve the user object)
 */
export const UserContext = createContext<UserContextType>({
  permissions: [],
  personas: [],
  activePersona: {},
  isMt: false,
  isSt: false
});
