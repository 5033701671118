import { CardActions, CardActionsProps, CardContent, CardHeader, CardMedia, CardTypeMap, Card as MuiCard } from '@mui/material';

import Button, { buttonColors, buttonSizes, buttonVariants } from '~/components/Button';
import { OverridableComponentProps } from '~/types';

export type CardProps = OverridableComponentProps<CardTypeMap> & {
  cardActionsProps?: CardActionsProps;
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  actions?: {
    label: string;
    href?: string;
    onClick?: () => void;
  }[];
  media?: React.ReactNode;
};

export default function Card(props: CardProps) {
  const { children, header, subheader, cardActionsProps, actions, media, ...overrides } = props;
  return (
    <MuiCard {...overrides} sx={{ ...(overrides?.sx || {}) }}>
      {media}
      {header || subheader ? <CardHeader subheader={subheader} title={header} sx={{ p: 3, pb: 0, fontSize: '2.4rem', width: 'fit-content' }} /> : null}
      <CardContent sx={{ p: 3, color: 'text.secondary' }}>{children}</CardContent>
      {actions ? (
        <CardActions {...cardActionsProps}>
          {actions.map(({ label, href, onClick, ...actionProps }) => (
            <Button key={label} href={href} onClick={onClick} variant={buttonVariants.TEXT} color={buttonColors.PRIMARY} size={buttonSizes.SMALL} {...actionProps}>
              {label}
            </Button>
          ))}
        </CardActions>
      ) : null}
    </MuiCard>
  );
}

export { CardMedia };
