import { Button, TextField } from '@ghs/components';
import { Stack, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import GrayhairDropZone from '../GrayhairDropzone';
import { useUnit } from 'effector-react';
import { $$theme } from '../../services/ThemeService/model';

/**
 * Component for displaying the upload logo form in a dialog.
 *
 * @param {object} props props for this component
 * @param {boolean} props.open whether the dialog should be open
 * @param {Function} props.onClose handler for when the dialog closes
 * @param {Function} props.handleUpload handler for when the upload button is clicked
 * @param {Function} props.uploadInProgress whether the upload of a logo is in progress or not
 * @param {Function} props.imageMaxSizeInBytes max size of the logo image to be uploaded
 * @returns {React.ReactNode} UploadLogoDialog component
 * @class
 */
function UploadLogoDialog({ open, onClose, handleUpload, uploadInProgress, imageMaxSizeInBytes }) {
  const [name, setName] = React.useState('');
  const [lightFile, setLightFile] = React.useState(null);
  const [darkFile, setDarkFile] = React.useState(null);
  const palettes = useUnit($$theme.$themePalettes);

  const clearForm = () => {
    setLightFile(null);
    setDarkFile(null);
    setName('');
  };

  React.useEffect(() => {
    if (open) {
      clearForm();
    }
  }, [open]);

  const handleDropLight = acceptedFiles => {
    if (acceptedFiles?.length) {
      setLightFile(acceptedFiles[0]?.base64);
    }
  };
  const handleDropDark = acceptedFiles => {
    if (acceptedFiles?.length) {
      setDarkFile(acceptedFiles[0]?.base64);
    }
  };

  const handleDialogClose = (_e, reloadData) => {
    clearForm();
    onClose(null, reloadData);
  };

  return (
    <Dialog fullWidth open={open} onClose={handleDialogClose}>
      <DialogTitle>Upload Logo</DialogTitle>
      <DialogContent>
        <TextField name="theme-name" label="Logo Name" value={name} onChange={event => setName(event.target.value)} error={false} helperText={''} sx={{ mt: 1, ml: 3 }} />
        <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 2 }}>
          <GrayhairDropZone
            inputDataTestId="light-input"
            maxFiles={1}
            accept={{ 'image/jpeg': [], 'image/png': [] }}
            label="Select logo for light theme"
            onDropAcceptedExtended={handleDropLight}
            previewBackgroundColor={palettes.light.palette.primary.dark}
          />
          <GrayhairDropZone
            inputDataTestId="dark-input"
            maxFiles={1}
            accept={{ 'image/jpeg': [], 'image/png': [] }}
            label="Select logo for dark theme"
            onDropAcceptedExtended={handleDropDark}
            previewBackgroundColor={palettes.dark.palette.primary.dark}
          />
        </Stack>
        {imageMaxSizeInBytes && (
          <Typography color="text.primary" sx={{ ml: 3, mt: 1 }}>
            Note: The file size should not exceed {imageMaxSizeInBytes / 1024} KB.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleDialogClose} size="medium" variant="contained" disabled={null} sx={{ mt: 1, ml: 1 }}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => handleUpload({ name, lightFile, darkFile })}
          size="medium"
          variant="contained"
          disabled={!name || !lightFile || !darkFile || !!uploadInProgress}
          sx={{ mt: 1 }}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
UploadLogoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  uploadInProgress: PropTypes.bool,
  imageMaxSizeInBytes: PropTypes.number.isRequired
};

export default UploadLogoDialog;
