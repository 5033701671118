import { DataGrid } from '@ghs/components';
import { Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { darken, lighten } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Scan } from '../../services/SplService';

import { useUnit } from 'effector-react';
import { formatDestination } from '../../util/AddressUtils';
import { $$splResultsPage } from './model';

type ScanDetailsProps = StackProps;

export default function ScanDetails(props: ScanDetailsProps) {
  const { ...overrides } = props;

  const data = useUnit($$splResultsPage.$scans);
  const isLoading = useUnit($$splResultsPage.$isMailDetailsFxPending);

  if (isLoading) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
      </Stack>
    );
  }

  return (
    <Stack {...overrides} id="mailing-details-container" spacing={2}>
      <Typography variant="h5" color="text.primary">
        Scan Details
      </Typography>
      <DataGrid
        columns={columns}
        rows={data}
        rowSelection={false}
        getRowClassName={params => (params.row.remit ? 'mailing-details-remit' : '')}
        // Wrap long cells
        getRowHeight={() => 'auto'}
        sx={{
          // Restore padding due to using getRowHeight
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          // Disable cell selection outline
          '& .MuiDataGrid-cell:focus': { outline: 'none' },
          '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
          // Styling for remit scans
          '& .mailing-details-remit': {
            backgroundColor: theme => getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': { backgroundColor: theme => getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode) }
          }
        }}
        localeText={{
          noRowsLabel: 'No scans received yet'
        }}
      />
      {data && data.some(scan => scan.remit) ? <Typography color="text.primary">* Blue rows indicate remittance mailpiece.</Typography> : null}
    </Stack>
  );
}

const columns: GridColDef<Scan>[] = [
  { field: 'destination', headerName: 'Mail Piece Destination', valueGetter: value => formatDestination(value), sortable: false, flex: 1 },
  { field: 'scanDateTime', headerName: 'Scan Date/Time', sortable: false, flex: 0.75 },
  { field: 'scanSiteZip', headerName: 'Scan Site ZIP', sortable: false, flex: 0.5 },
  { field: 'scanCityState', headerName: 'Scan City/State', sortable: false, flex: 0.75 },
  { field: 'details', headerName: 'Activity', sortable: false, flex: 0.75 },
  { field: 'travelDays', headerName: 'Travel Days', flex: 0.5 }
];

const getBackgroundColor = (color: string, mode: string) => (mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7));

const getHoverBackgroundColor = (color: string, mode: string) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6));
