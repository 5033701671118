import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

export const colors = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  GREY: 'grey',
  TEXT: 'text'
} as const;

export const typography = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'buttonLarge',
  'button',
  'buttonSmall',
  'caption',
  'overline',
  'avatarLetter',
  'inputLabel',
  'helperText',
  'inputText',
  'tooltip'
] as const;

export const baseTheme = {
  typography: {
    htmlFontSize: 10,
    fontFamily: 'Inter, Arial',
    h1: { letterSpacing: -1.5 },
    h2: { letterSpacing: -0.5 },
    h4: { letterSpacing: 0.25 },
    h6: { letterSpacing: 0.15 },
    subtitle1: { letterSpacing: 0.15 },
    subtitle2: { letterSpacing: 0.1 },
    body1: { letterSpacing: 0.15 },
    body2: { letterSpacing: 0.15 },
    buttonLarge: {
      fontWeight: 500,
      fontSize: '1.5rem',
      textTransform: 'uppercase',
      letterSpacing: -0.15
    },
    button: { letterSpacing: -0.25 },
    buttonSmall: {
      fontWeight: 500,
      fontSize: '1.3rem',
      textTransform: 'uppercase',
      letterSpacing: 0.46
    },
    caption: { letterSpacing: 0.25 },
    overline: { letterSpacing: 0.5 },
    avatarLetter: {
      fontWeight: 400,
      fontSize: '2.0rem',
      letterSpacing: -0.5
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: '1.2rem',
      letterSpacing: -0.3
    },
    helperText: {
      fontWeight: 400,
      fontSize: '1.2rem'
    },
    inputText: {
      fontWeight: 400,
      fontSize: '1.6rem',
      letterSpacing: -0.3
    },
    tooltip: {
      fontWeight: 500,
      fontSize: '1.0rem',
      letterSpacing: -0.35
    }
  }
};
