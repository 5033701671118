import PropTypes from "prop-types";
import { IconButton ,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from '@mui/material'
import FileTable from "./FileTable";
import * as React from "react";
import {useState} from "react";
import CloseIcon from '@mui/icons-material/Close';


const TabBody = ({tableLoading, finalData, dataMergedWithFieldStepDisplaySpecifications, handleKill, showCompletedFiles, getCompletedFilesResults, getRefreshedResults}) => {

    const [open, setOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const handleDialogOpen = (logId, uploadedFileName) => {
        setSelectedData({logId: logId, uploadedFileName: uploadedFileName});
        setOpen(true);
    };

    const handleDialogKill = async () => {
        await handleKill(selectedData.logId)
        getRefreshedResults()
        setOpen(false);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <FileTable tableLoading={tableLoading} handleDialogOpen={handleDialogOpen} files={finalData} stepDetails={dataMergedWithFieldStepDisplaySpecifications} showCompletedFiles={showCompletedFiles} getCompletedFilesResults={getCompletedFilesResults} getRefreshedResults={getRefreshedResults}/>
            <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md" sx={{ m: 0, p: 3 }}>
                <DialogTitle>
                    <Typography variant="h6" display="inline">
                        Kill file confirmation
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleDialogClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'primary.main'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="body1" display="inline">
                            Are you sure you want to kill the selected file?
                        </Typography>
                        <Typography sx={{ whiteSpace: "pre-line" }} >{"\n"}</Typography>
                        <Typography variant="body1">
                            <strong>Log ID:</strong> {selectedData ? selectedData.logId : ""}
                        </Typography>
                        <Typography variant="body1">
                            <strong>FileName:</strong> {selectedData ? selectedData.uploadedFileName : ""}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} autoFocus variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogKill} variant="contained" color="error">
                        KILL
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}
TabBody.propTypes = {
    tableLoading: PropTypes.bool,
    handleKill: PropTypes.func,
    showCompletedFiles: PropTypes.bool,
    getCompletedFilesResults: PropTypes.func,
    getRefreshedResults: PropTypes.func,
    finalData: PropTypes.array,
    dataMergedWithFieldStepDisplaySpecifications: PropTypes.array
};

export default TabBody;