import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { Auth0ProviderWithHistory } from './Auth0ProviderWithHistory';
import './index.css';
import AppThemeProvider from './services/ThemeService/AppThemeProvider';

createRoot(document.getElementById('root') as HTMLDivElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <AppThemeProvider>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </AppThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
