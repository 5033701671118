import jsPDF from 'jspdf';
import { MailPiece } from '../../../services/SplService';

type GenerateMailInfoParams = {
  pdf: jsPDF;
  mailPiece: MailPiece | null;
  columnWidth: number;
};

/**
 * Generate mail info to add to PDF.
 */
export default function generateMailInfo(params: GenerateMailInfoParams) {
  const { pdf, mailPiece: mailDetails, columnWidth } = params;

  const userField1 = mailDetails?.userField1 ? pdf.splitTextToSize(`${mailDetails.userField1}`, columnWidth) : [];
  const userField2 = mailDetails?.userField2 ? pdf.splitTextToSize(`${mailDetails.userField2}`, columnWidth) : [];
  const userField3 = mailDetails?.userField3 ? pdf.splitTextToSize(`${mailDetails.userField3}`, columnWidth) : [];

  // Note about "Array(Math.max(userField1.length-1, 0)).fill('')": This is to add empty rows to compensate the number
  // of lines of the user field value. If the user field value needs 3 lines to fit, we display the user field label in
  // first line plus leave 2 empty lines more

  const mailInfoLabels = [
    ...(mailDetails?.uniqueRecordId ? [`Unique Record ID:`] : []),
    ...(mailDetails?.imb ? [`IMb:`] : []),
    ...(mailDetails?.mailDate ? [`Mail Date:`] : []),
    ...(mailDetails?.inductionDate ? [`Induction Date:`] : []),
    `Delivery Date:`,
    ...(mailDetails?.lineOfBusiness ? [`Line of Business: `] : []),
    ...(mailDetails?.campaignName ? [`Campaign Name: `] : []),
    ...(mailDetails?.campaignNumber ? [`Campaign Nbr: `] : []),
    ...(mailDetails?.versionName ? [`Version Name: `] : []),
    ...(mailDetails?.versionNumber ? [`Version Nbr: `] : []),
    ...(mailDetails?.mailClass ? [`Mail Class: `] : []),
    ...(mailDetails?.branch ? [`Branch:`] : []),
    ...(mailDetails?.mailServiceProvider ? [`Mail Service Provider:`] : []),
    ...(mailDetails?.userField1 ? [`User field 1:`] : []), // Only ST
    ...Array(Math.max(userField1.length - 1, 0)).fill(''),
    ...(mailDetails?.userField2 ? [`User field 2:`] : []), // Only ST
    ...Array(Math.max(userField2.length - 1, 0)).fill(''),
    ...(mailDetails?.userField3 ? [`User field 3:`] : []), // Only ST
    ...Array(Math.max(userField3.length - 1, 0)).fill('')
  ];

  const mailInfoValues = [
    ...(mailDetails?.uniqueRecordId ? [`${mailDetails.uniqueRecordId}`] : []),
    ...(mailDetails?.imb ? [`${mailDetails.imb}`] : []),
    ...(mailDetails?.mailDate ? [`${mailDetails.mailDate}`] : []),
    ...(mailDetails?.inductionDate ? [`${mailDetails.inductionDate}`] : []),
    `${mailDetails?.deliveryDate || ''}`,
    ...(mailDetails?.lineOfBusiness ? [`${mailDetails.lineOfBusiness}`] : []),
    ...(mailDetails?.campaignName ? [`${mailDetails.campaignName}`] : []),
    ...(mailDetails?.campaignNumber ? [`${mailDetails.campaignNumber}`] : []),
    ...(mailDetails?.versionName ? [`${mailDetails.versionName}`] : []),
    ...(mailDetails?.versionNumber ? [`${mailDetails.versionNumber}`] : []),
    ...(mailDetails?.mailClass ? [`${mailDetails.mailClass}`] : []),
    ...(mailDetails?.branch ? [`${mailDetails.branch}`] : []),
    ...(mailDetails?.mailServiceProvider ? [`${mailDetails.mailServiceProvider}`] : []), // Only MT
    ...userField1, // Only ST
    ...userField2, // Only ST
    ...userField3 // Only ST
  ];

  return { mailInfoLabels, mailInfoValues };
}
