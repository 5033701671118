import { Box, BoxProps } from '@mui/material';

type TabPanelProps<T> = {
  index: T;
  value: T;
  children: React.ReactNode;
  sx?: BoxProps['sx'];
};

/**
 * Component for displaying the contents as a tab.
 */
function TabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </Box>
  );
}

export default TabPanel;
