import { Paper, AppBar, Typography, Stack, Card, CardContent, Divider, Skeleton, CardActions, Button } from '@mui/material'
import PropTypes from "prop-types";
import {SubmittedAddress} from "./SubmittedAddress";
import {OutputAddress} from "./OutputAddress";
import {areObjectStringValuesEmpty} from "../../../util/FormUtil";
import {FormDataPropTypes, IsRequestErrorPropTypes, SearchResultsPropTypes} from "../utils/PropTypes";

/**
 * The SubmittedResults component is used for displaying the input and output data in an address form. This data
 * is retrieved from the user and from verifyAQ respectively. It also allows the user to repopulate the AddressInput
 * fields from their previous request.
 *
 * @param {object} props - The properties passed to the component.
 * @param {object} props.searchResults - The searchResults data object that contains the data from VerifyAQ request.
 * @param {object} props.searchParams - The searchParams object that contains the URL params from the current request.
 * @param {object} props.formData - The current formData object that contains the values used to make VerifyAQ request.
 * @param {object} props.isRequestError - An isRequestError object that will contain an object if there was an error making a request to VerifyAQ.
 * @param {Function} props.setFormData - function allows the setting of a new formData object.
 * @returns {React.ReactNode} React JSX element representing the SubmittedResults component.
 */
const SubmittedResults = ({searchResults, searchParams, formData, isRequestError, setFormData}) => {

    if (!areObjectStringValuesEmpty(searchResults)) {
        return (
            <Paper data-testid="submitted-results" variant={"elevation"} elevation={3} sx={{m: 3}}>
                <AppBar elevation={1} position="static"
                        sx={{color: "primary.contrastText",
                            bgcolor: parseInt(searchResults['lookupReturnCode']) === 0 ? 'success.main' : 'error.main',
                            borderRadius: "4px 4px 0 0"}}>
                    <Typography variant={"h6"} sx={{m: 2}}>Submitted Results</Typography>
                </AppBar>
                <Stack direction={"row"} justifyContent={"space-around"} sx={{m: 2}}>
                    <SubmittedAddress
                        searchParams={searchParams}
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <OutputAddress
                        searchResults={searchResults}
                    />
                </Stack>
            </Paper>
        )
    } else if (isRequestError !== null) {
        return (
            <Paper variant={"elevation"} elevation={3} sx={{m: 3}}>
                <AppBar elevation={1} position="static"
                        sx={{color: "primary.contrastText", bgcolor: "primary.dark", borderRadius: "4px 4px 0 0"}}>
                    <Typography variant={"h6"} sx={{m: 2}}>Submitted Results</Typography>
                </AppBar>
                <Stack direction={"row"} justifyContent={"space-around"} sx={{m: 2}}>
                    <Card sx={{flexGrow: 1}}>
                        <CardContent>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                Submitted Address
                            </Typography>
                            <Divider sx={{width: '100%', marginTop: 1, marginBottom: 1}}/>
                            <Skeleton animation={"wave"} sx={{flexGrow: 1}}/>
                            <Skeleton animation={"wave"} sx={{flexGrow: 1}}/>
                            <Skeleton animation={"wave"} sx={{flexGrow: 1}}/>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Copy</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{flexGrow: 1}}>
                        <CardContent>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                Output Address
                            </Typography>
                            <Divider sx={{width: '100%', marginTop: 1, marginBottom: 1}}/>
                            <Skeleton animation={"wave"} sx={{flexGrow: 1}}/>
                            <Skeleton animation={"wave"} sx={{flexGrow: 1}}/>
                            <Skeleton animation={"wave"} sx={{flexGrow: 1}}/>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Copy</Button>
                        </CardActions>
                    </Card>
                </Stack>
            </Paper>
        )
    } else {
        return null
    }
}

SubmittedResults.propTypes = {
    searchResults: SearchResultsPropTypes,
    searchParams: PropTypes.object,
    formData: FormDataPropTypes,
    isRequestError: IsRequestErrorPropTypes,
    setFormData: PropTypes.func,
};

export default SubmittedResults;