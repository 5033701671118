import { EventCallable } from 'effector';
import { useEffect } from 'react';

/**
 * Hook to execute an event when the component is mounted.
 */
export default function useMountedEvent(event: EventCallable<boolean>) {
  useEffect(() => {
    event(true);
    return () => {
      event(false);
    };
  }, [event]);
}
