import OutputDetailsDataTable from "../utils/OutputDetailsDataTable";
import {IsRequestErrorPropTypes, SearchResultsPropTypes} from "../../utils/PropTypes";

const returnedDataSpecifications = [
    {name: 'Return Code:', jsonName: 'lookupReturnCode', type: 'string'},
    {name: 'Return Description:', jsonName: 'lookupReturnDescription', type: 'string'},
    {name: 'Business Name:', jsonName: 'company', type: 'string'},
    {name: 'Address Line 1:', jsonName: 'street', type: 'string'},
    {name: 'Address Line 2:', jsonName: 'street2', type: 'string'},
    {name: 'Urbanization:', jsonName: 'urbanization', type: 'string'},
    {name: 'City:', jsonName: 'city', type: 'string'},
    {name: 'State:', jsonName: 'state', type: 'string'},
    {name: 'ZIP:', jsonName: 'zip', type: 'string'},
    {name: 'ZIP+4:', jsonName: 'zipPlusFour', type: 'string'},
    {name: 'Delivery Point:', jsonName: 'deliveryPoint', type: 'string'},
];

/**
 * ReturnedData component is used for grouping and displaying the certain data from the
 * searchResults object in a table. If there is a request error then it will display a table skeleton.
 *
 * @param {object} props - The properties passed to the component.
 * @param {object} props.searchResults - The searchResults data object that contains the data from VerifyAQ request.
 * @param {object} props.isRequestError - An isRequestError object that will contain an object if there was an error making a request to VerifyAQ.
 * @returns {React.ReactNode} React JSX element representing the ReturnedData component.
 */
const ReturnedData = ({searchResults, isRequestError}) => {
    return (
        <OutputDetailsDataTable searchResults={searchResults} specifications={returnedDataSpecifications} isRequestError={isRequestError}/>
    )
}
ReturnedData.propTypes = {
    searchResults: SearchResultsPropTypes,
    isRequestError: IsRequestErrorPropTypes,
};

export default ReturnedData;